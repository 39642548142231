<template>
    <div class="team-creation-form">
        <form @submit.prevent="projectCreationSubmitted">
            <div class="sm:rounded-md">
                <div class="">
                    <label for="team-name" class="onboarding-label">Project Name</label>
                    <input
                        type="text"
                        name="team-name"
                        id="team-name"
                        class=""
                        v-model="v$.projectName.$model"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.projectName.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div class="error-msg" v-if="responseError">{{ responseError }}</div>
                </div>
                <div class="flex flex-row mt-4 items-baseline">
                    <div class="flex-grow"></div>
                    <LoadingButton :disabled="v$.$invalid" :loading="loading"
                        >Create Project</LoadingButton
                    >
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { createProject } from '@/services/projects'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { defineComponent } from 'vue'
import LoadingButton from '@/components/global/LoadingButton.vue'

export default defineComponent({
    components: { LoadingButton },
    emits: ['project'],
    setup() {
        const v$ = useVuelidate()

        return { v$ }
    },
    data() {
        return {
            projectName: '',
            responseError: '',
            loading: false,
        }
    },
    validations() {
        return {
            projectName: { required },
        }
    },
    methods: {
        async projectCreationSubmitted() {
            this.loading = true
            const { project, error } = await createProject(this.projectName)
            this.loading = false

            if (error.value) {
                this.responseError = error.value.message
            } else if (project.value) {
                this.$emit('project', project.value)
            }
        },
    },
})
</script>
