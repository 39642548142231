import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Dashboard from '../views/dashboard/Dashboard.vue'
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/OnboardingPasswordReset.vue'
import Recommendations from '../views/recommendations/Recommendations.vue'
import TeamDetail from '../views/teams/TeamDetail.vue'
import ProjectDetail from '../views/projects/ProjectDetail.vue'
import Settings from '../views/settings/Settings.vue'
import SlackRedirect from '../views/settings/SlackRedirect.vue'
import TeamsRedirect from '../views/settings/TeamsRedirect.vue'
import DiscordRedirect from '../views/settings/DiscordRedirect.vue'
import PrivacyPolicy from '../views/info/PrivacyPolicy.vue'
import Pricing from '../views/Pricing.vue'
import TermsOfService from '../views/info/TermsOfService.vue'
import PageNotFound from '../views/info/PageNotFound.vue'
import AudioDetail from '../views/projects/Audio/AudioDetail.vue'
import { useAuth } from '@/services/auth'
import SentimentAnalysisTrial from '../components/global/SentimentAnalysisTrial.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'welcome',
        component: Login,
        meta: { hideInDemo: true },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { hideInDemo: true },
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: { hideInDemo: true },
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: ForgotPassword,
        meta: { hideInDemo: true },
    },
    {
        path: '/resetPassword',
        name: 'onboardingReset',
        component: ResetPassword,
        meta: { hideInDemo: true },
    },
    {
        path: '/dashboard/:optionName',
        name: 'dashboard',
        component: Dashboard,
        meta: { requiresAuth: true },
    },
    {
        path: '/teams/:id/details',
        name: 'teams.details',
        component: TeamDetail,
        meta: { requiresAuth: true },
    },
    {
        path: '/projects/:id/details',
        name: 'projects.details',
        component: ProjectDetail,
        meta: { requiresAuth: true },
    },
    {
        path: '/audio/:audioId/:fileName',
        name: 'audio.details',
        component: AudioDetail,
        meta: { requiresAuth: true },
    },
    {
        path: '/marketplace',
        name: 'marketplace',
        component: Recommendations,
        meta: { requiresAuth: false },
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        // meta: { hideInDemo: true, requiresAuth: true },
    },
    {
        path: '/settings/user',
        name: 'settings.user',
        component: Settings,
        meta: { hideInDemo: true, requiresAuth: true },
    },
    {
        path: '/settings/company',
        name: 'settings.company',
        component: Settings,
        meta: { hideInDemo: true, requiresAuth: true },
    },
    {
        path: '/settings/payment',
        name: 'settings.payment',
        component: Settings,
        meta: { hideInDemo: true, requiresAuth: true },
    },
    {
        path: '/settings/slackAuth',
        name: 'settings.slackAuth',
        component: Settings,
        meta: { hideInDemo: true, requiresAuth: true },
    },
    {
        path: '/settings/slackRedirect',
        name: 'settings.slackRedirect',
        component: SlackRedirect,
        meta: { hideInDemo: true, requiresAuth: true },
    },
    {
        path: '/settings/teamsRedirect',
        name: 'settings.teamsRedirect',
        component: TeamsRedirect,
        meta: { hideInDemo: true, requiresAuth: true },
    },
    {
        path: '/settings/discordRedirect',
        name: 'settings.discordRedirect',
        component: DiscordRedirect,
        meta: { hideInDemo: true, requiresAuth: true },
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyPolicy,
    },
    {
        path: '/terms',
        name: 'terms',
        component: TermsOfService,
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: Pricing,

    },
    {
        path: '/*',
        component: PageNotFound,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    // Two routing models. Auth and demo. Demo users can only see some pages. Handling demo first, because it's simpler and doesn't require loading auth info
    const isDemo = process.env.VUE_APP_IS_DEMO == true
    if (isDemo) {
        const shouldHide = to.meta.hideInDemo === true
        if (shouldHide) {
            next({ name: 'dashboard' })
        } else {
            next()
        }
        return
    }

    const { authenticating, user } = useAuth()
    if (authenticating.value === false && to.meta.requiresAuth === true && !user?.value) {
        next({ name: 'login' })
    } else {
        next()
    }
})

export default router
