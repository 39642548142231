<template>
    <ModalContainer :openImmediately="isOpen" @closed="triggerClosed">
        <template v-slot:modalTitle>Message Insights Peek - {{ emotion }}</template>
        <template v-slot:modalContent>
            <div v-if="messagesToShow.length">
                <div v-for="message of messagesToShow">
                    <div class="flex flex-row shadow-md p-5 my-5 message rounded-md">
                        <div class="w-4/5">
                            <div><span class="font-bold">
                                    <span v-if="message.authorUsername">@{{ message.authorUsername }}</span>
                                    <template v-else>
                                        <span>@user-{{ messagex.userId }}</span>
                                    </template>
                                </span>: {{
                                message.rawText }} </div>
                            <div>{{ isoToHumanReadableDate(message.createdAt) }}</div>

                        </div>
                        <div class="w-1/5">

                            <div v-if="/^\d+$/.test(message.channelId)">
                                <a :href="getDiscordMessageLink(message)" target="_blank">
                                    <img class="mx-5 inline cursor-pointer" title="Click to view in discord"
                                        alt="Discord" src="../../assets/discord-mini-icon.png">
                                </a>
                                {{ (message.emotionScore * 100).toFixed(0) }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-else-if="!loading && messagesToShow.length == 0 && !errorMessage">
                <div class="flex flex-row shadow-md p-5 my-5 message rounded-md">
                    <div class="w-4/5">
                        <div>No messages to show</div>
                    </div>
                </div>
            </template>
            <template v-else-if="loading">
                <div class="flex flex-row shadow-md p-5 my-5 message rounded-md">
                    <svg class="animate-spin -mr-1 ml-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24" v-if="loading">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="#000000"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg> <span class="px-10">Loading Insights...</span>
                </div>
            </template>
            <template v-else>
                <div class="flex flex-row shadow-md p-5 my-5 message rounded-md">
                    <div class="w-4/5">
                        <div>{{ errorMessage }}</div>
                    </div>
                </div>
            </template>
        </template>

    </ModalContainer>

</template>

<script lang="ts">
import { Ref, defineComponent, ref, watch } from 'vue'
import ModalContainer from '../global/ModalContainer.vue';
import { EmotionInsightMessage } from '@/models/EmotionInsightMessage';

export default defineComponent({
    components: {
        ModalContainer,
    },
    props: {
        messages: {
            type: Array,
            required: true,
        },
        emotion: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        errorMessage: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
        }
    },
    setup(props, { emit }) {
        const isOpen = true;
        const messagesToShow: Ref<EmotionInsightMessage[]> = ref(props.messages as EmotionInsightMessage[]);

        console.log("OPENING THE MODAL: ", isOpen);
        const getDiscordMessageLink = (message: EmotionInsightMessage) => {
            // OPEN DISCORD TO THE MESSAGE
            console.log("OPENING DISCORD TO MESSAGE: ");
            return `https://discord.com/channels/${message.guildId}/${message.channelId}/${message.messageId}`;
        }
        watch(() => props.messages, (newMessages) => {
            messagesToShow.value = newMessages as EmotionInsightMessage[];
        });
        // watch(() => props.loading, (newLoading) => {
        //     console.log("LOADING CHANGED: ", newLoading);
        // });

        const triggerClosed = () => {
            emit('closed');
        }
        const isoToHumanReadableDate = (isoString: string) => {
            const date = new Date(isoString);
            return date.toLocaleString('en-US', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
            });
        }

        return {
            isOpen,
            messagesToShow,
            getDiscordMessageLink,
            triggerClosed,
            isoToHumanReadableDate
        }
    },
    methods: {

    }
});
</script>

<style scoped>
.message {
    background-color: rgb(103 232 249);
}
</style>
