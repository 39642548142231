<template>
    <div v-if="computedAudios.length">
        <div class="mx-5 mb-5">Uploaded Audio Files</div>
        <div class="content flex flex-wrap">
            <template v-for="audio of computedAudios" :key="audio.id">
                <div
                    v-on:click.self="
                        routeTo(
                            `/audio/${audio.id}/${audio.fileName.replace(/[^a-zA-Z0-9-_]/g, '+')}`
                        )
                    "
                    class="audio-card cursor-pointer flex shadow-md w-52 items-center mx-5 my-5"
                >
                    <div
                        v-on:click.self="
                            routeTo(
                                `/audio/${audio.id}/${audio.fileName.replace(
                                    /[^a-zA-Z0-9-_]/g,
                                    '+'
                                )}`
                            )
                        "
                        class="audio-logo h-10 w-10 mx-3"
                    ></div>
                    <div>
                        <div class="mb-1 flex flex-row items-center" v-if="audio">
                            <h3
                                v-on:click.self="
                                    routeTo(
                                        `/audio/${audio.id}/${audio.fileName.replace(
                                            /[^a-zA-Z0-9-_]/g,
                                            '+'
                                        )}`
                                    )
                                "
                                class="text-left font-semibold"
                            >
                                {{ audio.fileName }}
                            </h3>
                            <div class="flex-grow"></div>
                            <Menu as="div" class="relative inline-block text-left">
                                <div>
                                    <MenuButton
                                        class="inline-flex justify-center w-full p-2 text-sm font-medium text-black bg-teal rounded-md bg-opacity-0 hover:bg-opacity-30"
                                    >
                                        <DotsVerticalIcon
                                            class="w-5 h-5 text-black"
                                            aria-hidden="true"
                                        />
                                    </MenuButton>
                                </div>

                                <transition
                                    enter-active-class="transition duration-100 ease-out"
                                    enter-from-class="transform scale-95 opacity-0"
                                    enter-to-class="transform scale-100 opacity-100"
                                    leave-active-class="transition duration-75 ease-in"
                                    leave-from-class="transform scale-100 opacity-100"
                                    leave-to-class="transform scale-95 opacity-0"
                                >
                                    <MenuItems
                                        class="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none z-20"
                                    >
                                        <div class="px-1 py-1">
                                            <MenuItem v-slot="{ active }">
                                                <button
                                                    :class="[
                                                        active
                                                            ? 'bg-red-600 text-white'
                                                            : 'text-red-600',
                                                        'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                                    ]"
                                                    @click="() => deleteAudio(audio)"
                                                >
                                                    <TrashIcon
                                                        :active="active"
                                                        class="w-5 h-5 mr-2 text-red-600"
                                                        aria-hidden="true"
                                                    />
                                                    Delete Audio
                                                </button>
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </transition>
                            </Menu>
                        </div>

                        <div
                            v-on:click.self="
                                routeTo(
                                    `/audio/${audio.id}/${audio.fileName.replace(
                                        /[^a-zA-Z0-9-_]/g,
                                        '+'
                                    )}`
                                )
                            "
                            class="flex status h-11 my-2 capitalize"
                        >
                            {{ audio.status }}
                        </div>

                        <div
                            v-on:click.self="
                                routeTo(
                                    `/audio/${audio.id}/${audio.fileName.replace(
                                        /[^a-zA-Z0-9-_]/g,
                                        '+'
                                    )}`
                                )
                            "
                            class="text-xs my-2"
                        >
                            {{ timeAgo(audio.updatedAt || audio.createdAt) }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="z-32">
            <AudioDeletionModal
                v-if="audioToDelete"
                :audio="audioToDelete"
                @deleted="fileDeleted"
            ></AudioDeletionModal>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Menu, MenuItems, MenuButton, MenuItem } from '@headlessui/vue'
import { TrashIcon, DotsVerticalIcon } from '@heroicons/vue/solid'
import AudioAnalysisResult from '@/models/AudioAnalysisResult'
import AudioDeletionModal from '@/components/projects/AudioDeletionModal.vue'
import router from '@/router'

export default defineComponent({
    components: {
        AudioDeletionModal,
        Menu,
        MenuItems,
        MenuButton,
        MenuItem,
        DotsVerticalIcon,
        TrashIcon,
    },
    props: ['audioList'],
    computed: {
        computedAudios() {
            return this.audioList?.filter(
                (audio: AudioAnalysisResult) => !this.deletedList.includes(audio.fileKey)
            )
        },
    },
    data: () =>
        ({ audioToDelete: null, deletedList: [] } as {
            audioToDelete: AudioAnalysisResult | null
            deletedList: string[]
        }),
    methods: {
        routeTo(routeName: string) {
            router.push(routeName)
        },
        fileDeleted(deletedKey: string) {
            this.deletedList.push(deletedKey)
            this.audioToDelete = null
        },
        deleteAudio(audio: AudioAnalysisResult) {
            this.audioToDelete = audio
        },
        timeAgo(time: Date) {
            const takenTimestamp = new Date(time).valueOf()
            const nowTimestamp = Date.now()
            const yearsAgoCount =
                (nowTimestamp - takenTimestamp) / (1000 * 60 * 60 * 24 * 7 * 30 * 12)
            const monthsAgoCount = (nowTimestamp - takenTimestamp) / (1000 * 60 * 60 * 24 * 7 * 30)
            const weeksAgoCount = (nowTimestamp - takenTimestamp) / (1000 * 60 * 60 * 24 * 7)
            const daysAgoCount = (nowTimestamp - takenTimestamp) / (1000 * 60 * 60 * 24)
            const hoursAgoCount = (nowTimestamp - takenTimestamp) / (1000 * 60 * 60)
            const minutesAgoCount = (nowTimestamp - takenTimestamp) / (1000 * 60)
            let takenMoment
            if (yearsAgoCount > 1) {
                const flooredYears = Math.floor(yearsAgoCount)
                takenMoment =
                    flooredYears > 1
                        ? `about ${flooredYears} years ago`
                        : `about ${flooredYears} year ago`
            } else if (monthsAgoCount > 1) {
                const flooredMonths = Math.floor(monthsAgoCount)
                takenMoment =
                    flooredMonths > 1
                        ? `about ${flooredMonths} months ago`
                        : `about ${flooredMonths} month ago`
            } else if (weeksAgoCount > 1) {
                const flooredWeeks = Math.floor(weeksAgoCount)
                takenMoment =
                    flooredWeeks > 1 ? `${flooredWeeks} weeks ago` : `${flooredWeeks} week ago`
            } else if (daysAgoCount > 1) {
                const flooredDays = Math.floor(daysAgoCount)
                takenMoment = flooredDays > 1 ? `${flooredDays} days ago` : `${flooredDays} day ago`
            } else if (hoursAgoCount > 1) {
                const flooredHours = Math.floor(hoursAgoCount)
                takenMoment =
                    flooredHours > 1 ? `${flooredHours} hours ago` : `${flooredHours} hour ago`
            } else if (minutesAgoCount > 1) {
                const flooredMinutes = Math.floor(minutesAgoCount)
                takenMoment =
                    flooredMinutes > 1
                        ? `${flooredMinutes} minutes ago`
                        : `${flooredMinutes} minute ago`
            } else if (time) {
                takenMoment = 'just now'
            } else {
                takenMoment = time
            }
            return `${takenMoment}`
        },
    },
})
</script>

<style scoped>
.audio-logo {
    background-image: url('../../assets/audio-message.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.status {
    color: rgb(0, 107, 0);
}

.audio-card:hover {
    background-color: aliceblue;
}
</style>
