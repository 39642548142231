import AudioAnalysisResult from '@/models/AudioAnalysisResult';
import Project from '@/models/Project';
import axios, { AxiosProgressEvent } from 'axios'
import { ref } from 'vue';
import { callApiWithAuth } from './api'
import { useAuth } from './auth';

export interface ProjectDetailsResponse {
    project: Project
    audios: AudioAnalysisResult[]
}



export const getUploadURL = async (companyProjectId: number, fileName: string, userFileName: string) => {
    const { apiResponse, error, post } = callApiWithAuth(`projects/audio/s3-signed-url`);
    await post({ companyProjectId, userFileName, fileName: `${fileName.replace(/[^0-9a-zA-Z._-]+/g, '-')}` })
    const uploadURL: string = apiResponse.value.signedURL;
    const fileKey: string = apiResponse.value.fileKey;
    return { error, uploadURL, fileKey }
}

export const uploadFileToS3 = async (uploadURL: string, file: any, progressRef: { loaded: number, total: number }) => {

    const formData = new FormData();
    formData.append("image", file);
    const config = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            progressRef.total = progressEvent.total||0;
            progressRef.loaded = progressEvent.loaded;
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-amz-acl': 'public-read',
        }
    }
    try {
        const response = await axios.put(uploadURL, formData, config)
        return { error: ref(null), response }
    } catch (error) {
        return { error }

    }

}


// export const initiateAudioSentimentAnalysis = async (fileKey: string) => {
//     const { apiResponse, error, get } = projectsCallApi(`projects/audio/initiate-analysis/${fileKey}`);
//     await get()
//     return { error, response: apiResponse }
// }

export const checkAudioSentimentScores = async (fileKey: string) => {
    const { apiResponse, error, get } = callApiWithAuth(`projects/audio/check-scores/${fileKey}`);
    await get()
    return { error, response: apiResponse }
}

export const createProject = async (name: string) => {
    const { apiResponse, error, post } = callApiWithAuth('projects/')
    const { company } = useAuth()
    const project = ref<Project>()
    if (!company?.value) {
        error.value = { message: 'No company provided' }
    } else {
        const payload = { name: name }
        await post(payload)

        project.value = apiResponse.value.project;
    }

    return { project, error }
}

export const getProjectDetails = async (projectId: number) => {
    const { apiResponse, error, get } = callApiWithAuth(`projects/${projectId}/details`)
    await get();
    const details: ProjectDetailsResponse = apiResponse.value
    return { error, details }
}

export const getAudioDetails = async (audioId: number) => {
    const { apiResponse, error, get } = callApiWithAuth(`projects/audio/${audioId}/details`)
    await get();
    const details: AudioAnalysisResult = apiResponse.value
    return { error, details }
}

export const deleteAudio = async (audioFileKey: string) => {
    const { apiResponse, error, deleteCall } = callApiWithAuth(`projects/audio/${audioFileKey}`)
    await deleteCall();
    const results = apiResponse; 
    return { error, results }
}

export const deleteProject = async (projectId: number) => {
    const { apiResponse, error, deleteCall } = callApiWithAuth(`projects/${projectId}`)
    await deleteCall();
    const results = apiResponse;
    return { error, results }
}
