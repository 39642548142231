import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Transition as _Transition, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1cd55290"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content flex flex-wrap" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "flex items-center font-bold my-2" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "px-1 py-1" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "m-5"
}
const _hoisted_10 = { class: "z-32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DotsVerticalIcon = _resolveComponent("DotsVerticalIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_ProjectDeletionModal = _resolveComponent("ProjectDeletionModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedProjects, (project) => {
        return (_openBlock(), _createElementBlock("div", {
          key: project.id,
          onClick: _withModifiers(($event: any) => (_ctx.routeTo(`/projects/${project.id}/details`)), ["self"]),
          class: "project-card cursor-pointer flex shadow-md w-52 h-24 items-center mx-5 my-5"
        }, [
          _createElementVNode("div", {
            onClick: ($event: any) => (_ctx.routeTo(`/projects/${project.id}/details`)),
            class: "project-logo h-10 w-10 mx-3"
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "w-full",
                onClick: ($event: any) => (_ctx.routeTo(`/projects/${project.id}/details`))
              }, _toDisplayString(project.name), 9, _hoisted_6),
              _createVNode(_component_Menu, {
                as: "div",
                class: "relative inline-block text-left"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_MenuButton, { class: "inline-flex justify-center w-full p-2 text-sm font-medium text-black bg-teal rounded-md bg-opacity-0 hover:bg-opacity-30" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DotsVerticalIcon, {
                          class: "w-5 h-5 text-black",
                          "aria-hidden": "true"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_Transition, {
                    "enter-active-class": "transition duration-100 ease-out",
                    "enter-from-class": "transform scale-95 opacity-0",
                    "enter-to-class": "transform scale-100 opacity-100",
                    "leave-active-class": "transition duration-75 ease-in",
                    "leave-from-class": "transform scale-100 opacity-100",
                    "leave-to-class": "transform scale-95 opacity-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MenuItems, { class: "absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none z-20" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_MenuItem, null, {
                              default: _withCtx(({ active }) => [
                                _createElementVNode("button", {
                                  class: _normalizeClass([
                                                        active
                                                            ? 'bg-red-600 text-white'
                                                            : 'text-red-600',
                                                        'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                                    ]),
                                  onClick: () => _ctx.deleteProject(project)
                                }, [
                                  _createVNode(_component_TrashIcon, {
                                    active: active,
                                    class: "w-5 h-5 mr-2 text-red-600",
                                    "aria-hidden": "true"
                                  }, null, 8, ["active"]),
                                  _createTextVNode(" Delete Project ")
                                ], 10, _hoisted_8)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ], 8, _hoisted_2))
      }), 128)),
      (!_ctx.computedProjects.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Nothing to see here!"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.projectToDelete)
        ? (_openBlock(), _createBlock(_component_ProjectDeletionModal, {
            key: 0,
            project: _ctx.projectToDelete,
            onDeleted: _ctx.projectDeleted
          }, null, 8, ["project", "onDeleted"]))
        : _createCommentVNode("", true)
    ])
  ]))
}