<template>
    <div class="login h-full">
        <div class="grid grid-cols-9 place-content-center h-full">
            <div class="col-span-9 md:col-start-3 md:col-span-5">
                <h2 class="text-left text-xl font-medium mb-1">Login</h2>
                <!-- Card -->
                <div class="onboarding-card shadow-md">
                    <div v-if="requestCompanyDetails">
                        <CompanyInfoForm
                            :companyData="companyData"
                            @company-submitted="companySubmitted"
                            :loading="loading||googleLoading"
                        ></CompanyInfoForm>
                        <div class="error-msg" v-if="responseError">
                            {{ responseError }}
                        </div>
                    </div>
                    <div v-else>
                        <div class="flex justify-center">
                            <Loader class="h-[50px]" v-if="googleLoading||loading" color="black" />
                        </div>
                        <div class="flex justify-center w-full m-3">
                            <GoogleSignInButton @token="tokenReceived" />
                        </div>
                        <div class="w-full">
                            <form class="space-y-4" @submit.prevent="loginSubmitted">
                                <div class="rounded-md space-y-2">
                                    <div>
                                        <label for="email-address" class="onboarding-label"
                                            >Email address</label
                                        >
                                        <input
                                            id="email-address"
                                            name="email"
                                            type="email"
                                            autocomplete="email"
                                            required="true"
                                            class="text-input"
                                            placeholder="Email address"
                                            v-model="email"
                                        />
                                        <div
                                            class="input-errors"
                                            v-for="error of v$.email.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">{{ error.$message }}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <label for="password" class="onboarding-label"
                                            >Password</label
                                        >
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            autocomplete="current-password"
                                            required="true"
                                            class="text-input"
                                            placeholder="Password"
                                            v-model="password"
                                        />
                                        <div
                                            class="input-errors"
                                            v-for="error of v$.password.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">{{ error.$message }}</div>
                                        </div>
                                        <div class="mr-3 underline text-blue-500">
                                            <router-link to="/forgot">Forgot password?</router-link>
                                        </div>
                                    </div>
                                    <ReCaptchaV2 v-if="!requestCompanyDetails" @token="tokenReceived" />
                                    <div class="error-msg" v-if="responseError">
                                        {{ responseError }}
                                    </div>
                                </div>
                                <div class="flex flex-col items-baseline space-x-2">
                                    <!-- Sign up with Google -->
                                    <div class="flex justify-between w-full">
                                        <div>
                                            <LoadingButton
                                                :disabled="v$.$invalid"
                                                :loading="loading"
                                                >Log In</LoadingButton
                                            >
                                        </div>

                                        <div class="register-btn">
                                            <router-link to="/register"
                                                >New? Create an account</router-link
                                            >
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { googleLogin, login, useAuth, saveCompanyDetails } from '@/services/auth'
import LoadingButton from '@/components/global/LoadingButton.vue'
import router from '@/router'
import ReCaptchaV2 from '@/components/global/ReCaptchaV2.vue'
import GoogleSignInButton from '@/components/global/GoogleSignInButton.vue'
import { ICompanyInfoFormData } from '@/models/FormData'
import CompanyInfoForm from '@/components/onboarding/CompanyInfoForm.vue'
import Loader from '@/components/global/Loader.vue'

export default defineComponent({
    components: { LoadingButton, ReCaptchaV2, GoogleSignInButton, CompanyInfoForm, Loader },
    setup() {
        const v$ = useVuelidate()
        const { user } = useAuth()
        watch([user], () => {
            console.log('User:', user?.value)
            if (user?.value?.id && user.value?.company) {
                console.log("Watch gave up: ", user?.value?.id , user.value?.company, user?.value?.id && user.value?.company);
                router.replace('/dashboard/teams')
            }
        })
        return { v$ }
    },
    data() {
        return {
            email: '',
            password: '',
            responseError: '',
            loading: false,
            googleLoading: false,
            recaptchaToken: '',
            requestCompanyDetails: false,
            ongoingGoogleAuthFlow: false,
        }
    },
    validations() {
        return {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
        }
    },
    methods: {
        async loginSubmitted() {
            if (!this.recaptchaToken) {
                this.responseError = 'Please complete the reCAPTCHA'
                return
            }
            this.responseError = ''
            this.loading = true
            const { error } = await login(this.email, this.password, this.recaptchaToken)
            this.loading = false

            if (error.value != undefined) {
                this.responseError = error.value.message
            } else {
                // Route to dashboard
                console.log("Login submitted gave up: ",error.value);
                router.push('/dashboard/teams')
            }
        },
        async tokenReceived(token: string) {
            this.recaptchaToken = token
            this.responseError = ''
            this.googleLoading = true
            const { error, apiResponse } = await googleLogin(token)
            this.requestCompanyDetails = apiResponse.value?.requestCompanyDetails;
            this.googleLoading = false
            if (error.value != undefined) {
                this.responseError = error.value.message
            } else {
                // Route to dashboard
                if (this.requestCompanyDetails===false) {
                    console.log("requestCompanyDetails gave up", this.requestCompanyDetails);
                    router.push('/dashboard/teams')
                }
            }
        },
        async companySubmitted(data: { company: ICompanyInfoFormData; recaptchaToken: string }) {
            const { company, recaptchaToken } = data

            this.loading = true

            const { apiResponse, error } = await saveCompanyDetails(company)
            this.loading = false
            if (error.value != undefined) {
                console.log('Error:', error.value)
                this.responseError = error.value.message
            } else {
                    console.log("company submitted gave up", error.value);

                router.push('/dashboard/teams')
            }
        },
    },
    updated() {
        const { user } = useAuth()

        if (user?.value?.id && user.value?.company) {
            console.log("navigating updated...",user?.value?.id, user.value?.company )
            router.replace('/dashboard/teams')
        }
    },
})
</script>

<style></style>
