<template>
    <div class="forgot-password h-full">
        <div class="grid grid-cols-9 place-content-center h-full">
            <div class="col-span-9 md:col-start-3 md:col-span-5">
                <h2 class="text-left text-xl font-medium mb-1">Forgot Password</h2>
                <div class="onboarding-card">
                    <form @submit.prevent="forgotSubmitted">
                        <div class="overflow-hidden sm:rounded-md">
                            <div class="">
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6">
                                        <label for="email-address" class="onboarding-label"
                                            >Email</label
                                        >
                                        <input
                                            type="email"
                                            name="email-address"
                                            id="email-address"
                                            autocomplete="email"
                                            class="text-input"
                                            v-model="v$.email.$model"
                                        />
                                    </div>
                                </div>
                                <div class="error-msg" v-if="responseError">
                                    {{ responseError }}
                                </div>
                            </div>
                            <div class="mt-4 text-right">
                                <LoadingButton
                                    :disabled="v$.$invalid || success"
                                    :loading="loading"
                                    >{{ success ? 'Request Sent' : 'Request Reset' }}</LoadingButton
                                >
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { defineComponent } from 'vue'
import LoadingButton from '@/components/global/LoadingButton.vue'
import { forgotPassword } from '@/services/auth'

export default defineComponent({
    components: { LoadingButton },
    setup() {
        const v$ = useVuelidate()

        return { v$ }
    },
    data() {
        return {
            email: '',
            loading: false,
            responseError: '',
            success: false,
        }
    },
    validations() {
        return {
            email: {
                required,
                email,
            },
        }
    },
    methods: {
        async forgotSubmitted() {
            this.loading = true
            const { error } = await forgotPassword(this.email)
            this.loading = false

            if (error.value != undefined) {
                this.responseError = error.value.message
            } else {
                this.success = true
            }
        },
    },
})
</script>
