<template>
    <div class="p-2">
        <div class="mb-1 flex flex-row items-center" v-if="team && !hideTitle">
            <router-link :to="{ name: 'teams.details', params: { id: team.id } }">
                <h3 class="text-left font-semibold">{{ team.name }}</h3>
            </router-link>
            <div class="flex-grow"></div>
            <Menu as="div" class="relative inline-block text-left" v-if="canDelete == true">
                <div>
                    <MenuButton class="
                            inline-flex
                            justify-center
                            w-full
                            p-2
                            text-sm
                            font-medium
                            text-black
                            bg-teal
                            rounded-md
                            bg-opacity-0
                            hover:bg-opacity-30
                        ">
                        <DotsVerticalIcon class="w-5 h-5 text-black" aria-hidden="true" />
                    </MenuButton>
                </div>

                <transition enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
                    <MenuItems class="
                            absolute
                            right-0
                            w-56
                            mt-2
                            origin-top-right
                            bg-white
                            divide-y divide-gray-100
                            rounded-md
                            shadow-lg
                            focus:outline-none
                            z-20
                        ">
                        <div class="px-1 py-1">
                            <MenuItem v-slot="{ active }">
                            <button :class="[
            active ? 'bg-red-600 text-white' : 'text-red-600',
            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
        ]" @click="deleteClicked">
                                <TrashIcon :active="active" class="w-5 h-5 mr-2 text-red-600" aria-hidden="true" />
                                Delete Team
                            </button>
                            </MenuItem>
                        </div>
                    </MenuItems>
                </transition>
            </Menu>
        </div>
        <div class="mb-1 flex flex-row items-center" v-if="channel && !hideTitle">
            <h3 class="text-left font-semibold">#{{ channel.name }}</h3>
            <div class="flex-grow"></div>
            <Menu as="div" class="relative inline-block text-left" v-if="canDelete == true">
                <div>
                    <MenuButton class="
                            inline-flex
                            justify-center
                            w-full
                            p-2
                            text-sm
                            font-medium
                            text-black
                            bg-teal
                            rounded-md
                            bg-opacity-0
                            hover:bg-opacity-30">
                        <DotsVerticalIcon class="w-5 h-5 text-black" aria-hidden="true" />
                    </MenuButton>
                </div>

                <transition enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
                    <MenuItems class="
                            absolute
                            right-0
                            w-56
                            mt-2
                            origin-top-right
                            bg-white
                            divide-y divide-gray-100
                            rounded-md
                            shadow-lg
                            focus:outline-none
                            z-20
                        ">
                        <div class="px-1 py-1">
                            <MenuItem v-slot="{ active }">
                            <button :class="[
            active ? 'bg-red-600 text-white' : 'text-red-600',
            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
        ]" @click="deleteClicked">
                                <TrashIcon :active="active" class="w-5 h-5 mr-2 text-red-600" aria-hidden="true" />
                                Delete Channel
                            </button>
                            </MenuItem>
                        </div>
                    </MenuItems>
                </transition>
            </Menu>
        </div>
        <div class="onboarding-card space-y-2">
            <div :title="`Inspect ${score.title}`" v-for="score in scores" :key="score.title"
                class="flex relative z-10 cursor-pointer" @click="() => inspectEmotion(score.name, score.title)">
                <div class="absolute h-full rounded -z-10" :class="score.colorClass"
                    :style="{ width: score.percentage, backgroundColor: score.colorClass }"></div>
                <p class="p-1 pl-2 w-1/2 text-left">{{ score.title }}</p>
                <p class="p-1 pr-2 w-1/2 text-right">{{ score.percentage }}</p>
            </div>
        </div>
    </div>

    <div>
        <EmotionInsightMessagesModal v-if="showEmotionInsightsMessages" :messages="emotionInsightMessages"
            :loading="loadingEmotionInsightsMessages" :emotion="selectedEmotion as string"
            :errorMessage="errorMessage as string" @closed="showEmotionInsightsMessages = false">
        </EmotionInsightMessagesModal>

    </div>
</template>

<script lang="ts">
import CompanyTeam from '@/models/CompanyTeam'
import CompanyTeamChannel from '@/models/CompanyTeamChannel'
import { EmotionInsightMessage } from '@/models/EmotionInsightMessage'
import { displayForEmotionScore, EmotionScore, EmotionScoreDisplay } from '@/models/EmotionScore'
import { getLastMessagesWithEmotion } from '@/services/teams'
import { Menu, MenuItems, MenuButton, MenuItem } from '@headlessui/vue'
import { TrashIcon, DotsVerticalIcon } from '@heroicons/vue/solid'
import { defineComponent, PropType, ref, toRefs, watch } from 'vue'
import EmotionInsightMessagesModal from '../teams/EmotionInsightMessagesModal.vue'

export default defineComponent({
    components: { Menu, MenuItems, MenuItem, MenuButton, TrashIcon, DotsVerticalIcon, EmotionInsightMessagesModal },
    props: {
        emotionScore: {
            type: Object as PropType<EmotionScore>,
            required: true,
        },
        team: {
            type: Object as PropType<CompanyTeam>,
            required: false,
        },
        channel: {
            type: Object as PropType<CompanyTeamChannel>,
            required: false,
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['deleteClicked'],
    setup(props, { emit }) {
        const { emotionScore } = toRefs(props)
        const scores = ref<EmotionScoreDisplay[]>([])
        const emotionInsightMessages = ref<EmotionInsightMessage[]>([])
        const showEmotionInsightsMessages = ref(false);
        const loadingEmotionInsightsMessages = ref(false);
        const errorMessage = ref<string | null>(null);
        const selectedEmotion = ref<string | null>(null);
        scores.value = displayForEmotionScore(emotionScore.value)
        watch(emotionScore, (newScores) => {
            scores.value = displayForEmotionScore(newScores)
        })

        const deleteClicked = () => {
            emit('deleteClicked')
        }

        const inspectEmotion = async (name: string, title: string) => {
            if(!props.channel) {
                return;
            }
            selectedEmotion.value = title;
            showEmotionInsightsMessages.value = true;
            loadingEmotionInsightsMessages.value = true;
            errorMessage.value = null;
            emotionInsightMessages.value = [];
            // fetch the insight messages
            const { messages, error } = await getLastMessagesWithEmotion(1, props.channel!.channelId, name);
            if (error.value) {
                if (typeof error.value === 'string' && !error.value.startsWith('<html>')) {
                    errorMessage.value = error.value;
                } else {
                    errorMessage.value = 'An error occurred while fetching the messages';
                }
                loadingEmotionInsightsMessages.value = false;

                return
            }
            errorMessage.value = null;
            emotionInsightMessages.value = messages;
            showEmotionInsightsMessages.value = true;
            loadingEmotionInsightsMessages.value = false;


        }

        return {
            scores,
            deleteClicked,
            inspectEmotion,
            emotionInsightMessages,
            showEmotionInsightsMessages,
            loadingEmotionInsightsMessages,
            errorMessage,
            selectedEmotion
        }
    },
})
</script>

<style scoped></style>
