<template>
    <div class="company-info-form">
        <form @submit.prevent="companyInfoSubmitted">
            <div class="sm:rounded-md">
                <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6">
                        <label for="company-name" class="onboarding-label">Company Name</label>
                        <input type="text" name="company-name" id="company-name" autocomplete="organization"
                            class="text-input" v-model="v$.name.$model" />
                        <div class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
                            <div class="error-msg">{{ error.$message }}</div>
                        </div>
                    </div>
                    <div class="col-span-6">
                        <p>Company Size</p>
                        <div class="w-72">
                            <Listbox v-model="selectedBracket">
                                <div class="relative mt-1">
                                    <ListboxButton class="
                                            relative
                                            w-full
                                            py-2
                                            pl-3
                                            pr-10
                                            text-left
                                            rounded-md
                                            cursor-default
                                            sm:text-sm
                                            border-gray-500 border-2
                                        ">
                                        <span class="block truncate">{{
                                            selectedBracket.title
                                        }}</span>
                                        <span class="
                                                absolute
                                                inset-y-0
                                                right-0
                                                flex
                                                items-center
                                                pr-2
                                                pointer-events-none
                                            ">
                                            <SelectorIcon class="w-5 h-5 text-gray-500" aria-hidden="true" />
                                        </span>
                                    </ListboxButton>

                                    <transition leave-active-class="transition duration-100 ease-in"
                                        leave-from-class="opacity-100" leave-to-class="opacity=0">
                                        <ListboxOptions class="
                                                absolute
                                                w-full
                                                py-1
                                                mt-1
                                                overflow-auto
                                                text-base
                                                bg-white
                                                rounded-md
                                                shadow-lg
                                                max-h-60
                                            ">
                                            <ListboxOption v-slot="{ active, selected }" v-for="size in sizeBrackets"
                                                :key="size.id" :value="size" as="template">
                                                <li :class="[
                                                    active
                                                        ? 'text-white bg-teal'
                                                        : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-10 pr-4',
                                                ]">
                                                    <span :class="[
                                                        selected
                                                            ? 'font-medium'
                                                            : 'font-normal',
                                                        'block truncate',
                                                    ]">{{ size.title }}</span>
                                                    <span v-if="selected" class="
                                                            absolute
                                                            inset-y-0
                                                            left-0
                                                            flex
                                                            items-center
                                                            pl-3
                                                            text-amber-600
                                                        ">
                                                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                                    </span>
                                                </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                </div>
                <ReCaptchaV2 class="pt-5" @token="tokenReceived" />
                <div class="error-msg" v-if="responseError">{{ responseError }}</div>
                <div class="flex flex-row mt-4 items-baseline">
                    <div class="flex-grow"></div>
                    <div>
                        <LoadingButton :disabled="v$.$invalid || loading" :loading="loading">Save Company Information
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { defineComponent, PropType, reactive, ref, toRefs } from 'vue'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import LoadingButton from '../global/LoadingButton.vue'
import { ICompanyInfoFormData } from '@/models/FormData'
import ReCaptchaV2 from '../global/ReCaptchaV2.vue'

export default defineComponent({
    components: {
        Listbox,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        CheckIcon,
        SelectorIcon,
        LoadingButton,
        ReCaptchaV2
    },
    emits: ['company-submitted'],
    props: {
        companyData: {
            type: Object as PropType<ICompanyInfoFormData>,
            required: false,
        },
        loading: {
            default: false,
        },
    },
    setup(props) {
        const { companyData } = toRefs(props)
        const sizeBrackets = [
            { id: 1, title: '10 - 50', value: '10-50' },
            { id: 2, title: '51 - 100', value: '51-100' },
            { id: 3, title: '101 - 150', value: '101-150' },
            { id: 4, title: '151 - 250', value: '151-250' },
            { id: 5, title: '251 - 300', value: '251-300' },
            { id: 6, title: '301 - 400', value: '301-400' },
            { id: 7, title: '401 - 500', value: '401-500' },
            { id: 8, title: '501+', value: '501+' },
        ]
        const sizeString = companyData.value?.companySizeString
        let matchIndex = 0
        if (sizeString) {
            matchIndex = sizeBrackets.findIndex((b) => b.value == sizeString)
        }
        const selectedBracket = ref(sizeBrackets[matchIndex])

        const state: ICompanyInfoFormData = reactive({
            name: companyData.value?.name || '',
            companySizeString: selectedBracket.value.value,
        })

        const rules = {
            name: {
                required,
            },
            companySizeString: {
                required,
            },
        }
        const v$ = useVuelidate(rules, state)

        return { v$, sizeBrackets, selectedBracket, state }
    },
    data() {
        return {
            companyName: '',
            companySize: '',
            responseError: '',
            recaptchaToken: '',
        }
    },
    methods: {
        companyInfoSubmitted() {
            this.responseError = ''
            this.state.companySizeString = this.selectedBracket.value

            if (!this.recaptchaToken) {
                this.responseError = "Please solve the CAPTCHA to proceed";
                return;
            }
            this.responseError = '';
            this.$emit('company-submitted', {
                company: this.state,
                recaptchaToken: this.recaptchaToken,
            })
        },
        tokenReceived(token: string) {
            this.recaptchaToken = token;
        },
    },
})
</script>
