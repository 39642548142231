<template>
<div class="mt-10 text-lg font-bold flex flex-col items-center justify-center">
        <h2 class="title align-center">MoodConnect Pricing </h2>

</div>
    <div class="flex content-center justify-center">
        <SubscriptionSelection />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SubscriptionSelection from '../components/settings/SubscriptionSelection.vue'

export default defineComponent({
    components: {
        SubscriptionSelection
    }
})
</script>
