<template>
    <div class="container">
        <span class="tab-title">Manage Reports</span>
        <div class="form">
            <div v-if="!downloadTeamId">No teams to display</div>
            <div v-if="teamsRef.length > 0" class="form-row w-1/2">
                <label class="form-label">Download Report</label>
                <div class="download">
                    <label class="form-label">Select team</label>
                    <select class="form-field team-selection" v-model="downloadTeamId">
                        <option class="select-option" v-for="team in teamsRef" :value="team.id" :key="team.id">{{ team.name
                        }}
                        </option>
                    </select>
                    <div>
                        <label class="form-label">Select date range</label>

                        <litepie-datepicker class="datepicker" :disable-date="futureDates" placeholder="Select date range"
                            :disabled="isDownloading" :formatter="{ date: 'YYYY-MM-DD', month: 'MMM' }"
                            v-model="downloadDateRange">
                        </litepie-datepicker>
                    </div>
                    <div class="download-button">
                        <label class="form-label"></label>
                        <LoadingButton @click="handleDownloadSelection" :loading="isDownloading" :disabled="isDownloading">
                            Download
                        </LoadingButton>
                        <div class="error" v-if="errorMessage">
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="downloadTeamId" class="form-row w-1/2">
                <label class="form-label">Schedule report</label>
                <div class="email-container form-field">
                    <input v-model="targetEmail" type="email" placeholder="Enter email address"
                        class="form-field email-input" />
                    <div class="date-frequency-container">
                        <div class="date">
                            <label class="form-label">Select date</label>

                            <litepie-datepicker as-single placeholder="Select date" class="datepicker"
                                :disable-date="pastDates" :disabled="isDownloading"
                                :formatter="{ date: 'YYYY-MM-DD', month: 'MMM' }" v-model="scheduleStartDate">
                            </litepie-datepicker>
                        </div>
                        <div class="freq">
                            <label class="form-label">Select frequency</label>

                            <select class="freq-selector" v-model="frequency">
                                <option value="ONCE">Send Once</option>
                                <option value="DAILY">Daily (Day's Report)</option>
                                <option value="WEEKLY">Weekly (Week's Report)</option>
                                <option value="MONTHLY">Monthly (Month's Report)</option>
                            </select>
                        </div>
                    </div>

                    <div class="schedule-button">
                        <LoadingButton @click="handleScheduleReport" :loading="isScheduling" :disabled="isScheduling">
                            {{
                                shouldSendNow ? 'Send now' : 'Schedule'
                            }}
                        </LoadingButton>
                        <div class="error" v-if="schedulingErrorMessage">
                            {{ schedulingErrorMessage }}
                        </div>
                        <div class="success" v-if="schedulingSuccessMessage">
                            {{ schedulingSuccessMessage }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts">
import { defineComponent, ref, Ref, watch } from 'vue'
import LoadingButton from '@/components/global/LoadingButton.vue'
import LitepieDatepicker from 'litepie-datepicker'
import { getTeams, sendTeamReport, scheduleTeamReport } from '@/services/teams'
import CompanyTeam from '@/models/CompanyTeam'
import { DashboardEntry, getDashboardByDateRange } from '@/services/dashboard'
import { useAuth } from '@/services/auth'


export default defineComponent({
    name: 'ReportForm',
    components: {
        LoadingButton,
        LitepieDatepicker
    },
    setup() {
        const teamsRef: Ref<Array<CompanyTeam>> = ref([])

        const downloadDateRange = ref([]);
        const scheduleStartDate = ref([]);
        const downloadTeamId = ref(0);
        const dashboardEntryToDownload: Ref<DashboardEntry> = ref({} as DashboardEntry)
        const errorMessage = ref('')
        const schedulingErrorMessage = ref('')
        const schedulingSuccessMessage = ref('')
        const shouldSendNow = ref(true)
        const frequency = ref('ONCE')
        const isDownloading = ref(false)
        const isScheduling = ref(false)
        let previousDashboardRange: string = ''

        const { user } = useAuth()

        const targetEmail = ref(user?.value?.email || '')



        getTeams().then(({ teams, error }) => {
            teamsRef.value = teams;
            downloadTeamId.value = teams[0].id;
        })


        const handleDownloadSelection = async () => {
            errorMessage.value = '';
            schedulingErrorMessage.value = '';
            schedulingSuccessMessage.value = '';

            if (downloadDateRange.value.length == 0) {
                errorMessage.value = 'Please select a date range.';
                setTimeout(() => {
                    errorMessage.value = '';
                }, 5000);
                return;
            }
            isDownloading.value = true;
            if (!previousDashboardRange || previousDashboardRange != `${downloadDateRange.value[0]}-${downloadDateRange.value[1]}`) {
                const { entries: responseEntries } = await getDashboardByDateRange(
                    downloadDateRange.value[0], downloadDateRange.value[1]);
                dashboardEntryToDownload.value = responseEntries.value.find((entry: DashboardEntry) => entry.team.id == downloadTeamId.value) as DashboardEntry;
                previousDashboardRange = `${downloadDateRange.value[0]}-${downloadDateRange.value[1]}`
            }

            if (!dashboardEntryToDownload.value || dashboardEntryToDownload.value.scores.happy == 0 && dashboardEntryToDownload.value.scores.angry == 0 && dashboardEntryToDownload.value.scores.sad == 0 && dashboardEntryToDownload.value.scores.neutral == 0 && dashboardEntryToDownload.value.scores.surprise == 0 && dashboardEntryToDownload.value.scores.fear == 0 && dashboardEntryToDownload.value.scores.disgust == 0) {
                errorMessage.value = 'No data found for the selected date range.';
                setTimeout(() => {
                    errorMessage.value = '';
                }, 5000);
                isDownloading.value = false;
                return;
            }
            
            await saveJsonAsTxtFile();
            isDownloading.value = false;
        }


        const saveJsonAsTxtFile = () => {
            return new Promise(
                (resolve, reject) => {
                    const startDate = downloadDateRange.value[0]
                    const endDate = downloadDateRange.value[1]
                    const fileName = `Average-emotion-scores-team-${dashboardEntryToDownload.value.team.name}-${startDate}-${endDate}.csv`
                    const txtData = convertToScoresToTxt();
                    const blob = new Blob([txtData], { type: 'text/text' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.setAttribute('href', url);
                    link.setAttribute('download', fileName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    resolve(true);
                }
            )
        }

        const convertToScoresToTxt = () => {
            const scoresObj = dashboardEntryToDownload.value.scores;
            const teamName = dashboardEntryToDownload.value.team.name;

            const startDate = new Date(downloadDateRange.value[0]).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
            const endDate = new Date(downloadDateRange.value[1]).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

            return `Team Name,${teamName}
Start Date,"${startDate}"
End Date,"${endDate}"
Enthusiastic,${((scoresObj.happy || 0) * 100).toFixed(2)} %
Frustrated,${((scoresObj.angry || 0) * 100).toFixed(2)} %
Burned Out,${((scoresObj.sad || 0) * 100).toFixed(2)} %
Satisfied,${((scoresObj.neutral || 0) * 100).toFixed(2)} %
Confused,${((scoresObj.surprise || 0) * 100).toFixed(2)} %
Uncertain,${((scoresObj.fear || 0) * 100).toFixed(2)} %
Annoyed,${((scoresObj.disgust || 0) * 100).toFixed(2)} %
`
        }


        const pastDates = (currentDate: Date) => {
            return currentDate && currentDate.getTime() < Date.now();
        }

        const futureDates = (currentDate: Date) => {
            return currentDate && currentDate.getTime() > Date.now();
        }

        const handleScheduleReport = async () => {
            errorMessage.value = '';
            schedulingErrorMessage.value = '';
            schedulingSuccessMessage.value = '';

            isScheduling.value = true;
            // logic for scheduling report
            if (shouldSendNow.value) {
                if (downloadDateRange.value.length == 0 && frequency.value == 'ONCE') {
                    errorMessage.value = 'Please select a date range to be included in the report.';
                    schedulingErrorMessage.value = 'Please select a date range in the download section.';
                    setTimeout(() => {
                        errorMessage.value = '';
                        schedulingErrorMessage.value = '';
                    }, 10000);
                    isScheduling.value = false;
                    return;
                }
                const { postPromise, apiResponse, error } = sendTeamReport(downloadTeamId.value, downloadDateRange.value[0], downloadDateRange.value[1], targetEmail.value)
                await postPromise;
                const result = apiResponse.value;
                if (error.value?.error) {
                    schedulingErrorMessage.value = error.value?.error || 'Failed to send the report email. Please try again later.';
                }
                else {
                    if (result.status == 'success') {
                        schedulingSuccessMessage.value = 'Report sent successfully.';
                        setTimeout(() => {
                            schedulingSuccessMessage.value = '';
                        }, 5000);
                    }
                    else {
                        schedulingErrorMessage.value = result.message;
                    }
                    setTimeout(() => {

                        schedulingSuccessMessage.value = '';
                    }, 5000);

                }
            } else {
                if (downloadDateRange.value.length == 0 && frequency.value == 'ONCE') {
                    errorMessage.value = 'Please select a date range to be included in the report.';
                    schedulingErrorMessage.value = 'Please select a date range in the download section.';
                    setTimeout(() => {
                        errorMessage.value = '';
                        schedulingErrorMessage.value = '';
                    }, 10000);
                    isScheduling.value = false;
                    return;
                }

                if ((frequency.value == 'DAILY' || frequency.value == 'WEEKLY' || frequency.value == 'MONTHLY') && !scheduleStartDate.value.length) {
                    schedulingErrorMessage.value = 'Please select a start date for the report.';
                    setTimeout(() => {
                        schedulingErrorMessage.value = '';
                    }, 10000);
                    isScheduling.value = false;
                    return;
                }

                if (targetEmail.value == '' || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(targetEmail.value)) {
                    schedulingErrorMessage.value = 'Please enter a valid email address.';
                    setTimeout(() => {
                        errorMessage.value = '';
                        schedulingErrorMessage.value = '';
                    }, 5000);
                    isScheduling.value = false;
                    return;
                }

                // logic for scheduling report
                const { postPromise, apiResponse, error } = scheduleTeamReport(downloadTeamId.value,
                    downloadDateRange.value[0], downloadDateRange.value[1],
                    targetEmail.value, scheduleStartDate.value[0],
                    frequency.value);
                await postPromise;
                const result = apiResponse.value;
                if (error.value?.error) {
                    schedulingErrorMessage.value = error.value?.error?.name == 'DBError' ? 'Failed to schedule the report email. Please try again later.' : error.value?.error;
                }
                else {
                    if (result?.id) {
                        schedulingSuccessMessage.value = 'Report scheduled successfully.';
                        setTimeout(() => {

                            schedulingSuccessMessage.value = '';
                        }, 5000);
                    } else {
                        schedulingErrorMessage.value = 'Failed to schedule the report email. Please try again later.';
                        setTimeout(() => {
                            schedulingErrorMessage.value = '';
                        }, 5000);
                    }
                }

            }

            isScheduling.value = false;
        }

        watch(scheduleStartDate, (newVal, oldVal) => {
            errorMessage.value = '';
            schedulingErrorMessage.value = '';
            schedulingSuccessMessage.value = '';
            if (!newVal.length && frequency.value == 'ONCE') {
                shouldSendNow.value = true;
                return;
            } else if (!newVal.length && (frequency.value == 'DAILY' || frequency.value == 'WEEKLY' || frequency.value == 'MONTHLY')) {
                shouldSendNow.value = false;
                return;
            }

            const startDate = new Date(scheduleStartDate.value[0]).toISOString().split('T')[0];
            const today = new Date().toISOString().split('T')[0];

            shouldSendNow.value = startDate == today;

        })

        watch(frequency, (newVal, oldVal) => {
            errorMessage.value = '';
            schedulingErrorMessage.value = '';
            schedulingSuccessMessage.value = '';
            if (newVal == 'ONCE') {
                shouldSendNow.value = true;
                return;
            } else {
                shouldSendNow.value = false;
            }
        })

        return {
            teamsRef, handleDownloadSelection,
            handleScheduleReport, isDownloading,
            isScheduling, downloadDateRange, downloadTeamId,
            errorMessage,
            pastDates,
            futureDates,
            scheduleStartDate,
            shouldSendNow,
            schedulingErrorMessage,
            schedulingSuccessMessage,
            targetEmail,
            frequency
        }
    },
})
</script>
  
<style scoped> .tab-title {
     font-size: 1.2em;
     margin-bottom: 20px;
     margin-left: 15px;
 }

 .form {
     padding: 20px;
     border-radius: 5px;
 }

 .download {
     display: flex;
     flex-direction: column;
 }

 .form-row {
     display: flex;
     flex-direction: column;
 }

 .form-label {
     margin: 10px 0;
     color: #333;

 }

 .form-field {
     flex: 1 0 auto;
     border-radius: 3
 }

 .email-container {
     display: flex;
     flex-direction: column;
     flex-grow: 2;
 }

 .email-input {
     border: 1px solid #ccc;
     background-color: #fff;
     border-radius: 3px;
     padding: 5px;
     margin-bottom: 10px;
 }

 .team-selection,
 .freq-selector {
     border: 1px solid #ccc;
     background-color: #fff;
     border-radius: 3px;
     padding: 5px;
 }

 .form-row+.form-row {
     margin-top: 10px;
 }

 .download-button,
 .schedule-button {
     display: flex;
     margin: 15px 0;
     align-items: center;
 }

 .datepicker {
     margin: 5px 0;
     margin-top: 10px;

 }

 .error {
     color: red;
     font-size: 0.8em;
     margin-left: 10px;
 }

 .success {
     color: green;
     font-size: 0.8em;
     margin-left: 10px;
 }

 .select-option {
     padding: 5px;
     border: 1px solid #ccc;
 }

 .date,
 .freq {
     display: flex;
     flex-direction: column;

 }


 .freq {
     display: flex;


 }
</style>
  