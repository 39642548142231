<template>
    <div class="settings p-4">
        <p class="text-xl mb-4 pl-2">Settings</p>
        <TabGroup  :defaultIndex="selectedTab"  vertical class="grid grid-cols-10 gap-6" v-if="!loadingInitial">
            <div class="">
                <div class="col-span-10 md:col-span-2">
                    <TabList class="flex flex-col space-y-2 rounded-md">
                        <Tab v-slot="{ selected }">
                            <div class="w-full rounded-lg p-2" :class="[selected ? 'bg-teal text-white' : '']">
                                <p class="text-left">User Settings</p>
                            </div>
                        </Tab>
                        <Tab v-slot="{ selected }">
                            <div class="w-full rounded-lg p-2" :class="[selected ? 'bg-teal text-white' : '']">
                                <p class="text-left">Update Password</p>
                            </div>
                        </Tab>
                        <Tab v-if="isAdmin" v-slot="{ selected }">
                            <div class="w-full rounded-lg p-2" :class="[selected ? 'bg-teal text-white' : '']">
                                <p class="text-left">Company Info</p>
                            </div>
                        </Tab>
                        <Tab v-if="isAdmin" v-slot="{ selected }">
                            <div class="w-full rounded-lg p-2" :class="[selected ? 'bg-teal text-white' : '']">
                                <p class="text-left">Apps Connection</p>
                            </div>
                        </Tab>
                        <Tab v-slot="{ selected }">
                            <div class="w-full rounded-lg p-2" :class="[selected ? 'bg-teal text-white' : '']">
                                <p class="text-left">User Managment</p>
                            </div>
                        </Tab>
                        <Tab v-slot="{ selected }">
                            <div class="w-full rounded-lg p-2" :class="[selected ? 'bg-teal text-white' : '']">
                                <p class="text-left">Reports</p>
                            </div>
                        </Tab>
                        <Tab v-if="isAdmin && !msAppLoaded || isAdmin && msAppLoaded && !isMobile" v-slot="{ selected }">
                            <div class="w-full rounded-lg p-2" :class="[selected ? 'bg-teal text-white' : '']">
                                <p class="text-left">Payment Info</p>
                            </div>
                        </Tab>
                        <Tab v-if="isAdmin && !msAppLoaded || isAdmin && msAppLoaded && !isMobile" v-slot="{ selected }">
                            <div class="w-full rounded-lg p-2" :class="[selected ? 'bg-teal text-white' : '']">
                                <p class="text-left">
                                    Subscription
                                    <!-- <a
                                        href="https://www.moodconnect.com/plans-pricing"
                                        target="_blank"
                                        >Subscription</a
                                    > -->
                                </p>
                            </div>
                        </Tab>
                    </TabList>
                </div>
                <div class="col-span-10 md:col-span-8">
                    <TabPanels>
                        <TabPanel>
                            <div class="onboarding-card">
                                <div class="w-full">
                                    <UserInfoForm @user="updateUserSubmitted" :loading="loadingUserUpdate"
                                        :userData="userData" :isUpdate="true"></UserInfoForm>
                                    <div class="error-msg" v-if="userError">
                                        {{ userError }}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div class="onboarding-card">
                                <div class="w-full">
                                    <ResetPasswordForm :isSettings="true"></ResetPasswordForm>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel v-if="isAdmin">
                            <div class="onboarding-card">
                                <div class="w-full">
                                    <CompanyInfoForm :loading="loadingCompanyUpdate" :companyData="companyData"
                                        @company="updateCompanySubmitted"></CompanyInfoForm>
                                    <div class="error-msg" v-if="companyError">
                                        {{ companyError }}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel v-if="isAdmin">
                            <div class="onboarding-card">
                                <div class="w-full">
                                    <AppConnection></AppConnection>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div class="onboarding-card">
                                <div class="w-full">
                                    <div class="block flex flex-row mb-4">
                                        <div>
                                            <h2 class="text-left font-medium text-xl">All Users</h2>
                                        </div>
                                        <div class="flex-grow"></div>
                                        <div v-if="isAdmin">
                                            <!-- User Invite Modal -->
                                            <CompanyUserInviteModal></CompanyUserInviteModal>
                                        </div>
                                    </div>
                                    <CompanyUserList></CompanyUserList>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>

                            <div class="onboarding-card">
                                <div>
                                    <ReportForm></ReportForm>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel v-if="isAdmin">
                            <div class="onboarding-card">
                                <div class="w-full">
                                    <UserPaymentEntry></UserPaymentEntry>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel v-if="isAdmin">
                            <div class="onboarding-card">
                                <div class="w-full">
                                    <SubscriptionSelection></SubscriptionSelection>
                                </div>
                            </div>
                        </TabPanel>
                    </TabPanels>
                </div>
            </div>
        </TabGroup>
    </div>
</template>

<script lang="ts">
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from '@headlessui/vue'
import UserInfoForm from '@/components/onboarding/UserInfoForm.vue'
import ReportForm from '@/components/onboarding/ReportForm.vue'
import CompanyInfoForm from '@/components/onboarding/CompanyInfoForm.vue'
import { computed, defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { updateUser } from '@/services/me'
import SubscriptionSelection from '@/components/settings/SubscriptionSelection.vue'
import CompanyUserList from '@/components/settings/CompanyUserList.vue'
import CompanyUserInviteModal from '@/components/settings/CompanyUserInviteModal.vue'
import ResetPasswordForm from '@/components/onboarding/ResetPasswordForm.vue'
import {
    CompanyInfoFormData,
    ICompanyInfoFormData,
    IUserInfoFormData,
    UserInfoFormData,
} from '@/models/FormData'
import { useAuth } from '@/services/auth'
import { updateCompany } from '@/services/companies'
import { editFormData } from '@/models/Company'
import UserPaymentEntry from '@/components/settings/UserPaymentEntry.vue'
import AppConnection from '@/components/settings/AppConnection.vue'

export default defineComponent({
    components: {
        TabGroup,
        TabList,
        Tab,
        TabPanels,
        TabPanel,
        UserInfoForm,
        ReportForm,
        CompanyInfoForm,
        SubscriptionSelection,
        CompanyUserList,
        CompanyUserInviteModal,
        ResetPasswordForm,
        UserPaymentEntry,
        AppConnection,
    },
    setup() {

        const route = useRoute();
        // Probably going to be very worth breaking these out into separate "update components", so that the settings view isn't managing as much logic
        // Check path on mounted to see what tab to display
        const { user, company, msAppLoaded, isMobile } = useAuth()
        const selectedTab = ref(route.params.scrollTo == 'apps' ? 3 : 3)
        const isAdmin = ref(company?.value?.isAdmin() == true)
        const loadingUserUpdate = ref(false)
        const userError = ref<string>()
        const updateUserSubmitted = async (user: IUserInfoFormData) => {
            loadingUserUpdate.value = true
            const { error } = await updateUser(user)
            loadingUserUpdate.value = false
            if (error.value) {
                userError.value = error.value.message
            }
        }
        const loadingInitial = computed(() => {
            return user?.value == undefined || company?.value == undefined
        })
        const userData = ref(user?.value?.editFormData() ?? new UserInfoFormData())
        const companyData = ref(
            company?.value ? editFormData(company.value) : new CompanyInfoFormData()
        )
        const loadingCompanyUpdate = ref(false)
        const companyError = ref<string>()
        const updateCompanySubmitted = async (c: ICompanyInfoFormData) => {
            loadingCompanyUpdate.value = true
            const companyId = company?.value?.id
            if (!companyId) {
                return
            }
            const { error } = await updateCompany(companyId, c)
            loadingCompanyUpdate.value = false
            if (error.value) {
                companyError.value = error.value.message
                return
            }

            companyData.value = editFormData(c)
        }

        watch([user, company], () => {
            if (user?.value) {
                userData.value = user.value.editFormData()
            }
            if (company?.value) {
                companyData.value = editFormData(company.value)
                isAdmin.value = company.value.isAdmin()
            }
        })

        watch(selectedTab, (val) => {
            if (val == 3) {
                route.params.scrollTo = 'apps'
            } else {
                route.params.scrollTo = 'settings'
            }
        }
        )

        return {
            isAdmin,
            loadingUserUpdate,
            userError,
            updateUserSubmitted,
            userData,
            loadingCompanyUpdate,
            companyError,
            updateCompanySubmitted,
            companyData,
            loadingInitial,
            msAppLoaded,
            isMobile,
            selectedTab
        }
    },
})
</script>
