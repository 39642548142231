<template>
    <div class="app min-h-screen">
        <div id="nav">
            <TopNav></TopNav>
        </div>
        <div class="flex-auto">
            <router-view />
        </div>
        <div class="footer-container bottom-0 w-screen">
            <Footer></Footer>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue'
import TopNav from '@/components/layout/Header.vue'
import Footer from './components/layout/Footer.vue'

export default defineComponent({
    components: { TopNav, Footer },

    setup() {
        const isUITest = process.env.VUE_APP_IS_UI_TESTING == 1
        provide('isUITesting', isUITest)
        return
    },
})
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#nav a {
    font-weight: bold;
    @apply text-inactive;
}

#nav a.router-link-exact-active {
    @apply text-teal;
}

body {
    min-height: 100vh;
    background-color: #faf9f9;
    width: 100%;
}

html,
#app {
    position: relative;
    height: 100vh;
}

body,
#app,
.app {
    display: flex;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
}

.footer-container {
    width: 100%;
}

form [type='text'],
form [type='email'],
form [type='password'] {
    @apply rounded-md mt-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-teal focus:border-teal focus:z-10 sm:text-sm;
}

.onboarding-label {
    @apply block text-sm font-medium;
}

.onboarding-card {
    /* @apply border-2 border-teal p-4 rounded-lg bg-white; */
    @apply p-4 rounded-lg bg-gray-200;
}

.input-errors {
    @apply mt-2;
}

.error-msg {
    @apply text-sm font-medium text-red-500;
}

.action-button {
    @apply inline-flex justify-center py-2 px-4 border border-transparent font-medium rounded-md;
}

.primary-button {
    @apply action-button text-white bg-teal;
}

.legal-header {
    @apply text-2xl font-medium text-legalheader mt-2;
}

.legal-sub-header {
    @apply text-lg font-medium text-legalsubheader;
}

.legal-sub-small {
    @apply font-medium text-legalsubheader;
}

.legal-divider {
    @apply bg-legalsubheader h-px w-full mb-2;
}
</style>
