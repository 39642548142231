import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-5 my-10" }
const _hoisted_2 = { class: "team-detail pt-10 px-5" }
const _hoisted_3 = {
  key: 0,
  class: "mb-5 font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AudioFileUpload = _resolveComponent("AudioFileUpload")!
  const _component_AudioFileList = _resolveComponent("AudioFileList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(['primary-button', 'mr-3']),
      onClick: _cache[0] || (_cache[0] = () => _ctx.goTo('/dashboard/teams'))
    }, " Teams "),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = () => _ctx.goTo('/dashboard/projects')),
      class: _normalizeClass(['primary-button', 'mr-3'])
    }, " Projects "),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.projectDetails?.project?.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.projectDetails?.project.name), 1))
        : _createCommentVNode("", true),
      (_ctx.projectDetails?.project?.id)
        ? (_openBlock(), _createBlock(_component_AudioFileUpload, {
            key: 1,
            companyProjectId: _ctx.projectDetails.project.id,
            onOnUploadStatusChanged: _ctx.onUploadStatusChanged
          }, null, 8, ["companyProjectId", "onOnUploadStatusChanged"]))
        : _createCommentVNode("", true),
      (_ctx.projectDetails?.audios?.length)
        ? (_openBlock(), _createBlock(_component_AudioFileList, {
            key: 2,
            class: "my-10",
            audioList: _ctx.getAudioList()
          }, null, 8, ["audioList"]))
        : _createCommentVNode("", true)
    ])
  ]))
}