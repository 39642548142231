import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b7298f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scores-line bg-gray-200" }
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 0,
  class: "selected-score p-2 bg-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scores, (score, lineIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "score-line inline-block",
          style: _normalizeStyle(`width:${800 * score.ratio}px;${
                lineIndex == _ctx.activeIndex ? 'height:40px;border: 1px solid red;' : ''
            }`)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(score.scores), (label) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([
                    label,
                    'score h-2 block  shadow-md cursor-pointer',
                    lineIndex == _ctx.activeIndex ? 'pl-3 shadow-md' : 'justify-center',
                ]),
              style: _normalizeStyle(`width: 100%;${
                    lineIndex == _ctx.activeIndex ? 'height:13px' : ''
                };border-radius: 3px;' : ''}`),
              title: `${_ctx.newScoreLabelMap[label]} ${Math.ceil(score.scores[label] * 100)}% `
            }, null, 14, _hoisted_2))
          }), 256))
        ], 4))
      }), 256))
    ]),
    _createVNode(_Transition, { name: "nested" }, {
      default: _withCtx(() => [
        (_ctx.activeIndex)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.scores[_ctx.activeIndex].scores), (label) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([
                    label,
                    'inner selected-label p-1 mb-1 block  shadow-md cursor-pointer pl-3 shadow-md',
                ]),
                  style: _normalizeStyle(`width: ${
                    120 + _ctx.scores[_ctx.activeIndex].scores[label] * 100
                }px;text-transform: capitalize;`)
                }, _toDisplayString(_ctx.newScoreLabelMap[label]) + " " + _toDisplayString((_ctx.scores[_ctx.activeIndex].scores[label] * 100).toFixed(0)) + "% ", 7))
              }), 256))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}