export interface IUserInfoFormData {
    firstName: string
    lastName: string
    email: string
    password: string
    confirmPw: string
}

export class UserInfoFormData implements IUserInfoFormData {
    firstName = ''
    lastName = ''
    email = ''
    password = ''
    confirmPw = ''
    get isValid(): boolean {
        for (const p in [
            this.firstName,
            this.lastName,
            this.email,
            this.password,
            this.confirmPw,
        ]) {
            if (p.length == 0) {
                return false
            }
        }

        if (this.confirmPw != this.password) {
            return false
        }
        return true
    }
}

export interface ICompanyInfoFormData {
    name: string
    companySizeString: string
}

export class CompanyInfoFormData implements ICompanyInfoFormData {
    name = ''
    companySizeString = ''
}
