<template>
    <div :class="{ 'neon-shadow': shockwaveActivated && !hasTeams }">
        <ModalButton>
            <template v-slot:button>
                <div class="flex flex-wrap">
                    <div class="w-full text-center relative">
                        <button ref="btnRef" v-on:mouseenter="toggleTooltip()" v-on:mouseleave="toggleTooltip()"
                            class="outline-none focus:outline-none ease-linear transition-all duration-150 btn--shockwave"
                            type="button">
                            Create Team
                        </button>
                        <div ref="tooltipRef" v-bind:class="{ 'hidden': !tooltipShow, 'block': tooltipShow }"
                            class="absolute top-9 right-full ml-3 bg-blue-400 border-0 z-50 w-96 font-normal leading-normal text-sm max-w-md text-left no-underline break-words rounded-lg">
                            <div>
                                <div
                                    class="bg-blue-500 text-white font-semibold p-3 mb-0 border-b border-solid border-slate-100 uppercase rounded-t-lg">
                                    You are not yet setup!
                                </div>
                                <div class="text-white p-3">
                                    Create a Team Now to Monitor Channels from Your Connected Apps Seamlessly.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:modalTitle>Create a new team</template>
            <template v-slot:modalContent>
                <div class="mt-2">
                    <p class="text-sm text-gray-500">
                        Choose a name for your team, then connect channels from your workspace app!
                    </p>
                </div>
                <div class="mt-2">
                    <TeamCreationForm @team="teamCreated"></TeamCreationForm>
                </div>
            </template>
        </ModalButton>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TeamCreationForm from '@/components/teams/TeamCreationForm.vue'
import CompanyTeam from '@/models/CompanyTeam'
import ModalButton from '../global/ModalButton.vue'
import { useRouter } from 'vue-router'
import { ref } from 'vue'

export default defineComponent({
    components: {
        TeamCreationForm,
        ModalButton,
    },
    props: ['hasTeams'],
    data() {
        return {
            tooltipShow: false,
            shockwaveActivated: true
        }
    },
    setup() {
        const router = useRouter()
        const teamCreated = (newTeam: CompanyTeam) => {
            const teamId = newTeam.id
            router.push(`/teams/${teamId}/details`)
        }


        return {
            teamCreated,
        }
    },
    methods: {
        toggleTooltip: function () {
            if (this.hasTeams === false) {
                this.tooltipShow = !this.tooltipShow;
            }
        },
        activateShockwave: function () {
            this.shockwaveActivated = true;
        },
        deactivateShockwave: function () {
            this.shockwaveActivated = false;
        }
    }
});
</script>

<style scoped>
.neon-shadow {
    border: none;
    transition: 0.3s;
    background-color: rgba(156, 161, 160, 0.3);
    animation: glow 1s infinite;
    transition: 0.5s;
}

span {
    display: block;
    width: 100%;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    padding-top: 15%;
    padding-right: 2.5%;
    margin-right: 0px;
    font-size: 1.2rem;
    transition: 0.3s;
    opacity: 0;
}

span:hover {
    transition: 0.3s;
    opacity: 1;
    font-weight: 700;
}

.neon-shadow:hover {
    background-color: #c3bacc;
    transition: 0.5s;
}

@keyframes glow {
    0% {
        box-shadow: 5px 5px 20px rgb(255, 165, 0), -5px -5px 20px rgb(255, 165, 0);
        /* Standard Orange */
    }

    50% {
        box-shadow: 15px 5px 20px rgb(77, 255, 246), -5px -5px 20px rgb(77, 213, 255);
        /* Lighter Orange */
    }

    100% {
        box-shadow: 5px 5px 20px rgb(255, 165, 0), -5px -5px 20px rgb(255, 165, 0);
        /* Standard Orange */
    }

}
</style>
