<template>
    <div class="flex flex-col items-center justify-center h-full">
        <div class="flex-grow"></div>
        <p class="my-4 text">{{ helperText }}</p>
        <LoadingButton :loading="loading" :disabled="!teamsConnected" @click="routeToDashboard">{{
            buttonText
        }}</LoadingButton>
        <div class="error-msg" v-if="responseError">{{ responseError.message }}</div>
        <div class="flex-grow"></div>
    </div>
</template>

<script lang="ts">
import { useAuth } from '@/services/auth'
import { saveTenantId } from '@/services/companies'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LoadingButton from '@/components/global/LoadingButton.vue'
import { Company } from '@/models/Company'

export default defineComponent({
    setup() {
        const { company, getCompanyId } = useAuth()
        const teamsConnected = computed(() => {
            return company?.value?.teamsConnected == true
        })
        const helperText = computed(() => {
            return teamsConnected.value ? 'Teams Connected!' : 'Finishing Teams Connection'
        })
        const buttonText = computed(() => {
            return teamsConnected.value ? 'Go to Dashboard' : 'Connecting'
        })
        const loading = ref(false)
        const responseError = ref<any>()
        const saveAdminConsent = async (msTeamsTenantId: string) => {
            const companyId = getCompanyId()
            if (!companyId) {
                console.error('no company found')
                return
            }
            loading.value = true
            const { error } = await saveTenantId(companyId, msTeamsTenantId)
            loading.value = false
            if (error.value) {
                responseError.value = error.value
            } else {
                if (company && company.value) {
                    company.value.teamsConnected = true
                }
            }
        }

        const router = useRouter()
        const routeToDashboard = () => {
            router.push('/dashboard/teams')
        }
        onMounted(async () => {
            const route = useRoute()
            const adminConsent = route.query.admin_consent as string
            const state: string = route.query.state as string
            const tenantId: string = route.query.tenant as string
            if (
                (localStorage.getItem('connectionState') == state || state == 'teams-app') &&
                tenantId &&
                adminConsent == 'True'
            ) {
                await saveAdminConsent(tenantId)
            }
        })

        return {
            teamsConnected,
            loading,
            helperText,
            buttonText,
            responseError,
            routeToDashboard,
        }
    },
    components: { LoadingButton },
})
</script>
