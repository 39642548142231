<template>
    <div>
        <ModalButton>
            <template v-slot:button>Invite new user</template>
            <template v-slot:modalTitle>Invite a new user to help manage your team</template>
            <template v-slot:modalContent>
                <p class="text-sm text-gray-500 mb-2">
                    Managers can view analytics, but cannot create or delete new teams.
                </p>
                <div v-if="hideForm">
                    <p class="text-lg text-teal">Invite Sent!</p>
                </div>
                <div>
                    <CompanyUserInviteForm
                        v-if="!hideForm"
                        @invited="inviteSent"
                    ></CompanyUserInviteForm>
                </div>
            </template>
        </ModalButton>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import ModalButton from '../global/ModalButton.vue'
import CompanyUserInviteForm from './CompanyUserInviteForm.vue'

export default defineComponent({
    components: { ModalButton, CompanyUserInviteForm },
    setup() {
        const hideForm = ref(false)

        const inviteSent = () => {
            hideForm.value = true
        }

        return { hideForm, inviteSent }
    },
})
</script>
