import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 border-2 border-teal rounded-lg bg-white" }
const _hoisted_2 = { class: "grid grid-cols-5" }
const _hoisted_3 = { class: "col-span-4 flex items-center" }
const _hoisted_4 = { class: "text-lg" }
const _hoisted_5 = { class: "col-span-1 p-2 flex flex-col items-end" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-span-5" }
const _hoisted_8 = { class: "text-sm" }
const _hoisted_9 = { class: "col-span-3 col-start-2 flex justify-center" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "rounded-md text-sm bg-teal text-white p-2 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.recommendation.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          src: _ctx.recommendation.imageUrl,
          alt: "",
          class: "w-100 max-h-8"
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.recommendation.description), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", {
          href: _ctx.$props.recommendation.url,
          target: "_blank"
        }, [
          _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.recommendation.cta), 1)
        ], 8, _hoisted_10)
      ])
    ])
  ]))
}