<template>
    <div class="company-user-invite-form">
        <form @submit.prevent="inviteUser">
            <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                    <label for="first-name" class="onboarding-label">First Name</label>
                    <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autocomplete="given-name"
                        class="text-input"
                        v-model="v$.firstName.$model"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.firstName.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                    <label for="last-name" class="onboarding-label">Last Name</label>
                    <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autocomplete="family-name"
                        class="text-input"
                        v-model="v$.lastName.$model"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.lastName.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                </div>
                <div class="col-span-6 sm:col-span-4">
                    <label for="email-address" class="onboarding-label">Email</label>
                    <input
                        type="email"
                        name="email-address"
                        id="email-address"
                        autocomplete="email"
                        class="text-input"
                        v-model="v$.email.$model"
                    />
                    <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div class="error-msg" v-if="responseError">{{ responseError }}</div>
                </div>
            </div>
            <div class="flex flex-row mt-4 items-baseline">
                <div class="flex-grow"></div>
                <LoadingButton :disabled="v$.$invalid" :loading="loading"
                    >Invite User</LoadingButton
                >
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { useAuth } from '@/services/auth'
import { inviteNewUser } from '@/services/companies'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { defineComponent, reactive, ref } from 'vue'
import LoadingButton from '../global/LoadingButton.vue'

export default defineComponent({
    components: { LoadingButton },
    emits: ['invited'],
    setup(_, { emit }) {
        const loading = ref(false)
        const responseError = ref<any>()
        const state = reactive({
            firstName: '',
            lastName: '',
            email: '',
        })
        const rules = {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
                email,
            },
        }
        const v$ = useVuelidate(rules, state)

        const inviteUser = async () => {
            const { getCompanyId } = useAuth()
            const companyId = getCompanyId()
            if (!companyId) {
                return
            }
            const { error } = await inviteNewUser(
                companyId,
                state.firstName,
                state.lastName,
                state.email
            )

            if (error.value) {
                responseError.value = error.value
            } else {
                state.email = ''
                state.firstName = ''
                state.lastName = ''
                emit('invited')
            }
        }

        return { v$, loading, responseError, inviteUser }
    },
    methods: {
        async userInviteSubmitted() {
            // TODO: Implement this
        },
    },
})
</script>
