import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { ref, watch } from 'vue'
import { useAuth } from './auth'

export const callApiWithAuth = (endpoint: string, baseURL?: string) => {
    const { authToken } = useAuth()

    console.log('authToken==>', authToken)

    return callApi(endpoint, authToken?.value ? authToken.value : undefined, baseURL)
}

export const projectsCallApiWithAuth = (endpoint: string) => {
    return callApiWithAuth(endpoint,
        process.env.VUE_APP_TRY_API_BASE_URL);
}


export const callApi = (endpoint: string, access_token?: string|null, baseUrl?: string) => {
    const api = axios.create({
        baseURL: baseUrl || process.env.VUE_APP_BACKEND,
        headers: {
            Authorization: access_token ? `Bearer ${access_token}` : undefined,
        },
    })
    const apiResponse = ref()
    const error = ref()

    const post = async (payload?: Record<string, any>) => {
        error.value = undefined

        return api
            .post(endpoint, payload)
            .then((res) => (apiResponse.value = res.data))
            .catch((reason: AxiosError) => {
                error.value = reason.response?.data
            })
        // .finally(() => (loading.value = false))
    }

    const deleteCall = async () => {
        error.value = undefined

        return api
            .delete(endpoint)
            .then((res) => (apiResponse.value = res.data))
            .catch((reason: AxiosError) => {
                error.value = reason.response?.data
            })
    }

    const get = (query?: Record<string, any>, config?: AxiosRequestConfig) => {
        // loading.value = true
        error.value = undefined

        let queryString = ''

        if (query) {
            queryString =
                '?' +
                Object.entries(query)
                    .map(
                        ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                    )
                    .join('&')
        }

        return api
            .get(endpoint + queryString, config)
            .then((res) => {
                apiResponse.value = res.data
            })
            .catch((reason: AxiosError) => {
                error.value = reason?.response?.data || reason?.message;
            })
        // .finally(() => (loading.value = false))
    }

    // Add error details/message extractor?
    watch([error], () => {
        // If 401, send back to login screen
        if (
            error.value.response &&
            error.value.response.status &&
            error.value.response.status === 401
        ) {
            // How to send user to login?
        }
    })

    return {
        // loading,
        apiResponse,
        error,
        get,
        post,
        deleteCall,
    }
}
