<template>
    <div class="h-full flex content-center">
        <p class="text-2xl">404</p>
        <p class="text-lg mt-4">Page not found!</p>
        <button class="primary-button">
            <router-link to="/dashboard/teams">Take me somewhere better!</router-link>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>
