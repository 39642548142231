<template>
    <div class="p-4 border-2 border-teal rounded-lg bg-white">
        <div class="grid grid-cols-5">
            <!-- Title and description are large on big displays, need to change ordering on smaller displays -->
            <div class="col-span-4 flex items-center">
                <h2 class="text-lg">{{ recommendation.title }}</h2>
            </div>
            <div class="col-span-1 p-2 flex flex-col items-end">
                <img :src="recommendation.imageUrl" alt="" class="w-100 max-h-8" />
            </div>
            <div class="col-span-5">
                <p class="text-sm">{{ recommendation.description }}</p>
            </div>
            <div class="col-span-3 col-start-2 flex justify-center">
                <a :href="$props.recommendation.url" target="_blank">
                    <button class="rounded-md text-sm bg-teal text-white p-2 mt-4">
                        {{ recommendation.cta }}
                    </button>
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Recommendation from '@/models/Recommendation'

export default defineComponent({
    props: {
        recommendation: {
            type: Object as PropType<Recommendation>,
            required: true,
        },
    },
})
</script>
