<template>
    <div class="recommendations p-4">
        <!-- commented out since it depends on active subscription -->
        <div>
            <!--v-if="hasSubscription"-->
            <RecommendationList />
        </div>
        <!--<div v-else class="flex flex-col items-center justify-center h-full">
            <p class="text-lg mt-8">
                This is where Subscribers can see special offers from partnered services.
            </p>
            <p class="text-lg mb-8">Support your team's growth</p>
            <SubscriptionModal v-if="isAdmin"></SubscriptionModal>
        </div>-->
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import RecommendationList from '@/components/recommendations/RecommendationList.vue'
import { useAuth } from '@/services/auth'
//import SubscriptionModal from '@/components/global/SubscriptionModal.vue'

export default defineComponent({
    components: {
        RecommendationList,
        //SubscriptionModal,
    },
    setup() {
        const { subscription, company } = useAuth()
        const isAdmin = ref(company?.value?.isAdmin() == true)
        const hasSubscription = ref(subscription?.value != null && subscription.value.isActive)

        watch([subscription, company], () => {
            hasSubscription.value = subscription?.value != null && subscription.value.isActive
            isAdmin.value = company?.value?.isAdmin() == true
        })
        return { hasSubscription, isAdmin }
    },
})
</script>
