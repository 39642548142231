import User from '@/models/User'
import { ICompany, CompanyUserType } from '@/models/Company'
import { callApiWithAuth } from './api'
import { ICompanyInfoFormData } from '@/models/FormData'
import { useAuth } from './auth'
import { ref } from 'vue'
import Project from '@/models/Project'

export interface CompanyUserListResponse {
    users: CompanyUserListEntry[]
}

export interface CompanyUserListEntry {
    userType: CompanyUserType
    user: User
}

export const updateCompany = async (companyId: number, data: ICompanyInfoFormData) => {
    const { error, apiResponse, post } = callApiWithAuth(`companies/${companyId}`)

    await post(data)

    const company: ICompany = apiResponse.value.company
    if (company && !error.value) {
        const { company: com } = useAuth()
        if (com && com.value?.companySizeString && com.value.name) {
            com.value.companySizeString = company.companySizeString
            com.value.name = company.name
        }
    }
    return { company, error }
}

export const connectSlackToken = async (companyId: number, slackToken: string) => {
    const { error, apiResponse, post } = callApiWithAuth(`companies/${companyId}/connectSlack`)
    await post({ code: slackToken })
    return { error }
}

export const saveTenantId = async (companyId: number, msTeamsTenantId: string) => {
    const { error, apiResponse, post } = callApiWithAuth(`companies/${companyId}/connectTeams`)

    await post({ ms_teams_tenant_id: msTeamsTenantId })
    const company: ICompany = apiResponse.value?.company
    if (company && !error.value) {
        const { company: com } = useAuth()
        if (com && com.value?.teamsConnected) {
            com.value.teamsConnected = true
        }
    }
    return { company, error }
}

export const saveDiscordGuildId = async (companyId: number, discordGuildId: string) => {
    const { error, apiResponse, post } = callApiWithAuth(`companies/${companyId}/connectDiscord`)
    await post({ discord_guild_id: discordGuildId })
    const company: ICompany = apiResponse.value?.company
    if (company && !error.value) {
        const { company: com } = useAuth()
        if (com && com.value?.discordConnected) {
            com.value.discordConnected = true
        }
    }
    return { company, error }
}

export const getCompanyUsers = async (companyId: number) => {
    const { apiResponse, error, get } = callApiWithAuth(`companies/${companyId}/users`)

    await get()

    const userJson: any[] = apiResponse.value.users || []
    const userArray: CompanyUserListEntry[] = userJson.map((u) => {
        return { userType: u.userType, user: new User(u.user) }
    })
    const users = ref(userArray)

    return { users, error }
}

export const inviteNewUser = async (
    companyId: number,
    first: string,
    last: string,
    email: string
) => {
    const { error, post } = callApiWithAuth(`companies/${companyId}/users`)
    const payload = { companyId: companyId, firstName: first, lastName: last, email: email }
    await post(payload)
    return { error }
}

export const deleteUserFromCompany = async (companyId: number, userId: number) => {
    const { error, deleteCall } = callApiWithAuth(`companies/${companyId}/users/${userId}`)
    await deleteCall()
    return { error }
}

export const disconnectTeams = async (companyId: number) => {
    const { error, apiResponse, post } = callApiWithAuth(`companies/${companyId}/disconnectTeams`)
    await post()
    const company: ICompany = apiResponse.value?.company
    if (company && !error.value) {
        const { company: com } = useAuth()
        if (com && com.value?.teamsConnected) {
            com.value.teamsConnected = false
        }
    }
    return { updatedCompany: company, error }
}


export const disconnectDiscord = async (companyId: number) => {
    const { error, apiResponse, post } = callApiWithAuth(`companies/${companyId}/disconnectDiscord`)
    await post()
    const company: ICompany = apiResponse.value?.company
    if (company && !error.value) {
        const { company: com } = useAuth()
        if (com && com.value?.discordConnected) {
            com.value.discordConnected = false
        }
    }
    return { updatedCompany: company, error }
}

export const disconnectSlack = async (companyId: number) => {
    const { error, apiResponse, post } = callApiWithAuth(`companies/${companyId}/disconnectSlack`)

    await post()
    const company: ICompany = apiResponse.value?.company

    if (company && !error.value) {
        const { company: com } = useAuth()
        if (com && com.value?.slackConnected) {
            com.value.slackConnected = false
        }
    }
    return { updatedCompany: company, error }

}

export const getProjects = async (companyId: number): Promise<{ error: any, projectsList: any }> => {
    const { apiResponse, error, get } = callApiWithAuth(`companies/${companyId}/projects`)
    await get();
    const projectsList: Project[] = apiResponse.value
    return { error, projectsList }
}
