<template>
    <div class="">
        <button :class="buttonClass || 'primary-button'" @click="openModal" :disabled="isOpen">
            <slot name="button"></slot>
        </button>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="closeModal">
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="min-h-screen px-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay
                            class="
                                fixed
                                inset-0
                                bg-gray-300 bg-opacity-40
                                backdrop-filter backdrop-blur
                            "
                        ></DialogOverlay>
                    </TransitionChild>

                    <span class="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <div
                            class="
                                inline-block
                                w-full
                                md:max-w-2xl
                                p-6
                                my-8
                                overflow-hidden
                                text-left
                                align-middle
                                transition-all
                                transform
                                bg-white
                                shadow-xl
                                rounded-2xl
                            "
                        >
                            <DialogTitle
                                as="h3"
                                class="text-lg font-medium leading-6 text-gray-900 mb-2"
                                ><slot name="modalTitle"></slot
                            ></DialogTitle>
                            <slot name="modalContent"></slot>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script lang="ts">
import {
    TransitionRoot,
    Dialog,
    TransitionChild,
    DialogOverlay,
    DialogTitle,
} from '@headlessui/vue'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    components: {
        TransitionRoot,
        Dialog,
        TransitionChild,
        DialogOverlay,
        DialogTitle,
    },
    props: {
        buttonClass: {
            type: String,
            required: false,
        },
    },
    setup() {
        const isOpen = ref(false)

        return {
            isOpen,
            closeModal() {
                isOpen.value = false
            },
            openModal() {
                isOpen.value = true
            },
        }
    },
})
</script>
