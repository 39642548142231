<template>
    <ModalContainer :openImmediately="true" @closed="modalClosed">
        <template v-slot:modalTitle>{{ titleText }}</template>
        <template v-slot:modalContent>
            <p class="text-md text-red-600 mb-2">This process is irreversible!</p>

            <LoadingButton
                @click="deleteClicked"
                :disabled="state.loading"
                :loading="state.loading"
                class="bg-red-600"
                >{{ buttonText }}</LoadingButton
            >
            <div class="error-msg" v-if="state.responseError">{{ state.responseError }}</div>
        </template>
    </ModalContainer>
</template>

<script lang="ts">
import CompanyTeam from '@/models/CompanyTeam'
import CompanyTeamChannel from '@/models/CompanyTeamChannel'
import { deleteTeamOrChannel } from '@/services/teams'
import { computed, defineComponent, PropType, reactive } from 'vue'
import ModalContainer from '../global/ModalContainer.vue'
import LoadingButton from '../global/LoadingButton.vue'

export default defineComponent({
    components: { ModalContainer, LoadingButton },
    props: {
        team: {
            type: Object as PropType<CompanyTeam>,
            required: true,
        },
        channel: {
            type: Object as PropType<CompanyTeamChannel>,
            required: false,
        },
    },
    emits: ['completed'],
    setup(props, { emit }) {
        const state: { loading: boolean; responseError?: string } = reactive({
            loading: false,
            responseError: undefined,
        })

        const titleText = computed(() => {
            let name: string
            if (props.channel) {
                name = props.channel.name + ' channel'
            } else {
                name = props.team.name + ' team'
            }
            return 'Are you sure you want to delete the ' + name + '?'
        })

        const buttonText = computed(() => {
            let deleteText = props.channel != null ? 'Channel' : 'Team'
            return 'Delete ' + deleteText
        })

        const deleteClicked = async () => {
            const teamId = props.team.id
            const channelId = props.channel?.id

            state.loading = true
            const { error } = await deleteTeamOrChannel(teamId, channelId);
            state.loading = false
            if (error.value) {
                state.responseError = error.value.message
            } else {
                emit('completed', true)
            }
        }

        const modalClosed = () => {
            emit('completed', false)
        }

        return { state, titleText, buttonText, deleteClicked, modalClosed }
    },
})
</script>
