<template>
    <div v-if="audioDetails">
        <av-waveform
            :canv-width="800"
            ref="player"
            class="player"
            v-if="audioDetails?.fileKey"
            :line-width="2"
            line-color="lime"
            v-on:click="updateCursor"
            :audio-src="`https://audio-user-uploads.s3.us-east-2.amazonaws.com/${audioDetails?.fileKey}`"
        ></av-waveform>
        <AudioScoreLine
            v-if="audioDetails?.scoresJson"
            :activeIndex="activeIndex"
            :scores="timestampedScores"
        />
    </div>
</template>
<script lang="ts">
declare const window: any
import { defineComponent, onMounted, PropType, Ref, ref } from 'vue'
import AudioAnalysisResult from '@/models/AudioAnalysisResult'
import AudioScoreLine from './AudioScoreLine.vue'

export default defineComponent({
    components: { AudioScoreLine },
    props: {
        audioDetails: {
            type: Object as PropType<AudioAnalysisResult>,
            required: true,
        },
    },
    data: function () {
        return {
            activeIndex: 0,
            timestampedScores: [] as {
                time: number
                seq_number_in_timeframe: number
                ratio: number
                frameTime: number
                scores: Object
            }[],
        }
    },
    mounted: function () {
        if (!this.audioDetails.scoresJson) return
        const player: { audio: HTMLAudioElement } = this.$refs?.player as {
            audio: HTMLAudioElement
        }
        this.timestampedScores = JSON.parse(this.audioDetails.scoresJson)
        let playerInitialized = false
        setInterval(() => {
            if (!this.$refs.player) return
            if (player.audio.duration) {
                if (!playerInitialized) {
                    this.timestampedScores = this.ratioAwareTimestampedScores(
                        this.timestampedScores.slice(0),
                        player.audio.duration
                    )
                    if (!window.moodconnect_state) {
                        window.moodconnect_state = {}
                    }
                    window.moodconnect_state.audio_visualizer = {
                        timestampedScores: this.timestampedScores, player
                    }
                }
                playerInitialized = true
            }
            if (!playerInitialized) return
            const computedCurrentTimeFrameEndIndex =
                this.timestampedScores.findIndex(
                    (transcript) => transcript.time > player.audio.currentTime
                ) - 1

            // dealing with audio-end time frame
            if (computedCurrentTimeFrameEndIndex == -2) {
                // Find timeframe total slots
                const currentTimeFrameTotalSlots =
                    this.timestampedScores[this.timestampedScores.length - 1]
                        .seq_number_in_timeframe

                const currentTimeFrameLength =
                    player.audio.duration -
                    this.timestampedScores[this.timestampedScores.length - 1].time

                const singleSentenceLength = currentTimeFrameLength / currentTimeFrameTotalSlots
                const playedLength =
                    player.audio.currentTime -
                    this.timestampedScores[this.timestampedScores.length - 1].time
                const currentSlotIndex = Math.ceil(playedLength / singleSentenceLength) - 1
                this.activeIndex =
                    this.timestampedScores.length + currentSlotIndex - currentTimeFrameTotalSlots
                return
                // dealing with audio-beginning time frame
            } else if (computedCurrentTimeFrameEndIndex <= 0) {
                this.activeIndex = 0
                return
            }
            // Find timeframe total slots
            const currentTimeFrameTotalSlots =
                this.timestampedScores[computedCurrentTimeFrameEndIndex].seq_number_in_timeframe
            const nextTimeFrameIndex =
                computedCurrentTimeFrameEndIndex + 1 < this.timestampedScores.length
                    ? computedCurrentTimeFrameEndIndex + 1
                    : computedCurrentTimeFrameEndIndex
            const previousTimeFrameIndex =
                computedCurrentTimeFrameEndIndex - currentTimeFrameTotalSlots
            const currentTimeFrameLength =
                this.timestampedScores[nextTimeFrameIndex].time -
                this.timestampedScores[computedCurrentTimeFrameEndIndex].time

            const singleSentenceLength = currentTimeFrameLength / currentTimeFrameTotalSlots
            const playedLength =
                player.audio.currentTime -
                this.timestampedScores[computedCurrentTimeFrameEndIndex].time
            const currentSlotIndex = Math.floor(playedLength / singleSentenceLength)
            this.activeIndex = previousTimeFrameIndex + currentSlotIndex + 1
        }, 1000)
    },
    methods: {
        updateCursor() {},
        ratioAwareTimestampedScores(
            timestampedScoresTmp: {
                time: number
                seq_number_in_timeframe: number
                ratio: number
                frameTime: number
                scores: Object
            }[],
            totalDuration: number
        ) {
            const item1StartTime = timestampedScoresTmp[0].time
            timestampedScoresTmp.reverse()
            let currentTimeFrameTotalSlots = timestampedScoresTmp[0].seq_number_in_timeframe
            let previousTime: number = totalDuration
            timestampedScoresTmp.forEach((score, index) => {
                // audio end frame
                if (index == 0) {
                    const currentTimeFrameLength = totalDuration - timestampedScoresTmp[0].time
                    const singleSentenceLength = currentTimeFrameLength / currentTimeFrameTotalSlots

                    score.ratio = singleSentenceLength / totalDuration
                    // TODO: use frameTime to avoid recomputing this value all the time in the hook mounted!
                    // score.frameTime =

                    // audio beginning frame
                } else if (index == timestampedScoresTmp.length - 1) {
                    const currentTimeFrameLength =
                        previousTime - timestampedScoresTmp[timestampedScoresTmp.length - 1].time
                    const singleSentenceLength = currentTimeFrameLength / currentTimeFrameTotalSlots
                    score.ratio = singleSentenceLength / totalDuration
                    // TODO: use frameTime to avoid recomputing this value all the time in the hook mounted!
                    // score.frameTime =
                    // anything in-between
                } else {
                    const currentTimeFrameLength = previousTime - timestampedScoresTmp[index].time
                    const singleSentenceLength = currentTimeFrameLength / currentTimeFrameTotalSlots
                    score.ratio = singleSentenceLength / totalDuration
                    // TODO: use frameTime to avoid recomputing this value all the time in the hook mounted!
                    // score.frameTime =
                }
                if (
                    score.seq_number_in_timeframe == 1 &&
                    index != timestampedScoresTmp.length - 1
                ) {
                    previousTime = score.time
                    currentTimeFrameTotalSlots =
                        timestampedScoresTmp[index + 1].seq_number_in_timeframe
                }
            })
            // TODO: ratios should add-up to 1, currently giving 0.99 which may be an indication of a bug.

            // Add initial pad score
            timestampedScoresTmp.push({
                time: 0,
                ratio: item1StartTime / totalDuration,
                seq_number_in_timeframe: 1,
                frameTime: 0,
                scores: {},
            })
            timestampedScoresTmp.reverse()

            return timestampedScoresTmp
        },
    },
    setup() {
        const initiated = ref(false)

        return {
            initiated,
        }
    },
})
</script>

<style scoped>
.player {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 800px;
}
</style>
