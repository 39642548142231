import {
    ISubscriptionPlan,
    ISubscriptionPrice,
    SubscriptionPlan,
    UserSubscriptionLevel,
} from '@/models/UserSubscription'
import { reactive, toRefs } from 'vue-demi'
import { callApi } from './api'

interface InfoState {
    plans: ISubscriptionPlan[]
    initialLoadComplete: boolean
}

const infoState = reactive<InfoState>({
    plans: [],
    initialLoadComplete: false,
})

export const useInfo = () => {
    const setPlans = (newPlans: ISubscriptionPlan[]): void => {
        infoState.plans = newPlans
        infoState.initialLoadComplete = true
    }

    return { setPlans, ...toRefs(infoState) }
}

interface SubscriptionPriceResponse {
    [UserSubscriptionLevel.STARTER]: ISubscriptionPrice[]
    [UserSubscriptionLevel.PREMIUM]: ISubscriptionPrice[]
}

export const getSubscriptionPrices = async () => {
    const { apiResponse, error, get } = callApi('info/subscriptions')

    await get()

    const responsePlans: SubscriptionPriceResponse = apiResponse.value?.plans
    if (!responsePlans) return;
    const plans: SubscriptionPlan[] = []
    const starter = SubscriptionPlan.create(
        UserSubscriptionLevel.STARTER,
        responsePlans[UserSubscriptionLevel.STARTER]
    )
    if (starter) {
        plans.push(starter)
    }
    const premium = SubscriptionPlan.create(
        UserSubscriptionLevel.PREMIUM,
        responsePlans[UserSubscriptionLevel.PREMIUM]
    )
    if (premium) {
        plans.push(premium)
    }

    const { setPlans } = useInfo()
    setPlans(plans)
    return { error }
}

// Load prices when first created
if (!infoState.initialLoadComplete) {
    getSubscriptionPrices()
}
