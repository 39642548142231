import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app min-h-screen" }
const _hoisted_2 = { id: "nav" }
const _hoisted_3 = { class: "flex-auto" }
const _hoisted_4 = { class: "footer-container bottom-0 w-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNav = _resolveComponent("TopNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TopNav)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Footer)
    ])
  ]))
}