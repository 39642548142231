import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full"
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "truncate" }
const _hoisted_4 = { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }
const _hoisted_5 = {
  key: 0,
  class: "absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
}
const _hoisted_6 = { class: "mt-4 w-72" }
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = {
  key: 0,
  class: "ml-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!

  return (_ctx.channels.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_Listbox, {
            class: "w-72",
            modelValue: _ctx.selectedChannel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedChannel) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ListboxButton, { class: "relative w-full py-2 pl-3 pr-10 text-left rounded-md cursor-default sm:text-sm border-gray-500 border-2" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selectedChannel.title), 1),
                    _createElementVNode("span", _hoisted_4, [
                      _createVNode(_component_SelectorIcon, {
                        class: "w-5 h-5 text-gray-400",
                        "aria-hidden": "true"
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_Transition, {
                  "leave-active-class": "transition duration-100 ease-in",
                  "leave-from-class": "opacity-100",
                  "leave-to-class": "opacity=0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ListboxOptions, { class: "absolute w-full py-1 mt-1 z-20 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channels, (channel) => {
                          return (_openBlock(), _createBlock(_component_ListboxOption, {
                            key: channel.id,
                            value: channel,
                            as: "template"
                          }, {
                            default: _withCtx(({ active, selected }) => [
                              _createElementVNode("li", {
                                class: _normalizeClass([
                                        active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                                        'cursor-default select-none relative py-2 pl-10 pr-4',
                                    ])
                              }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass([
                                            selected ? 'font-medium' : 'font-normal',
                                            'block truncate',
                                        ])
                                }, _toDisplayString(channel.title), 3),
                                selected
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                      _createVNode(_component_CheckIcon, {
                                        class: "w-5 h-5",
                                        "aria-hidden": "true"
                                      })
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 2)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_LoadingButton, {
                class: "w-1/2 mr-4",
                onClick: _ctx.addChannel,
                loading: _ctx.loading,
                disabled: !_ctx.selectedChannel
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Add Channel ")
                ]),
                _: 1
              }, 8, ["onClick", "loading", "disabled"]),
              (_ctx.addChannelStatus)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.addChannelStatus), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}