export enum UserSubscriptionLevel {
    UNKNOWN = 'UNKNOWN',
    STARTER = 'STARTER',
    PREMIUM = 'PREMIUM'
}

export interface SubscriptionCopy {
    title: string
    benefits: string[]
}

const marketingCopy = (level: UserSubscriptionLevel): SubscriptionCopy => {
    switch (level) {
        case UserSubscriptionLevel.STARTER:
            return {
                title: 'Invest in your team!',
                benefits: [
                    'Unlock unlimited sentiment analysis',
                    'Unlimited audio analysis',
                    'Unlimited reporting',
                ],
            }
        case UserSubscriptionLevel.PREMIUM:
            return {
                title: 'Premium',
                benefits: [
                    'Everything from Starter',
                    '4 Surveys per month',
                    'Access to exclusive wellness content',
                ],
            }
        default:
            return { title: 'None', benefits: [] }
    }
}

export interface IUserSubscription {
    subscriptionLevel: UserSubscriptionLevel
    expiresAt?: Date
    durationInMonths?: number
    stripePlanId?: string
    isActive: boolean
}

export class UserSubscription implements IUserSubscription {
    subscriptionLevel: UserSubscriptionLevel
    expiresAt?: Date
    durationInMonths?: number
    stripePlanId?: string

    get isActive() {
        if (this.subscriptionLevel == UserSubscriptionLevel.UNKNOWN) {
            return false
        }
        if (this.expiresAt && this.expiresAt > new Date()) {
            return true
        }
        return false
    }

    get titleString() {
        let title = marketingCopy(this.subscriptionLevel).title
        const duration = this.durationInMonths
        if (duration) {
            if (duration == 1) {
                title = title + ' Monthly'
            } else if (duration == 12) {
                title = title + ' Annual'
            }
        }

        return title
    }
    constructor(data: any) {
        this.subscriptionLevel = data.subscriptionLevel
        this.expiresAt = new Date(data.expiresAt)
        this.durationInMonths = data.durationInMonths
        this.stripePlanId = data.stripePlanId
    }
}

export interface ISubscriptionPlan {
    subscriptionLevel: UserSubscriptionLevel
    prices: ISubscriptionPrice[]
    monthlyPrice: ISubscriptionPrice
    annualPrice: ISubscriptionPrice
    marketingCopy: SubscriptionCopy
}

export class SubscriptionPlan implements ISubscriptionPlan {
    subscriptionLevel: UserSubscriptionLevel
    get prices() {
        return [this.monthlyPrice, this.annualPrice]
    }

    monthlyPrice: ISubscriptionPrice
    annualPrice: ISubscriptionPrice
    marketingCopy: SubscriptionCopy

    private constructor(
        level: UserSubscriptionLevel,
        monthlyPrice: ISubscriptionPrice,
        annualPrice: ISubscriptionPrice
    ) {
        this.subscriptionLevel = level
        this.monthlyPrice = monthlyPrice
        this.annualPrice = annualPrice
        this.marketingCopy = marketingCopy(level)
    }

    static create(level: UserSubscriptionLevel, prices: ISubscriptionPrice[]) {
        const monthly = prices.find((p) => p.duration == 1)
        const annual = prices.find((p) => p.duration == 12)
        if (!monthly || !annual) {
            return null
        }
        return new SubscriptionPlan(level, monthly, annual)
    }
}

export interface ISubscriptionPrice {
    id: string
    seats: number
    duration: number
}
