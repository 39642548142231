import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "w-72" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "truncate" }
const _hoisted_4 = { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }
const _hoisted_5 = {
  key: 0,
  class: "absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Listbox, {
      modelValue: _ctx.selectedBracket,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedBracket) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ListboxButton, { class: "relative w-full py-2 pl-3 pr-10 text-left rounded-md cursor-default sm:text-sm border-gray-500 border-2" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selectedBracket.title), 1),
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_SelectorIcon, {
                  class: "w-5 h-5 text-gray-400",
                  "aria-hidden": "true"
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_Transition, {
            "leave-active-class": "transition duration-100 ease-in",
            "leave-from-class": "opacity-100",
            "leave-to-class": "opacity=0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ListboxOptions, { class: "absolute w-full py-1 mt-1 z-20 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rangeBrackets, (range) => {
                    return (_openBlock(), _createBlock(_component_ListboxOption, {
                      key: range.id,
                      value: range,
                      as: "template"
                    }, {
                      default: _withCtx(({ active, selected }) => [
                        _createElementVNode("li", {
                          class: _normalizeClass([
                                    active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-10 pr-4',
                                ])
                        }, [
                          _createElementVNode("span", {
                            class: _normalizeClass([
                                        selected ? 'font-medium' : 'font-normal',
                                        'block truncate',
                                    ])
                          }, _toDisplayString(range.title), 3),
                          selected
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                _createVNode(_component_CheckIcon, {
                                  class: "w-5 h-5",
                                  "aria-hidden": "true"
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ], 2)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}