<template>
    <div class="error-msg">{{ error }}</div>
    <div class="w-100 space-y-2">
        <div class="flex flex-col md:grid md:grid-cols-9" v-for="(cu, index) in users" :key="cu.user.id">
            <div class="col-span-2">{{ cu.user.fullName }}</div>
            <div class="col-span-4">{{ cu.user.email }}</div>
            <div class="col-span-2">{{ companyUserTypeString(cu.userType) }}</div>
            <div v-if="isAdmin && cu.userType != 1" class="col-span-1 flex flex-row">
                <div class="flex-grow"></div>
                <Menu as="div" class="relative inline-block text-left">
                    <div>
                        <MenuButton
                            class="
                                inline-flex
                                justify-center
                                w-full
                                p-2
                                text-sm
                                font-medium
                                text-black
                                bg-teal
                                rounded-md
                                bg-opacity-0
                                hover:bg-opacity-30
                            "
                        >
                            <DotsVerticalIcon class="w-5 h-5 text-black" aria-hidden="true" />
                        </MenuButton>
                    </div>

                    <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-from-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in"
                        leave-from-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                    >
                        <MenuItems
                            class="
                                absolute
                                right-0
                                w-56
                                mt-2
                                origin-top-right
                                bg-white
                                divide-y divide-gray-100
                                rounded-md
                                shadow-lg
                                focus:outline-none
                                z-10
                            "
                        >
                            <div class="px-1 py-1">
                                <MenuItem v-slot="{ active }" as="div" class="w-full">
                                    <button
                                        :class="[
                                            active ? 'bg-red-600 text-white' : 'text-red-600',
                                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                        ]"
                                        @click="showDelete(cu)"
                                    >
                                        <UserRemoveIcon
                                            :active="active"
                                            :class="[active ? 'text-white' : 'text-red-600']"
                                            class="w-5 h-5 mr-2"
                                            aria-hidden="true"
                                        />
                                        Remove User
                                    </button>
                                </MenuItem>
                            </div>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
            <div class="col-span-5 col-start-2" v-if="index < users.length - 1">
                <div class="flex flex-row justify-center w-full">
                    <div class="w-full h-px bg-gray-300"></div>
                </div>
            </div>
        </div>
    </div>
    <UserDeletionModal
        v-if="userToDelete"
        :user="userToDelete"
        @completed="userDeleted"
    ></UserDeletionModal>
</template>

<script lang="ts">
import { useAuth } from '@/services/auth'
import { CompanyUserListEntry, getCompanyUsers } from '@/services/companies'
import { defineComponent, ref } from 'vue'
import { CompanyUserType, companyUserTypeString } from '@/models/Company'
import { Menu, MenuItems, MenuButton, MenuItem } from '@headlessui/vue'
import { DotsVerticalIcon, UserRemoveIcon } from '@heroicons/vue/solid'
import UserDeletionModal from './UserDeletionModal.vue'

export default defineComponent({
    components: {
        Menu,
        MenuItems,
        MenuItem,
        MenuButton,
        DotsVerticalIcon,
        UserDeletionModal,
        UserRemoveIcon,
    },
    setup() {
        const { company } = useAuth()
        const companyId = company?.value?.id || 1
        // Check for admin status, remove remove option if not
        const isAdmin = ref(company?.value?.userType == CompanyUserType.admin)

        const users = ref<CompanyUserListEntry[]>([])
        const error = ref<any>()
        const loadUsers = async () => {
            const { users: responseUsers, error: responseError } = await getCompanyUsers(companyId)
            users.value = responseUsers.value
            error.value = responseError.value
        }
        loadUsers()

        const userToDelete = ref<CompanyUserListEntry>()
        const showDelete = (user: CompanyUserListEntry) => {
            userToDelete.value = user
        }

        const userDeleted = (deleted: boolean) => {
            userToDelete.value = undefined
            if (deleted) {
                loadUsers()
            }
        }

        return {
            error,
            users,
            companyUserTypeString,
            isAdmin,
            userToDelete,
            userDeleted,
            showDelete,
        }
    },
})
</script>
