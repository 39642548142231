<template>
    <div v-if="!(msAppLoaded&&isMobile)">
        <ModalButton>
            <template v-slot:button>Subscribe now</template>
            <template v-slot:modalTitle>{{
                gotNewSubscription ? 'Subscription Successful!' : 'Subscribe to access all features'
            }}</template>
            <template v-slot:modalContent>
                <div v-if="gotNewSubscription"></div>
                <SubscriptionSelection v-else></SubscriptionSelection>
            </template>
        </ModalButton>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAuth } from '@/services/auth'
import ModalButton from './ModalButton.vue'
import SubscriptionSelection from '../settings/SubscriptionSelection.vue'

export default defineComponent({
    components: { ModalButton, SubscriptionSelection },

    setup() {
        const { gotNewSubscription, msAppLoaded, isMobile } = useAuth()
        return {
            gotNewSubscription,
            msAppLoaded,
            isMobile
        }
    },
})
</script>
