import CompanyTeam from '@/models/CompanyTeam'
import CompanyTeamChannel from '@/models/CompanyTeamChannel'
import { EmotionScore } from '@/models/EmotionScore'
import { ref } from 'vue'
import { callApi, callApiWithAuth } from './api'
import { useAuth } from './auth'
import { EmotionInsightMessage } from '@/models/EmotionInsightMessage'

export const getTeams = async () => {
    const { apiResponse, error, get } = callApiWithAuth('teams/all')

    await get()

    const teams: CompanyTeam[] = apiResponse.value.teams
    return { teams, error }
}

export interface TeamDetailResponse {
    team: CompanyTeam
    scores: EmotionScore
    stats?: { channelCount: number; messageCount: number }
}

export const getTeamDetails = async (teamId: number) => {
    const { apiResponse, error, get } = callApiWithAuth(`teams/${teamId}/details`)

    await get()

    const details: TeamDetailResponse = apiResponse.value
    return { details, error }
}

export const getTeamDetailsByDateRange = async (teamId: number, startDate: string, endDate: string) => {
    const { apiResponse, error, get } = callApiWithAuth(`teams/${teamId}/details`)

    await get({ startDate, endDate })

    const details: TeamDetailResponse = apiResponse.value
    return { details, error }
}

export interface TeamChannelEntry {
    channel: CompanyTeamChannel
    scores: EmotionScore
}

export const getTeamChannels = async (teamId: number, dateRangeEnum: number) => {
    const { apiResponse, error, get } = callApiWithAuth(`teams/${teamId}/channels`)

    await get({ dateRangeEnum: dateRangeEnum })

    const channels: any[] = apiResponse.value.channels || []
    const entries = channels.map((c) => {
        const channel: CompanyTeamChannel = c
        const scores: EmotionScore = c
        return { channel: channel, scores: scores }
    })

    return { entries, error }
}
export const getTeamChannelsByDateRange = async (teamId: number, startDate: string, endDate: string) => {
    const { apiResponse, error, get } = callApiWithAuth(`teams/${teamId}/channels`)
    if (startDate && endDate)
        await get({ startDate, endDate })
    else
        await get()

    const channels: any[] = apiResponse.value.channels || []
    const entries = channels.map((c) => {
        const channel: CompanyTeamChannel = c
        const scores: EmotionScore = c
        return { channel: channel, scores: scores }
    })

    return { entries, error }
}

export const createTeam = async (name: string) => {
    const { apiResponse, error, post } = callApiWithAuth('teams/')
    const { company } = useAuth()
    const team = ref<CompanyTeam>()
    if (!company?.value) {
        error.value = { message: 'No company provided' }
    } else {
        const payload = { companyId: company.value?.id, name: name }
        await post(payload)

        team.value = apiResponse.value.team
    }

    return { team, error }
}

export const deleteTeamOrChannel = async (teamId: number, channelId?: number) => {
    let endpoint = `teams/${teamId}`
    if (channelId) {
        endpoint = endpoint + `/channels/${channelId}`
    }
    const { error, deleteCall } = callApiWithAuth(endpoint)
    await deleteCall()
    return { error }
}

export const getMSTeamsChannels = async (): Promise<{ channelsResponse: any[], errorResonse: any }> => {
    const { apiResponse, error, get } = callApiWithAuth(`teams/info/allMSTeamsChannels`)
    await get({})
    const channelsResponse = apiResponse.value || []
    return { channelsResponse, errorResonse: error }
}

export const addMSTeamsChannelToTeam = async (team_id: number, channel_id: string, ms_teams_id: string) => {
    const { apiResponse, error, post } = callApiWithAuth(`teams/addMSTeamsChannel`)
    const { company } = useAuth()

    if (!company?.value) {
        error.value = { message: 'No company provided' }
    } else {
        const payload = { channel_id, team_id, ms_teams_id }
        await post(payload)
    }
    const result = apiResponse.value;
    return { result, error }
}

export const getSlackChannels = async () => {
    const { apiResponse, error, get } = callApiWithAuth(`info/slackChannels`)
    await get({})
    const channels = apiResponse.value || []
    return { channels, error }
}

export const getDiscordChannels = async (): Promise<{ channelsResponse: any, errorResonse: any }> => {
    const { apiResponse, error, get } = callApiWithAuth(`teams/info/allDiscordChannels`)
    await get({})
    return { channelsResponse: apiResponse, errorResonse: error }
}

export const addSlackChannelToTeam = async (team_id: number, channel_id: string) => {
    const { apiResponse, error, post } = callApiWithAuth('teams/addSlackChannel')
    await post({ channel_id, team_id })
    const result = apiResponse.value
    return { result, error }
}

export const addDiscordChannelToTeam = async (team_id: number, channel_id: string, channel_name: string) => {
    const { apiResponse, error, post } = callApiWithAuth('teams/addDiscordChannel')
    await post({ channel_id, team_id, channel_name })
    const result = apiResponse.value
    return { result, error }
}

export const loadSlackHistory = async (team_id: number, channel_id: string) => {
    const { apiResponse, error, post } = callApiWithAuth('teams/loadSlackHistory')
    await post({ channel_id, team_id })
    const result = apiResponse.value
    return { result, error }
}

export const sendTeamReport = (teamId: number, startDate: string, endDate: string, targetEmail: string) => {
    const { apiResponse, error, post } = callApiWithAuth(`teams/sendTeamReport`)
    const postPromise = post({ teamId, startDate, endDate, targetEmail })
    return { postPromise, apiResponse, error }
}

export const scheduleTeamReport = (teamId: number, startDate: string, endDate: string, targetEmail: string, scheduleStartDate: string, frequency: string) => {
    const { apiResponse, error, post } = callApiWithAuth(`teams/scheduleTeamReport`)
    const postPromise = post({ teamId, startDate, endDate, frequency, targetEmail, scheduleStartDate })
    return { postPromise, apiResponse, error }
}

export const getLastMessagesWithEmotion = async (teamId: number, channelId: string, emotion: string) => {
    const { apiResponse, error, get } = callApiWithAuth(`teams/${teamId}/messageInsights/${channelId}/${emotion}`)
    await get({})
    const messages: EmotionInsightMessage[] = apiResponse.value || []
    console.log(messages);
    return { messages, error }
}
