import { IUserInfoFormData } from '@/models/FormData'
import User from '@/models/User'
import UserPaymentMethod from '@/models/UserPaymentMethod'
import { ISubscriptionPrice, UserSubscription } from '@/models/UserSubscription'
import { callApiWithAuth } from './api'
import { useAuth } from './auth'

export const uploadStripeToken = async (token: string) => {
    const { apiResponse, error, post } = callApiWithAuth('me/payment')

    const payload = { token: token }

    await post(payload)

    const newPayment: UserPaymentMethod = apiResponse.value.payment
    const stripeCustomerId: string = apiResponse.value.stripeCustomerId

    const { setPayment } = useAuth()
    setPayment(newPayment)
    return { newPayment, stripeCustomerId, error }
}

export const updateUser = async (user: IUserInfoFormData) => {
    const { error, apiResponse, post } = callApiWithAuth('me/')

    await post(user)

    if (apiResponse && !error.value) {
        const userJson = apiResponse.value.user
        const updated = new User(userJson)
        const { setUser } = useAuth()

        setUser(updated, false)
    }

    return { error }
}

export const updatePassword = async (current: string, newPw: string) => {
    const { error, post } = callApiWithAuth('me/password')

    const payload = {
        current: current,
        new: newPw,
    }

    await post(payload)
    return { error }
}

export const purchaseSubscription = async (price: ISubscriptionPrice, promoCodeId: any = '') => {
    const { apiResponse, error, post } = callApiWithAuth('me/subscription')
    const { setSubscription, gotNewSubscription } = useAuth()

    const payload = {
        planId: price.id,
        seats: price.seats,
        promoCodeId,
    }

    await post(payload)

    // Get the updated subscription from the response, then send it to the state handler
    if (apiResponse?.value?.subscription) {
        const newSub: UserSubscription = new UserSubscription(apiResponse?.value?.subscription)
        setTimeout(() => {
            setSubscription(newSub)
        }, 5000);
        gotNewSubscription.value = true;
    }
    return { error }
}

export const validatePromoCode = async (promoCode: string) => {
    // const { setPromoCode } = useAuth()
    const { apiResponse, error, post } = callApiWithAuth('me/promo-code')

    await post({ promoCode })

    const promoCodeId = apiResponse?.value?.id
    if (promoCodeId) {
        // TODO: setPromoCode
        // setPromoCode(promoCodeId)
    }

    return { error, apiResponse }
}
