<template>
    <div class="content">
        <div class="main-content">
            <div class="text-lg text-left">Audio Sentiment Analysis</div>
            <div class="upload">
                <label class="relative block">
                    <span class="text-gray-400">Upload audio</span>
                    <input
                        :disabled="processingInProgress"
                        class="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                        placeholder="First type the file name"
                        type="text"
                        name="search"
                        v-model="userFileName"
                    />
                </label>
                <div
                    class="drop flex justify-center items-center w-full"
                    v-if="!processingInProgress && userFileName.length"
                >
                    <label
                        for="dropzone-file"
                        class="flex flex-col justify-center items-center w-full h-32 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                        <div class="flex flex-col justify-center items-center pt-5 pb-6">
                            <svg
                                aria-hidden="true"
                                class="mb-3 w-10 h-10 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                            </svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span class="font-semibold">Click to upload media file</span>
                            </p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">
                                <!-- .mp3,.mp4,.webm,.amr,.flac,.wav,.ogg --> .mp3
                            </p>
                        </div>
                        <!-- <input
                            id="dropzone-file"
                            type="file"
                            class="hidden"
                            accept=".amr,.flac,.wav,.ogg,.mp3,.mp4,.webm"
                            accept=".amr,.flac,.wav,.ogg,.mp3,.mp4,.webm"
                            v-on:change="uploadFile"
                        /> -->
                            <input
                            id="dropzone-file"
                            type="file"
                            class="hidden"
                            accept=".mp3"
                            v-on:change="uploadFile"
                        />
                    </label>
                </div>
                <div
                    class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                    v-if="errorMessage"
                >
                    <strong class="font-bold mr-3">{{ errorTitle }}</strong>
                    <span class="block sm:inline"> {{ errorMessage }}</span>
                    <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg
                            v-on:click="clearErrorMessage"
                            class="fill-current h-6 w-6 text-red-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path
                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                            />
                        </svg>
                    </span>
                </div>
                <div v-if="uploadProgressPercentage && uploadProgressPercentage != 100">
                    Uploading...
                </div>
                <div
                    v-show="uploadProgressPercentage && uploadProgressPercentage != 100"
                    class="w-full bg-gray-200 rounded-full dark:bg-gray-700"
                >
                    <div
                        class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                        :style="'width:' + uploadProgressPercentage + '%'"
                    >
                        {{ uploadProgressPercentage }}%
                    </div>
                </div>

                <div
                    class="flex flex-col"
                    v-if="uploadProgressPercentage == 100 && processingInProgress"
                >
                    <button
                        disabled
                        type="button"
                        class="processing-loader text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                    >
                        <svg
                            role="status"
                            class="inline mr-3 w-4 h-4 text-white animate-spin"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                            />
                        </svg>
                        Performing sentiment analysis...
                    </button>
                    <span class="text-xs mt-3"
                        >Processing may take up to 10 minutes. You can view the results later
                        below.</span
                    >
                    <div v-if="processingInProgress">
                        While waiting for the results, you may check our
                        <router-link class="marketplace-link" to="/marketplace">
                            marketplace offers!</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import ScoreCard from '@/components/dashboard/ScoreCard.vue'
import { defineComponent, ref } from 'vue'
import { checkAudioSentimentScores, getUploadURL, uploadFileToS3 } from '@/services/projects'
import router from '@/router'

export default defineComponent({
    components: { ScoreCard },
    props: ['companyProjectId'],
    data: () => ({
        progressRef: ref({ loaded: 0, total: 1 }),
        scoreObj: { avgScores: null },
        processingInProgress: false,
        isUploading: false,
        intervalId: 0,
        userFileName: '',
        errorMessage: '',
        errorTitle: '',
    }),
    created: function () {
        router.beforeEach((to, from, next) => {
            if (this.isUploading) {
                if (confirm('The current upload will fail if you continue. Are you sure?')) {
                    this.isUploading = false
                    next()
                }
            } else {
                next()
            }
        })
    },
    computed: {
        uploadProgressPercentage() {
            return Math.floor((this.progressRef.loaded * 100) / this.progressRef.total)
        },
        averageScores() {
            return this.scoreObj.avgScores
        },
    },
    methods: {
        clearErrorMessage() {
            this.errorMessage = ''
            this.errorTitle = ''
        },
        pollScores(fileKey: string) {
            this.intervalId = setInterval(async () => {
                this.clearErrorMessage()
                const { error, response } = await checkAudioSentimentScores(fileKey)
                if (error.value) {
                    this.$emit('onUploadStatusChanged', error)
                    this.errorMessage = 'Error checking file status'
                    return
                }

                if (!response.value) return

                if (response.value.status == 'SCORING_FAILED') {
                    this.$emit('onUploadStatusChanged', response.value.status)
                    this.errorMessage = 'Error scoring transcribed file.'
                }
                if (response.value.status == 'TRANSCRIPTION_FAILED') {
                    this.errorMessage = 'Error transcribing file.'
                }

                if (response.value.status == 'TRANSCRIPTION_INITIATED') {
                    this.$emit('onUploadStatusChanged', response.value.status)
                }

                if (
                    ['SCORING_COMPLETED', 'SCORING_FAILED', 'TRANSCRIPTION_FAILED'].includes(
                        response.value.status
                    )
                ) {
                    this.$emit('onUploadStatusChanged', response.value.status)
                    this.processingInProgress = false
                    this.progressRef = { loaded: 0, total: 1 }
                    clearInterval(this.intervalId)
                }

                if (response.value.status == 'SCORING_COMPLETED') {
                    this.$emit('onUploadStatusChanged', response.value.status)
                    setTimeout(() => {
                        this.$router.push(
                            `/audio/${response.value.id}/${response.value.fileName.replace(
                                /[^a-zA-Z0-9-_]/g,
                                '+'
                            )}`
                        )
                    }, 5000)
                }
            }, 60 * 1000)
        },
        async uploadFile(e: any) {
            if (!e.target.files.length) return
            const imagefile: HTMLInputElement = document.getElementById(
                'dropzone-file'
            ) as HTMLInputElement
            // @ts-ignore: Object is possibly 'null'.
            const file = imagefile.files[0]
            if (file.size > 1024 * 1024 * 1024) {
                this.errorMessage = `Selected file has ${(file.size / (1024 * 1024 * 1024)).toFixed(
                    2
                )}Gb which is bigger than the allowed limit of 1Gb.`
                this.errorTitle = 'File too large!'
                imagefile.value = ''
                return
            }
            const splitFileName = file.name.split('.')
            if (
                // !['amr', 'flac', 'wav', 'ogg', 'mp3', 'mp4', 'webm'].includes(
                !['mp3'].includes(
                    splitFileName[splitFileName.length - 1].toLowerCase()
                )
            ) {
                this.errorTitle = 'Unsupported file type!'
                this.errorMessage =
                    "Please choose one of the supported file types: 'amr', 'flac', 'wav', 'ogg', 'mp3', 'mp4', or 'webm'."
                return
            }

            this.clearErrorMessage()
            this.isUploading = true
            this.processingInProgress = true
            this.scoreObj = { avgScores: null }
            this.progressRef.loaded = 1
            this.progressRef.total = 100
            const { error, uploadURL, fileKey } = await getUploadURL(
                this.companyProjectId,
                file.name,
                this.userFileName
            )

            if (error.value) {
                this.errorMessage = 'Error initiating file upload'
                this.isUploading = false

                return
            }
            const uploadResponse: { error: any } = await uploadFileToS3(
                uploadURL,
                file,
                this.progressRef
            )

            if (uploadResponse.error.value) {
                this.errorMessage = 'Error uploading file'
                this.isUploading = false
                return
            }
            this.isUploading = false
            this.$emit('onUploadStatusChanged')
            this.pollScores(fileKey)
        },
    },
})
</script>

<style scoped>
h3 {
    margin-top: 20px;
}
.content {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.main-content div {
    margin: 20px 0;
}

.main-content {
    width: 400px;
}
.text-button-font {
    font-family: 'Courier New', Courier, monospace;
}
.marketplace-link {
    color: #0891b2;
}
</style>
