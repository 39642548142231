<template>
    <div class="flex flex-row">
        <div v-if="payment">
            <h2 class="text-lg font-semibold">Active Payment Method</h2>
            <p class="mt-2 text-base">Card Type: {{ payment.creditCardType }}</p>
            <p class="mt-2 text-base">Last Four: {{ payment.lastFour }}</p>
            <p class="mt-2 text-base">
                Exp: {{ payment.expirationMonth }} / {{ payment.expirationYear }}
            </p>
        </div>
        <div class="flex-grow"></div>
        <div class="">
            <button
                class="primary-button"
                v-if="!(updating && !payment)"
                @click="updating = !updating"
            >
                {{ updating ? 'Cancel' : payment ? 'Update' : 'Add Payment' }}
            </button>
        </div>
    </div>
    <div v-if="updating" class="mt-4">
        <StripeElement :element="cardElement" @change="event = $event"></StripeElement>
        <div class="flex flex-row">
            <div class="flex-grow"></div>
            <LoadingButton class="mt-4" :disabled="false" :loading="loading" @click="registerCard"
                >Add Card</LoadingButton
            >
        </div>
        <div v-if="event && event.error">{{ event.error.message }}</div>
    </div>
</template>

<script lang="ts">
import { useAuth } from '@/services/auth'
import { uploadStripeToken } from '@/services/me'
import { defineComponent, ref, watch } from 'vue'
import { useStripe, StripeElement, ElementChangeEvent } from 'vue-use-stripe'
import LoadingButton from '../global/LoadingButton.vue'

export default defineComponent({
    components: { StripeElement, LoadingButton },
    emits: ['updating'],
    setup(_, { emit }) {
        const event = ref<ElementChangeEvent>()
        const updating = ref(false)
        const loading = ref(false)

        const { payment } = useAuth()

        if (!payment?.value) {
            updating.value = true
        }

        const {
            stripe,
            elements: [cardElement],
        } = useStripe({
            key: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
            elements: [{ type: 'card', options: {} }],
        })

        const registerCard = async () => {
            if (event.value?.complete) {
                loading.value = true
                const tokenResult = await stripe.value?.createToken(cardElement.value)
                const tokenId = tokenResult?.token?.id
                if (!tokenId) {
                    console.error('no stripe token created')
                    loading.value = false
                    return
                }
                await uploadStripeToken(tokenId)
                loading.value = false
                updating.value = false
            }
        }

        watch(updating, () => {
            emit('updating', updating.value)
        })

        return { payment, updating, loading, event, cardElement, registerCard }
    },
})
</script>
