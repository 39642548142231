import { Company, CompanyUserType } from '@/models/Company'
import { ICompanyInfoFormData, IUserInfoFormData } from '@/models/FormData'
import User from '@/models/User'
import UserPaymentMethod from '@/models/UserPaymentMethod'
import { IUserSubscription, UserSubscription } from '@/models/UserSubscription'
import { reactive, toRefs, watch, computed } from 'vue'
import * as microsoftTeams from "@microsoft/teams-js";
import { callApi, callApiWithAuth } from './api'
import router from '@/router'

const AUTH_KEY = 'moodconnect_token'
export const AUTH_TOKEN = 'auth_token'
const companyIdKey = 'mc_company_id'
const userTypeKey = 'mc_user_type'

interface AuthState {
    authenticating: boolean
    authToken?: string
    user?: User
    subscription?: IUserSubscription
    company?: Company
    payment?: UserPaymentMethod
    error?: Error,
    gotNewSubscription: boolean,
    msAppLoaded: boolean,
    isMobile: boolean
}

const state = reactive<AuthState>({
    authenticating: false,
    authToken: undefined,
    user: undefined,
    subscription: undefined,
    company: undefined,
    payment: undefined,
    error: undefined,
    gotNewSubscription: false,
    msAppLoaded: true,
    isMobile: false
})

const isDemo = process.env.VUE_APP_IS_DEMO == 1
const token = window.localStorage.getItem(AUTH_KEY)

// eslint-disable-next-line
if (token && !isDemo) {
    const { error, apiResponse, get } = callApi('/me')
    // Need to figure out a better way to make API calls wait until auth is set up
    state.authToken = token
    state.authenticating = true

    get({}, { headers: { Authorization: `Bearer ${token}` } })

    watch([apiResponse, error], () => {
        if (error.value) {
            state.authToken = undefined
            state.user = undefined
            window.localStorage.removeItem(AUTH_KEY)
        } else if (apiResponse.value) {
            const { setUser } = useAuth()
            apiResponse.value[AUTH_TOKEN] = token
            setUser(apiResponse.value, true)
        }

        state.authenticating = false
    })
}

export const useAuth = () => {
    state.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    microsoftTeams.app.initialize().then(() => {
        state.msAppLoaded = true
    }).catch((e) => {
        // TODO: Fix this hack. This workaround was applied since for some reason in prod, initialization fails.
        // Since we so not need to use the context from the app initialisation, this fix was reasonable atm.
        if (String(e) == 'Error: Initialization Failed. No Parent window found.') {
            state.msAppLoaded = false
        } else {
            state.msAppLoaded = true
        }
    })

    const setUser = (payload: User, remember: boolean): void => {
        console.log('setting user: ', payload)
        if (remember && payload[AUTH_TOKEN]) {
            state.authToken = payload[AUTH_TOKEN]
            window.localStorage.setItem(AUTH_KEY, payload[AUTH_TOKEN])
        }

        const user = new User(payload)
        state.user = user
        if (user.company) {
            setCompany(user.company)
            if (user.company.subscription) {
                state.subscription = user.company.subscription
            } else {
                console.info('no subscription found on company')
            }
        }

        if (user.paymentMethod) {
            state.payment = user.paymentMethod
        }
        state.error = undefined
    }

    const loggedIn = computed(() => {
        return state.authToken != undefined && state.authToken.length > 0
    })

    const logout = (): Promise<void> => {
        window.localStorage.clear()
        state.authToken = undefined
        state.user = undefined
        state.subscription = undefined
        state.company = undefined
        state.payment = undefined
        return Promise.resolve()
    }

    const setPayment = (pm: UserPaymentMethod) => {
        state.payment = pm
    }

    const setSubscription = (s: UserSubscription) => {
        state.subscription = s
    }

    const setCompany = (c: Company) => {
        state.company = c
        window.localStorage.setItem(companyIdKey, `${c.id}`)
        window.localStorage.setItem(userTypeKey, `${c.userType}`)
    }

    const getCompanyId = () => {
        const fromState = state.company?.id
        if (fromState) {
            return fromState
        }
        const idString = window.localStorage.getItem(companyIdKey)
        if (idString) {
            return parseInt(idString)
        }
    }

    const userIsCompanyAdmin = computed(() => {
        const fromState = state.company?.isAdmin()
        if (fromState != undefined) {
            return fromState
        }
        const typeString = window.localStorage.getItem(userTypeKey)
        if (typeString) {
            const typeInt = parseInt(typeString)
            return typeInt == CompanyUserType.admin
        }

        return false
    })

    state.gotNewSubscription = false;

    return {
        setUser,
        logout,
        setPayment,
        setSubscription,
        getCompanyId,
        loggedIn,
        userIsCompanyAdmin,
        ...toRefs(state),
    }
}

export const login = async (username: string, password: string, recaptchaToken: string) => {
    const { apiResponse, error, post } = callApi('/auth/login')

    const payload = { username, password, recaptchaToken }

    await post(payload)

    if (error.value == undefined) {
        const user = apiResponse.value.user
        const token = apiResponse.value.token
        user[AUTH_TOKEN] = token
        const { setUser } = useAuth()
        setUser(user, true)
    }

    return { error }
}

export const googleLogin = async (idToken: string) => { 

    const { apiResponse, error, post } = callApi('/auth/googleLogin')
    
    await post({ token: idToken })
    console.log("Auth res: ", apiResponse.value);
    if (error.value == undefined && apiResponse.value) {
        const user = apiResponse.value.user
        const token = apiResponse.value.token
        user[AUTH_TOKEN] = token
        const { setUser } = useAuth()
        setUser({ ...apiResponse.value?.user, company: apiResponse.value?.user?.companies?.at(0) }, true);

    }

    return { error, apiResponse }
}
export const saveCompanyDetails = async (company: ICompanyInfoFormData) => {
    const { apiResponse, error, post } = callApiWithAuth('/auth/saveCompanyDetails')

    await post(company)
    if (error.value == undefined) {
        const { setUser } = useAuth()
        setUser({...apiResponse.value?.user, company:apiResponse.value?.user?.companies?.at(0)}, true);
    }

    return { error, apiResponse }
}
export const register = async (
    user: IUserInfoFormData,
    company?: ICompanyInfoFormData,
    inviteToken?: string,
    recaptchaToken?: string
) => {
    const { apiResponse, error, post } = callApi('/auth/register')

    let companyData: any
    if (company) {
        companyData = company
    } else if (inviteToken) {
        companyData = {
            inviteToken: inviteToken,
        }
    }
    const payload: any = user
    payload.company = companyData
    payload.recaptchaToken = recaptchaToken


    await post(payload)

    if (apiResponse && !error.value) {
        router.push({ name: 'login' })
        return { error }
    }
}


export const forgotPassword = async (email: string) => {
    const { error, post } = callApi('/auth/forgotPassword')

    await post({ email: email })

    return { error }
}

export const resetPassword = async (token: string, password: string) => {
    const { error, post } = callApi('/auth/resetPassword')

    await post({ resetToken: token, password: password, confirm: password })

    return { error }
}
