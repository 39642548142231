<template>
    <div class="flex flex-col items-center justify-center h-full">
        <div class="flex-grow"></div>
        <p class="my-4 text">{{ helperText }}</p>
        <LoadingButton :loading="loading" :disabled="!slackConnected" @click="routeToDashboard">{{
                buttonText
        }}</LoadingButton>
        <div class="error-msg" v-if="responseError">{{ responseError }}</div>
        <div class="flex-grow"></div>
    </div>
</template>

<script lang="ts">
import { useAuth } from '@/services/auth'
import { connectSlackToken } from '@/services/companies'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LoadingButton from '@/components/global/LoadingButton.vue'
import { Company } from '@/models/Company'

export default defineComponent({
    setup() {
        const { company, getCompanyId } = useAuth()
        const slackConnected = computed(() => {
            return company?.value?.slackConnected == true
        })
        const helperText = computed(() => {
            return slackConnected.value ? 'Slack Connected!' : 'Finishing Slack Connection'
        })
        const buttonText = computed(() => {
            return slackConnected.value ? 'Go to Dashboard' : 'Connecting'
        })
        const loading = ref(false)
        const responseError = ref<any>()
        const saveToken = async (token: string) => {
            const companyId = getCompanyId()
            if (!companyId) {
                return
            }
            loading.value = true
            const { error } = await connectSlackToken(companyId, token)
            loading.value = false
            if (error.value) {
                responseError.value = error.value
            } else {
                if (company && company.value) {
                    company.value.slackConnected = true;
                }
            }
        }
        const router = useRouter()
        const routeToDashboard = () => {
            router.push('/dashboard/teams')
        }
        onMounted(async () => {
            const route = useRoute()
            const token = route.query.code as string

            if (token && token.length > 0 && slackConnected.value == false) {
                loading.value = true
                await saveToken(token)
            }
        })

        return { slackConnected, loading, helperText, buttonText, responseError, routeToDashboard }
    },
    components: { LoadingButton },
})
</script>
