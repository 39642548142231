<template>
    <div class="register h-full">
        <div class="grid grid-cols-9 place-content-center h-full">
            <div class="col-span-9 md:col-start-3 md:col-span-5">
                <h2 class="text-left text-xl font-medium mb-1">Register</h2>
                <!-- Card -->
                <div class="onboarding-card">
                    <div class="w-full">
                        <UserInfoForm v-if="!userCompleted && userData" :userData="userData"
                            :captchaRequired="Boolean(inviteToken)" @user-submitted="userSubmitted"></UserInfoForm>
                        <CompanyInfoForm v-if="userCompleted" :companyData="companyData"
                            @company-submitted="companySubmitted" :loading="loading"></CompanyInfoForm>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref } from 'vue'
import UserInfoForm from '@/components/onboarding/UserInfoForm.vue'
import { OnboardingTestData } from '@/models/UITestData'
import {
    CompanyInfoFormData,
    ICompanyInfoFormData,
    IUserInfoFormData,
    UserInfoFormData,
} from '@/models/FormData'
import CompanyInfoForm from '@/components/onboarding/CompanyInfoForm.vue'
import { register } from '@/services/auth'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
    components: { UserInfoForm, CompanyInfoForm },
    setup() {
        /*
            This view handles registration for both company admins and managers. Managers will have an invite token from the email they received passed in and won't need to complete the company information portion
        */
        // Pulls in test data for easy checking of layout
        const isUITest = inject('isUITesting') == true
        // const userData = ref(isUITest ? OnboardingTestData.userInfoForm : new UserInfoFormData())
        const userData = ref<IUserInfoFormData>()
        const companyData = ref(
            isUITest ? OnboardingTestData.companyInfoForm : new CompanyInfoFormData()
        )
        const userCompleted = ref(false)
        const loading = ref(false)

        const inviteToken = ref<string>()

        const missingRecaptcha = ref(false)

        const checkRecaptcha = (recaptchaToken: string) => {
            if (recaptchaToken == undefined || recaptchaToken == '') {
                missingRecaptcha.value = true
                return false
            }
            missingRecaptcha.value = false
            return true
        }

        const userSubmitted = async (
            data: {
                user: IUserInfoFormData,
                recaptchaToken: string
            }
        ) => {
            const { user, recaptchaToken } = data;
            if (inviteToken.value && !checkRecaptcha(recaptchaToken)) {
                return
            }
            userData.value = user

            // Check to see if there is an invite token here
            const token = inviteToken.value
            if (token) {
                loading.value = true
                const error = await register(userData.value, undefined, token, recaptchaToken)
                loading.value = false

                if (error?.error.value == undefined) {
                    router.push('/dashboard/teams')
                }
            } else {
                userCompleted.value = true
            }
        }

        const router = useRouter()

        const companySubmitted = async (
            data: {
                company: ICompanyInfoFormData,
                recaptchaToken: string
            }
        ) => {
            const { company, recaptchaToken } = data;
            if (!checkRecaptcha(recaptchaToken)) {
                return
            }
            companyData.value = company
            loading.value = true
            if (!userData.value) {
                return
            }
            const error = await register(userData.value, companyData.value, undefined, recaptchaToken)
            loading.value = false

            if (error?.error.value == undefined) {
                router.push('/dashboard/teams')
            }
        }

        onMounted(() => {
            const route = useRoute()
            const token = route.query.token as string
            const email = route.query.email as string
            if (isUITest) {
                userData.value = OnboardingTestData.userInfoForm
            } else {
                const formData = new UserInfoFormData()

                if (email) {
                    formData.email = email
                }
                const firstName = route.query.firstName as string
                if (firstName) {
                    formData.firstName = firstName
                }
                const lastName = route.query.lastName as string
                if (lastName) {
                    formData.lastName = lastName
                }

                userData.value = formData
            }

            inviteToken.value = token
        })

        return {
            userData, companyData, userCompleted,
            userSubmitted, companySubmitted,
            loading, missingRecaptcha,
            inviteToken
        }
    },
})
</script>
