import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubscriptionSelection = _resolveComponent("SubscriptionSelection")!
  const _component_ModalButton = _resolveComponent("ModalButton")!

  return (!(_ctx.msAppLoaded&&_ctx.isMobile))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModalButton, null, {
          button: _withCtx(() => [
            _createTextVNode("Subscribe now")
          ]),
          modalTitle: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.gotNewSubscription ? 'Subscription Successful!' : 'Subscribe to access all features'), 1)
          ]),
          modalContent: _withCtx(() => [
            (_ctx.gotNewSubscription)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2))
              : (_openBlock(), _createBlock(_component_SubscriptionSelection, { key: 1 }))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}