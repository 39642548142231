<template>
    <div class="dashboard p-4">
        <div v-if="hasSubscription">
            <DashboardList />
        </div>
        <div v-else class="flex flex-col items-center justify-center h-full">
            <p class="text-lg mt-8">
                This is where Subscribers can see their teams' emotional progress.
            </p>
            <p class="text-lg mb-8">Find out how your employees are feeling.</p>
            <SubscriptionModal v-if="isAdmin"></SubscriptionModal>
        </div>
    </div>
</template>

<script lang="ts">
import { useAuth } from '@/services/auth'
import { defineComponent, onMounted, ref, watch } from 'vue'
import DashboardList from '../../components/dashboard/DashboardList.vue'
import SubscriptionModal from '@/components/global/SubscriptionModal.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
    components: { DashboardList, SubscriptionModal },
    setup() {
        const { subscription, company } = useAuth()
        const isAdmin = ref(company?.value?.isAdmin() == true)
        const hasSubscription = ref(subscription?.value != null && subscription.value.isActive)

        watch([subscription, company], () => {
            hasSubscription.value = subscription?.value != null && subscription.value.isActive
            isAdmin.value = company?.value?.isAdmin() == true

        })

        const obj = { hasSubscription, isAdmin }
        return obj
    },

})
</script>