<template>
    <div id="google-signin-button"></div>
    <!-- Correct id for button -->
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'GoogleSignInButton',
    mounted() {
        this.loadGoogleApi()
    },
    methods: {
        loadGoogleApi() {
            const script = document.createElement('script')
            script.src = 'https://accounts.google.com/gsi/client' // New Google Identity Service
            script.async = true
            script.defer = true
            script.onload = this.initializeGoogleSignIn // Callback after script loads
            document.head.appendChild(script)
        },
        initializeGoogleSignIn() {
            const button = document.createElement('button');
            button.innerText = 'Continue with Google';
            button.id = 'g_id_signin';
            button.className = 'g_id_signin'; // Add your styling here
            document.getElementById('google-signin-button')?.appendChild(button); // Append button to the div

            // Initialize Google Sign-In with GIS
            (window as any).google.accounts.id.initialize({
                client_id: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID, // Your Client ID
                callback: this.handleCredentialResponse, // Handle sign-in response
            });

            (window as any).google.accounts.id.renderButton(
                button,
                { theme: 'outline', size: 'large' }, // Customize button style
            );

            // ;(window as any).google.accounts.id.prompt() // Optional: show the one-tap prompt
            (window as any).onSignIn = this.onSignIn; // Handle sign-in response
        },
        handleCredentialResponse(response: any) {
            console.log('Encoded JWT ID token: ' + response.credential)
            this.$emit('token', response.credential) // Emit the token to the parent
        },
        onSignIn(response: any) {
            // This function will be called upon successful sign-in
            console.log('Encoded JWT ID token : onSignIn' + response.credential)
            // You can send this token to your backend for verification and user creation
            this.$emit('token', response.credential) // Emit the token to parent or handle it directly
        },
    },
})
</script>

<style scoped>
/* Add any custom styles for the button here */
.g_id_signin {
    /* Example styling */
    padding: 10px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
</style>
