<template>
    <div v-if="!msAppLoaded" class="footer bottom-0 w-100">
        <div class="pt-5 grid grid-cols-3 pl-4 pb-4">
            <div>
                <div class="grid grid-cols-3">
                    <div class="help">
                        Need help or have questions? <br />Contact us at
                        <a href="mailto:info@moodconnectapp.com">info@moodconnectapp.com</a>
                    </div>
                </div>
                <div class="pl-2 col-span-3 md:col-span-1">
                    <div class="flex flex-col space-y-2">
                        <a href="https://www.moodconnect.com/" target="_blank"><img
                                src="../../assets/logo_moodconnect_white.png" alt="MoodConnect" class="w-40 -ml-5" /></a>
                        <div class="flex flex-row space-x-4 items-center">
                            <a href="https://www.instagram.com/moodconnect/" target="_blank"><img
                                    src="../../assets/ig_icon.png" alt="Instagram" class="w-6" /></a>
                            <a href="https://www.linkedin.com/company/moodconnect" target="_blank"><img
                                    src="../../assets/li_icon.png" alt="LinkedIn" class="w-6" /></a>
                            <a href="https://www.facebook.com/moodconnect" target="_blank"><img
                                    src="../../assets/fb_icon.png" alt="Facebook" class="w-6" /></a>
                            <a href="https://www.youtube.com/channel/UCBAAxO8SJ1KzBOqEsW2f0mg" target="_blank"><img
                                    src="../../assets/yt_icon.png" alt="YouTube" class="w-6" /></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-3 md:col-span-1 md:col-start-3">
                <div class="flex flex-col space-y-2 mt-2">
                    <p class="text-lg">Resources</p>
                    <router-link class="text-sm" to="/terms">Terms and Conditions</router-link>
                    <router-link class="text-sm" to="/privacy">Privacy Policy</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useAuth } from '@/services/auth'

export default defineComponent({
    setup() {
        // Does this matter if the user is logged in? Should just be the same footer regardless
        const { msAppLoaded } = useAuth()

        return {
            msAppLoaded,
        }
    },
})
</script>

<style scoped>
.footer {
    background-color: #18b8d1;
}

.footer-col {
    @apply flex flex-col space-y-2;
}

.help {
    @apply text-sm;
    padding-left: 10px;
}

a:hover {
    text-decoration: underline;
}
</style>
