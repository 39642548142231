<template>
    <div class="scores-line bg-gray-200">
        <div
            class="score-line inline-block"
            :style="`width:${800 * score.ratio}px;${
                lineIndex == activeIndex ? 'height:40px;border: 1px solid red;' : ''
            }`"
            v-for="(score, lineIndex) of scores"
        >
            <div
                :class="[
                    label,
                    'score h-2 block  shadow-md cursor-pointer',
                    lineIndex == activeIndex ? 'pl-3 shadow-md' : 'justify-center',
                ]"
                :style="`width: 100%;${
                    lineIndex == activeIndex ? 'height:13px' : ''
                };border-radius: 3px;' : ''}`"
                :title="`${newScoreLabelMap[label]} ${Math.ceil(score.scores[label] * 100)}% `"
                v-for="label of Object.keys(score.scores)"
            ></div>
        </div>
    </div>
    <Transition name="nested">
        <div class="selected-score p-2 bg-gray-200" v-if="activeIndex">
            <div
                :class="[
                    label,
                    'inner selected-label p-1 mb-1 block  shadow-md cursor-pointer pl-3 shadow-md',
                ]"
                :style="`width: ${
                    120 + scores[activeIndex].scores[label] * 100
                }px;text-transform: capitalize;`"
                v-for="label of Object.keys(scores[activeIndex].scores)"
            >
                {{ newScoreLabelMap[label] }} {{ (scores[activeIndex].scores[label] * 100).toFixed(0) }}%
            </div>
        </div>
    </Transition>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
    props: ['scores', 'activeIndex'],
    setup() {

        const newScoreLabelMap: any = {
            happy: 'Enthusiastic',
            angry: 'Frustrated',
            sad: 'Burned Out',
            neutral: 'Satisfied',
            surprise: 'Confused',
            fear: 'Uncertain',
            disgust: 'Annoyed'
        }
        return {
            newScoreLabelMap
        }
    },
})
</script>

<style scoped>
.scores-line {
    width: 800px;
    height: 40px;
}
.score-line {
    margin: 0px;
    border-radius: 2px;
    padding: 0;
}
.score {
    border-radius: 1px;
    margin: 0px;
    padding: 0px;
}
.happy {
    background-color: #c1f792;
}

.angry {
    background-color: #ff9898;
}

.disgust {
    background-color: #8d7824;
}

.fear {
    background-color: #888888;
}
.sad {
    background-color: #95d5d5;
}

.surprise {
    background-color: #f8d28e;
}

.neutral {
    background-color: white;
}
.selected-score {
    width: 800px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    transition: display 5s ease-in-out;
}
.selected-label {
    border-radius: 4px;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
    transition: all 0.3s ease-in-out;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
    transform: translateX(30px);
    opacity: 0;
}
</style>