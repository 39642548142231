<template>
    <ModalContainer :openImmediately="true" @closed="modalClosed">
        <template v-slot:modalTitle>Are you sure that you want to delete this user?</template>
        <template v-slot:modalContent>
            <p class="text-md text-red-600 mb-2">
                Once you delete a user's account, it cannot be recovered!
            </p>

            <LoadingButton
                @click="deleteUser"
                :disabled="loading"
                :loading="loading"
                class="bg-red-600"
                >Delete User</LoadingButton
            >
            <div class="error-msg" v-if="responseError">{{ responseError }}</div>
        </template>
    </ModalContainer>
</template>

<script lang="ts">
import { useAuth } from '@/services/auth'
import { CompanyUserListEntry, deleteUserFromCompany } from '@/services/companies'
import { defineComponent, PropType, ref } from 'vue'
import LoadingButton from '../global/LoadingButton.vue'
import ModalContainer from '../global/ModalContainer.vue'

export default defineComponent({
    props: {
        user: {
            type: Object as PropType<CompanyUserListEntry>,
            required: true,
        },
    },
    emits: ['completed'],
    setup(props, { emit }) {
        const loading = ref(false)
        const responseError = ref<any>()

        const deleteUser = async () => {
            const { getCompanyId } = useAuth()
            const companyId = getCompanyId()
            if (!companyId) {
                return
            }

            const { error } = await deleteUserFromCompany(companyId, props.user.user.id)
            if (error.value) {
                responseError.value = error.value
            } else {
                // Emit that this is finished
                emit('completed', true)
            }
        }

        const modalClosed = () => {
            emit('completed', false)
        }

        return { loading, deleteUser, responseError, modalClosed }
    },
    components: { LoadingButton, ModalContainer },
})
</script>
