<template>
    <div class="w-full" v-if="apps.length">
        <Listbox class="w-72" v-model="selectedApp">
            <div class="relative">
                <ListboxButton class="
                        relative
                        w-full
                        py-2
                        pl-3
                        pr-10
                        text-left
                        rounded-md
                        cursor-default
                        sm:text-sm
                        border-gray-500 border-2
                    ">
                    <span class="truncate"> {{ selectedApp.name }}</span>
                    <span class="
                            absolute
                            inset-y-0
                            right-0
                            flex
                            items-center
                            pr-2
                            pointer-events-none
                        ">
                        <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                    leave-to-class="opacity=0">
                    <ListboxOptions class="
                            absolute
                            w-full
                            py-1
                            mt-1
                            z-20
                            overflow-auto
                            text-base
                            bg-white
                            rounded-md
                            shadow-lg
                            max-h-60
                        ">
                        <ListboxOption v-slot="{ active, selected }" v-for="app in appsRef" :key="app.name" :value="app"
                            as="template">
                            <li :class="[
                                app.value ? 'text-amber-900 bg-amber-100' : 'text-gray-300',
                                'cursor-default select-none relative py-2 pl-10 pr-4',
                            ]" :disabled="app.value">
                                <span :class="[
                                    selected ? 'font-medium' : 'font-normal',
                                    'block truncate',
                                ]">{{ app.name }}</span>
                                <span v-if="selected" class="
                                        absolute
                                        inset-y-0
                                        left-0
                                        flex
                                        items-center
                                        pl-3
                                        text-amber-600
                                    ">
                                    <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>

</template>
<script lang="ts">
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { defineComponent, ref, watch, Ref, toRef, toRefs } from "vue";

export default defineComponent({
    components: {
        Listbox, ListboxButton, ListboxOptions,
        ListboxOption, CheckIcon, SelectorIcon
    },
    props: {
        apps: {
            type: Array,
            required: true
        }
    },
    setup(props, { emit }) {
        const { apps } = toRefs(props)
        const appsRef: Ref<Array<{ name: string, value: string }>> = apps as Ref<Array<{ name: string, value: string }>>
        const selectedApp: Ref = ref<{ name: string, value: string }>(appsRef.value[0])

        watch(selectedApp, () => {
            emit('appChanged', selectedApp.value.value)
        })
        return {
            selectedApp,
            appsRef
        }
    }
})
</script>
<style>
</style>