<template>
    <div class="w-full" v-if="channels.length">
        <div>
            <Listbox class="w-72" v-model="selectedChannel">
                <div class="relative">
                    <ListboxButton
                        class="
                            relative
                            w-full
                            py-2
                            pl-3
                            pr-10
                            text-left
                            rounded-md
                            cursor-default
                            sm:text-sm
                            border-gray-500 border-2
                        "
                    >
                        <span class="truncate"> {{ selectedChannel.title }}</span>
                        <span
                            class="
                                absolute
                                inset-y-0
                                right-0
                                flex
                                items-center
                                pr-2
                                pointer-events-none
                            "
                        >
                            <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>

                    <transition
                        leave-active-class="transition duration-100 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity=0"
                    >
                        <ListboxOptions
                            class="
                                absolute
                                w-full
                                py-1
                                mt-1
                                z-20
                                overflow-auto
                                text-base
                                bg-white
                                rounded-md
                                shadow-lg
                                max-h-60
                            "
                        >
                            <ListboxOption
                                v-slot="{ active, selected }"
                                v-for="channel in channels"
                                :key="channel.id"
                                :value="channel"
                                as="template"
                            >
                                <li
                                    :class="[
                                        active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                                        'cursor-default select-none relative py-2 pl-10 pr-4',
                                    ]"
                                >
                                    <span
                                        :class="[
                                            selected ? 'font-medium' : 'font-normal',
                                            'block truncate',
                                        ]"
                                        >{{ channel.title }}</span
                                    >
                                    <span
                                        v-if="selected"
                                        class="
                                            absolute
                                            inset-y-0
                                            left-0
                                            flex
                                            items-center
                                            pl-3
                                            text-amber-600
                                        "
                                    >
                                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                    </span>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>

            <div class="mt-4 w-72">
                <div class="flex items-center">
                    <LoadingButton
                        class="w-1/2 mr-4"
                        v-on:click="addChannel"
                        :loading="loading"
                        :disabled="!selectedChannel"
                    >
                        Add Channel
                    </LoadingButton>
                    <div class="ml-5" v-if="addChannelStatus">{{ addChannelStatus }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { defineComponent, onMounted, ref, watch } from 'vue'
import { addSlackChannelToTeam, getSlackChannels, loadSlackHistory } from '../../services/teams'
import LoadingButton from '@/components/global/LoadingButton.vue'

export default defineComponent({
    components: {
        Listbox,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        CheckIcon,
        SelectorIcon,
        LoadingButton,
    },
    data() {
        return {
            loading: false,
            loading_history: false,
            addChannelStatus: '',
        }
    },
    props: {
        initialChannelIndex: {
            type: Number,
            required: false,
        },
        teamId: {
            type: Number,
            required: true,
        },
    },
    methods: {
        async addChannel() {
            this.loading = true
            this.addChannelStatus = ''

            const { result, error } = await addSlackChannelToTeam(
                this.teamId,
                this.selectedChannel.id
            )
            if (result.id) {
                this.$emit('channelAdded')
            }
            this.loading = false

            this.addChannelStatus = result.id ? 'Added' : 'Failed'
            // Reset the message after a few seconds.
            setTimeout(() => {
                this.addChannelStatus = ''
            }, 3000)
        },
        async loadChannelHistory() {
            this.loading_history = true
            const { result, error } = await loadSlackHistory(this.teamId, this.selectedChannel.id)
            this.$emit('channelAdded')
            this.loading_history = false
        },
    },
    setup() {
        const channels = ref<
            {
                title: string
                id: string
            }[]
        >([])
        let selectedChannel = ref<{ title: string; id: string }>({
            title: 'loading...',
            id: 'loading...',
        })

        const loadSlackChannels = async function () {
            const result = await getSlackChannels()
            channels.value = result.channels.channels.map(function (ele: any) {
                return { id: ele.id, title: ele.name }
            })
            // Sort the channels based on the title.
            channels.value.sort(function (a, b) {
                var titleA = a.title.toUpperCase()
                var titleB = b.title.toUpperCase()
                return titleA < titleB ? -1 : titleA > titleB ? 1 : 0
            })
            // Set default value to the first item in the array.
            selectedChannel.value = channels.value[0]
        }

        loadSlackChannels()

        return { channels, selectedChannel }
    },
})
</script>
