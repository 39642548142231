<template>
    <div v-if="error" class="error-msg">{{ error }}</div>
    <div class="grid grid-cols-1 md:grid-cols-2">
        <ScoreCard v-for="e in entries" :key="e.channel.id" :emotionScore="e.scores" :channel="e.channel"
            :canDelete="userIsCompanyAdmin" @deleteClicked="showDelete(e.channel)" />
    </div>
    <TeamChannelDeletionModal v-if="channelToDelete" :team="team" :channel="channelToDelete"
        @completed="channelDeletionFinished"></TeamChannelDeletionModal>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, toRefs, watch } from 'vue'
import ScoreCard from '@/components/dashboard/ScoreCard.vue'
import { getTeamChannelsByDateRange } from '@/services/teams'
import CompanyTeamChannel from '@/models/CompanyTeamChannel'
import { EmotionScore } from '@/models/EmotionScore'
import CompanyTeam from '@/models/CompanyTeam'
import TeamChannelDeletionModal from '../dashboard/TeamChannelDeletionModal.vue'
import { useAuth } from '@/services/auth'

export default defineComponent({
    components: { ScoreCard, TeamChannelDeletionModal },
    props: {
        team: {
            type: Object as PropType<CompanyTeam>,
            required: true,
        },
        startDate: {
            type: String,
            required: true,
        },
        endDate: {
            type: String,
            required: true,
        },
        teamsListHasChanged: {
            type: Number,
            required: true
        }
    },
    emits: ['channelDeleted'],
    setup(props, { emit }) {
        const { startDate, endDate, teamsListHasChanged } = toRefs(props)
        const entries = ref<
            {
                channel: CompanyTeamChannel
                scores: EmotionScore
            }[]
        >([])

        const error = ref<any>()
        const { userIsCompanyAdmin } = useAuth()

        const channelToDelete = ref<CompanyTeamChannel>()
        const loadChannels = async () => {
            channelToDelete.value = undefined
            const { entries: responseEntries, error: responseError } = await getTeamChannelsByDateRange(
                props.team.id,
                startDate.value,
                endDate.value
            )
            entries.value = responseEntries
            error.value = responseError.value
        }
        loadChannels()

        const showDelete = (channel: CompanyTeamChannel) => {
            channelToDelete.value = channel
        }

        const channelDeletionFinished = (deleted: boolean) => {
            channelToDelete.value = undefined
            if (deleted) {
                emit('channelDeleted')
                loadChannels()
            }
        }
        watch([startDate, endDate], () => {
            loadChannels()
        })
        watch(teamsListHasChanged, () => {
            loadChannels()
        })

        watch(teamsListHasChanged, () => {
            loadChannels()
        })

        return {
            error,
            entries,
            channelToDelete,
            showDelete,
            channelDeletionFinished,
            userIsCompanyAdmin        }
    },
})
</script>
