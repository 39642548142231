<template>
    <div class="mt-10 mb-10 flex flex-col content-center">
        <div>
            <div class="mb-5 flex flex-row items-center justify-center">
                <p class="text mr-5">Monthly</p>
                <Switch v-model="annual" :class="annual ? 'bg-teal' : 'bg-gray-500'"
                    class="relative h-9 w-32 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200">
                    <span class="sr-only">Annual</span>
                    <span :class="annual ? 'translate-x-8' : '-translate-x-8'"
                        class="pointer-events-none inline-block h-8 w-8 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200" />
                </Switch>

                <p class="text ml-5">Annual</p>
            </div>
            <div v-if="plan" class="grid grid-cols-2 gap-x-8">
                <div class="col-span-3 md:col-span-1 h-full">
                    <div class="flex flex-col h-full">
                        <h3 class="text-xl font-bold text-center">
                            Free
                        </h3>
                        <div class="p-10 text-center">
                            Visit our <router-link class="link" to="/marketplace">marketplace</router-link>
                            <br />
                            <br />
                            With $500,000 in wellness savings
                        </div>
                    </div>
                </div>

                <div class="col-span-3 md:col-span-1 h-full">
                    <div class="flex flex-col h-full">
                        <h3 class="text-xl font-bold text-center"
                            :class="tierIsSelected(plan.subscriptionLevel) ? 'text-teal' : 'text-black'">
                            {{ plan.marketingCopy.title }}
                        </h3>
                        <div class="p-2 border-2 rounded-lg flex flex-col text-center h-full" :class="tierIsSelected(plan.subscriptionLevel)
                            ? 'border-teal'
                            : 'border-gray-500'
                            " @click="selectPlan(plan)">
                            <div class="" v-for="(benefit, index) in plan.marketingCopy.benefits" :key="benefit">
                                <p v-if="index != 0" class="mt-1 text-sm">+</p>
                                <p class="block mt-1 text-sm">{{ benefit }}</p>
                            </div>
                            <div class="seats-input mt-5">
                                <button v-on:click="() => remove('STARTER')" class="remove">
                                    -
                                </button>
                                <input type="number" v-model="seats['STARTER']" />
                                <button v-on:click="() => add('STARTER')" class="add">
                                    +
                                </button>
                            </div>
                            <div class="mt-5">Number of users</div>
                            <div class="flex-grow"></div>
                            <h4 v-if="getPrice(plan)" class="block text-2xl text-center text-teal font-bold mt-2">
                                ${{ getPrice(plan) }}
                            </h4>
                            <h2 v-if="getPrice(plan)" class="text-lg text-center text-teal font-bold -mt-1">
                                {{ annual ? '/year' : '/month' }}
                            </h2>
                            <p v-if="annual && getYearlyDiscountAmount(plan)" class="text-base text-center mt-2">
                                You will save ${{ getYearlyDiscountAmount(plan) }}/year
                            </p>
                            <p v-else-if="getYearlyDiscountAmount(plan)" class="text-base text-center mt-2">
                                <span class="cursor-pointer underline" v-on:click="() => (annual = !annual)">Pay
                                    annually</span>
                                to save ${{ getYearlyDiscountAmount(plan) }}/year
                            </p>

                            <div class="flex justify-center" v-if="!getPrice(plan)">
                                <div class="flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
                                    role="alert">
                                    <svg class="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                            clip-rule="evenodd"></path>
                                    </svg>

                                    <div>
                                        <span class="font-medium">Contact our sales team.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="subscription && plan && subscription.subscriptionLevel == plan.subscriptionLevel
                            " class="flex flex-col mt-2 items-center">
                            <!-- <ArrowSmUpIcon class="w-5 h-5"></ArrowSmUpIcon>
                    <p class="text-sm">
                        {{ subscription.durationInMonths == 1 ? 'Monthly' : 'Annual' }} Active
                    </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="mt-4 grid grid-cols-2 gap-x-8" v-if="selectedPlan">
                <div class="mb-4 col-span-2 md:col-span-1">
                    <UserPaymentEntry @updating="updatingPaymentToggle"></UserPaymentEntry>
                </div>
                <div class="mb-4 col-span-2 md:col-span-1">
                    <h2 class="text-lg font-semibold">Selected Plan</h2>
                    <p class="mt-2">
                        {{ selectedPlan.marketingCopy.title }} {{ annual ? 'annual' : 'monthly' }}
                    </p>
                    <div class="promo-container" v-if="!annual && getPrice(selectedPlan)">
                        <div class="flex flex-row items-baseline mt-2">
                            <input id="promo-code" maxlength="6" placeholder="Promo code" name="promo-code" type="text"
                                @input="handlePromoCode" :loadingPromo="loadingPromo" />
                            <svg class="animate-spin -mr-1 ml-3 h-5 w-5 text-blue" xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24" v-if="loadingPromo">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#19B8D1" stroke-width="4">
                                </circle>
                                <path class="opacity-75" fill="#19B8D1"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                </path>
                            </svg>
                        </div>
                        <div class="mp-4 col-span-2 md:col-span-1">
                            <span class="text-sm" style="color: darkgray">
                                Promo code is not applied before subscription is purchased. Valid only for first-time
                                customers.
                            </span>
                        </div>
                    </div>

                    <div v-if="getPrice(selectedPlan)" class="flex flex-row items-baseline mt-2">
                        <h3 class="text-lg">Total:</h3>
                        <div class="flex-grow"></div>
                        <h3 class="text-lg">${{ getPrice(selectedPlan) }}</h3>
                    </div>
                </div>
            </div>
            <div class="flex flex-row" v-if="responseError">
                <div class="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                    role="alert">
                    <svg class="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <div>
                        <span class="font-medium">{{ responseError }}</span>
                    </div>
                </div>
            </div>

            <div class="flex flex-row" v-if="successMsg">
                <div class="flex p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
                    role="alert">
                    <svg class="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"></path>
                    </svg>

                    <div>
                        <span class="font-medium">{{ successMsg }}</span>
                    </div>
                </div>
            </div>
            <div v-if="selectedPlan" class="flex flex-row justify-end mt-4">
                <div>
                    <LoadingButton :loading="loading" :disabled="!canPurchase" @click="purchaseTapped">Purchase Subscription
                    </LoadingButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {
    UserSubscriptionLevel,
    ISubscriptionPlan,
    ISubscriptionPrice,
    SubscriptionPlan,
} from '@/models/UserSubscription'
import { defineComponent, ref, watch } from 'vue'
import { ArrowSmUpIcon } from '@heroicons/vue/solid'
import UserPaymentEntry from './UserPaymentEntry.vue'
import { useInfo } from '@/services/info'
import { useAuth } from '@/services/auth'
import LoadingButton from '../global/LoadingButton.vue'
import { Switch } from '@headlessui/vue'
import { purchaseSubscription, validatePromoCode } from '@/services/me'

export default defineComponent({
    components: { ArrowSmUpIcon, UserPaymentEntry, LoadingButton, Switch },
    setup() {
        const { subscription, payment } = useAuth()
        const { plans } = useInfo()
        const selectedPlan = ref<ISubscriptionPlan>()
        const loading = ref(false)
        const loadingPromo = ref(false)
        const canPurchase = ref(false)
        const updatingPayment = ref(false)
        const annual = ref(true)
        const responseError = ref<string>()
        const successMsg = ref<string>()
        const plan = ref<ISubscriptionPlan>()
        const seats = ref({
            STARTER: 10,
        } as {
            [seats: string]: number
        })
        const add = (plan: string) => {
            if (!annual.value) {
                if (plan == 'STARTER') seats.value.STARTER++
            } else {
                if (plan == 'STARTER') seats.value.STARTER++
            }
        }
        const remove = (plan: string) => {
            if (!annual.value) {
                if (plan == 'STARTER' && seats.value.STARTER > 1) seats.value.STARTER--
            } else {
                if (plan == 'STARTER' && seats.value.STARTER > 1) seats.value.STARTER--
            }
        }

        const descSortedTiers = (prices: any) => prices.sort((a: any, b: any) => a.up_to - b.up_to)

        const validateSeats = (seats: number) => {
            if (seats > 2000) {
                return false
            }
            return true
        }
        const getPrice = (plan: any) => {
            const selectedSeats = seats.value['STARTER']
            if (!validateSeats(selectedSeats)) return 0

            const sortedTiers = descSortedTiers(
                annual.value ? plan.annualPrice.price.tiers : plan.monthlyPrice.price.tiers
            )
            const pricingTier =
                sortedTiers[sortedTiers.findIndex((item: any) => selectedSeats <= item.up_to)]
            return (pricingTier.unit_amount * selectedSeats) / 100
        }

        const getYearlyDiscountAmount = (plan: any) => {
            const selectedSeats = seats.value['STARTER']
            if (!validateSeats(selectedSeats)) return 0
            const sortedTiersMonthly = descSortedTiers(plan.monthlyPrice.price.tiers)
            const pricingTierMonthly =
                sortedTiersMonthly[
                sortedTiersMonthly.findIndex((item: any) => selectedSeats <= item.up_to)
                ]

            const sortedTiersAnnual = descSortedTiers(plan.annualPrice.price.tiers)
            const pricingTierAnnual =
                sortedTiersAnnual[
                sortedTiersAnnual.findIndex((item: any) => selectedSeats <= item.up_to)
                ]

            return (
                (pricingTierMonthly.unit_amount * selectedSeats * 12 -
                    pricingTierAnnual.unit_amount * selectedSeats) /
                100
            )
        }
        let promoCodeId = ''
        const selectPlan = (plan: ISubscriptionPlan) => {
            selectedPlan.value = plan
        }

        const handlePromoCode = async (el: any) => {
            const { target } = el

            loadingPromo.value = true

            if (target.value) {
                const { error, apiResponse } = await validatePromoCode(target.value.toUpperCase())

                loadingPromo.value = false
                if (error?.value?.message) {
                    responseError.value = `${target.value} cannot be applied: ${error.value.message}`
                    successMsg.value = ''
                } else {
                    responseError.value = ''
                    promoCodeId = apiResponse?.value?.id

                    let msg = `Promo code ${target.value.toUpperCase()} is valid. The first ${apiResponse.value?.coupon?.duration_in_months || 'limited'
                        } month(s) will be free after which you monthly charge will be $${getPrice(
                            selectedPlan.value
                        )}. Due today $0.`
                    successMsg.value = msg
                }
            } else {
                loadingPromo.value = false
                responseError.value = ''
                successMsg.value = ''
            }
        }

        const purchaseTapped = async () => {
            const selected = selectedPlan.value
            if (!selected) {
                console.error('no plan selected')
                return
            }
            const price: ISubscriptionPrice = {
                id: annual.value ? selected.annualPrice.id : selected.monthlyPrice.id,
                seats: seats.value['STARTER'],
                duration: annual.value ? 12 : 1,
            }
            responseError.value = ''
            if (price.seats > 2000) {
                responseError.value = 'Please contact our sales team.'
                return
            }
            loading.value = true
            const { error } = await purchaseSubscription(price, promoCodeId)
            loading.value = false
            if (error.value) {
                responseError.value = error.value.message
            } else {
                selectedPlan.value = undefined
            }
        }

        const updatingPaymentToggle = (isUpdating: boolean) => {
            updatingPayment.value = isUpdating
        }


        watch([plans], () => {
            plan.value = plans.value.find((plan: ISubscriptionPlan) => plan?.subscriptionLevel == UserSubscriptionLevel.STARTER)
        })

        if (plans.value.length) {
            plan.value = plans.value.find((plan: ISubscriptionPlan) => plan?.subscriptionLevel == UserSubscriptionLevel.STARTER)
        }

        watch([payment, selectedPlan, subscription, updatingPayment, annual, seats], () => {
            // Need to have payment, a plan selected and the duration selected
            let allSelected =
                updatingPayment.value == false &&
                payment?.value != null &&
                selectedPlan.value != null &&
                getPrice(selectedPlan.value) != 0
            canPurchase.value = allSelected
        })

        const tierIsSelected = (tier: UserSubscriptionLevel) => {
            return selectedPlan.value?.subscriptionLevel == tier
        }

        return {
            remove,
            add,
            getPrice,
            getYearlyDiscountAmount,
            seats,
            plans,
            subscription,
            selectPlan,
            selectedPlan,
            loading,
            loadingPromo,
            canPurchase,
            handlePromoCode,
            purchaseTapped,
            updatingPaymentToggle,
            tierIsSelected,
            annual,
            successMsg,
            responseError,
            plan
        }
    },
})
</script>

<style scoped>
.promo-container {
    width: 20vw;
}

.link {
    text-decoration: underline;
    color: #18b8d1;
    margin: 0;
}

.seats-input button {
    padding: 10px;
    background: #18b8d1;
}

.seats-input .add {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-weight: bold;
}

.seats-input .remove {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-weight: bold;
}

.seats-input input {
    width: 70px;
    border: none;
    border-top: 2px solid #18b8d1;
    border-bottom: 2px solid #18b8d1;
    text-align: center;
}

.seats-input input:focus {
    outline: none;
    border: 1px solid white;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
</style>
