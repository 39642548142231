import { CompanyInfoFormData } from './FormData'
import { IUserSubscription, UserSubscription } from './UserSubscription'

export enum CompanyUserType {
    admin = 1,
    manager,
}

export interface ICompany {
    id: number
    name: string
    userType: CompanyUserType
    companySizeString: string
    subscription?: IUserSubscription
}

export class Company implements ICompany {
    id: number
    name: string
    userType: CompanyUserType
    companySizeString: string
    subscription?: IUserSubscription
    slackConnected: boolean
    teamsConnected: boolean
    discordConnected: boolean

    constructor(data: any) {
        this.id = data.id
        this.name = data.name
        this.userType = data.userType
        this.companySizeString = data.companySizeString
        if (data?.subscription) {
            this.subscription = new UserSubscription(data.subscription)
        }
        this.slackConnected = data.slackConnected
        this.teamsConnected = data.teamsConnected
        this.discordConnected = data.discordConnected
    }

    isAdmin() {
        return this.userType == CompanyUserType.admin
    }
}

export const companyUserTypeString = (type: CompanyUserType) => {
    switch (type) {
        case CompanyUserType.admin:
            return 'Admin'
        case CompanyUserType.manager:
            return 'Manager'
    }
}

export const editFormData = (company: ICompany | { companySizeString: string; name: string }) => {
    const data = new CompanyInfoFormData()
    data.companySizeString = company.companySizeString
    data.name = company.name
    return data
}
