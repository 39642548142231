<template>
    <ModalContainer :openImmediately="true" @closed="modalClosed">
        <template v-slot:modalTitle
            >Are you sure you want to delete the project '{{ project.name }}' and associated
            files?</template
        >
        <template v-slot:modalContent>
            <p class="text-md text-red-600 mb-2">This process is irreversible!</p>

            <LoadingButton
                v-if="state.deletedAudioFiles == null"
                @click="deleteClicked"
                :disabled="state.loading"
                :loading="state.loading"
                class="bg-red-600"
                >Delete now</LoadingButton
            >
            <div v-if="state.deletedAudioFiles != null">
                Deleted {{ state.deletedAudioFiles }} associated files.
            </div>
            <div class="error-msg" v-if="state.responseError">{{ state.responseError }}</div>
        </template>
    </ModalContainer>
</template>

<script lang="ts">
import { deleteProject } from '@/services/projects'
import { defineComponent, PropType, reactive } from 'vue'
import ModalContainer from '../global/ModalContainer.vue'
import LoadingButton from '../global/LoadingButton.vue'
import Project from '@/models/Project'

export default defineComponent({
    components: { ModalContainer, LoadingButton },
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
    },
    emits: ['deleted'],
    setup(props, { emit }) {
        const state: {
            loading: boolean
            responseError?: string
            deletedAudioFiles: number | null
        } = reactive({
            loading: false,
            deletedAudioFiles: null,
        })

        const deleteClicked = async () => {
            const projectId = props.project.id

            state.loading = true
            const { error, results } = await deleteProject(projectId)
            state.loading = false
            if (error.value) {
                state.responseError = error.value.message
            } else if (results.value?.message) {
                state.responseError = results.value.message
            } else {
                state.deletedAudioFiles = results.value?.audioDeleteCount
                emit('deleted', projectId)
            }
        }

        const modalClosed = () => {
            emit('deleted', false)
        }

        return { state, deleteClicked, modalClosed }
    },
})
</script>
