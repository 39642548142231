import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0d5fbea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_av_waveform = _resolveComponent("av-waveform")!
  const _component_AudioScoreLine = _resolveComponent("AudioScoreLine")!

  return (_ctx.audioDetails)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.audioDetails?.fileKey)
          ? (_openBlock(), _createBlock(_component_av_waveform, {
              key: 0,
              "canv-width": 800,
              ref: "player",
              class: "player",
              "line-width": 2,
              "line-color": "lime",
              onClick: _ctx.updateCursor,
              "audio-src": `https://audio-user-uploads.s3.us-east-2.amazonaws.com/${_ctx.audioDetails?.fileKey}`
            }, null, 8, ["onClick", "audio-src"]))
          : _createCommentVNode("", true),
        (_ctx.audioDetails?.scoresJson)
          ? (_openBlock(), _createBlock(_component_AudioScoreLine, {
              key: 1,
              activeIndex: _ctx.activeIndex,
              scores: _ctx.timestampedScores
            }, null, 8, ["activeIndex", "scores"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}