<template>
    <div class="team-detail p-4">
        <div class="grid grid-cols-12">
            <!-- Add stats/team name here -->
            <div v-if="teamData" class="col-span-12 md:col-span-6 p-2">
                <h2 class="text-2xl">{{ teamData.team.name }}</h2>
                <div class="mt-2" v-if="teamData.stats">
                    <p>{{ teamData.stats.channelCount }} channels</p>
                    <p>{{ teamData.stats.messageCount }} scored messages</p>
                    <div class="flex w-1/2">
                        <litepie-datepicker placeholder="Select date range"
                            :formatter="{ date: 'YYYY-MM-DD', month: 'MMM' }" v-model="dateValue">
                        </litepie-datepicker>
                    </div>
                    <!-- <DateRangeSelector
                        @dateRange="dateRangeChanged"
                        :initialDateRange="3"
                        class="mt-2"
                    >
                    </DateRangeSelector> -->
                </div>
                <div v-if="hasConnectedApps()">
                    <div class="mt-5">Add More Channels to Team</div>
                    <AppPicker :apps="connectedAppsList()" @appChanged="handleAppChange" />
                    <div>
                        <div class="mt-5" v-show="selectedAppValue == 'ms-teams'" v-if="company?.teamsConnected">
                            <MSTeamsChannels v-if="teamData" :teamId="teamData.team.id"
                                @channelAdded="channelListChanged" />
                        </div>
                        <div class="mt-5" v-show="selectedAppValue == 'slack'" v-if="company?.slackConnected">
                            <SlackChannels v-if="teamData" :teamId="teamData.team.id" @channelAdded="channelListChanged" />
                        </div>
                        <div class="mt-5" v-show="selectedAppValue == 'discord'" v-if="company?.discordConnected">
                            <DiscordChannels v-if="teamData" :teamId="teamData.team.id"
                                @channelAdded="channelListChanged" />
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="mt-5">
                        <div class="warn  w-1/2">
                            <ol class="notes">
                                <li>
                                    MoodConnect only analyses messages from channels that you add. We do not analyse any
                                    other
                                    messages.

                                </li>
                                <li>
                                    Moodconnect only analyses messages from the time you added the channel. We do not have
                                    access to any
                                    messages before that.
                                </li>
                            </ol>
                        </div>
                        <div class="mr-5 inline">To add channels</div>
                        <button class="neon-shadow text-blue-500 cursor-pointer" v-on:click="$router.push({
                            name: 'settings', params: {
                                scrollTo: 'apps'
                            }
                        })">Connect Apps
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="teamData" class="col-span-12 md:col-span-6">
                <ScoreCard :emotionScore="teamData.scores" :team="teamData.team" :hideTitle="true" />
            </div>
        </div>
        <div class="">
            <p class="text-xl p-2">Channels</p>
        </div>
        <TeamChannelList v-if="teamData" :team="teamData.team" :startDate="startDate" :endDate="endDate"
            @channelDeleted="channelDeleted()" :teamsListHasChanged="listChangeKey" ref="teamChannelList">
        </TeamChannelList>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import ScoreCard from '@/components/dashboard/ScoreCard.vue'
import { useRoute } from 'vue-router'
import { getTeamDetails, getTeamDetailsByDateRange, TeamDetailResponse } from '@/services/teams'
import TeamChannelList from '@/components/teams/TeamChannelList.vue'
import MSTeamsChannels from '@/components/msTeams/MSTeamsChannels.vue'
import DiscordChannels from '@/components/discord/DiscordChannels.vue'
import SlackChannels from '@/components/slack/SlackChannels.vue'
import DateRangeSelector from '@/components/global/DateRangeSelector.vue'
import { useAuth } from '@/services/auth'
import AppPicker from '@/components/global/AppPicker.vue'
import LitepieDatepicker from 'litepie-datepicker'

export default defineComponent({
    components: {
        ScoreCard,
        TeamChannelList,
        DateRangeSelector,
        SlackChannels,
        MSTeamsChannels,
        AppPicker,
        DiscordChannels,
        LitepieDatepicker
    },
    data() {
        return {
            listChangeKey: Math.random(),
            selectedAppValue: ''
        }
    },
    computed: {
        isMsAppLoaded() {
            const { msAppLoaded } = useAuth()
            return msAppLoaded.value
        },
    },
    methods: {
        hasConnectedApps() {
            return this.connectedAppsList().length > 1
        },
        channelListChanged(data: any) {
            if (data?.isPrivate) {
                alert(
                    'To complete adding a private channel you need to manually add the MoodConnect bot to the channel.'
                )
            }
            this.listChangeKey = Math.random()
            this.loadTeamDetails()
        },
        handleAppChange(value: any) {
            this.selectedAppValue = value
        },
        connectedAppsList() {
            const connectedApps: Array<{ name: string; value: string | null }> = [
                {
                    name: 'Connected Apps',
                    value: null,
                },
            ]
            const { company } = useAuth()

            if (company?.value?.teamsConnected) {
                connectedApps.push({
                    name: 'Microsoft Teams',
                    value: 'ms-teams',
                })
            }
            if (company?.value?.slackConnected && !this.isMsAppLoaded) {
                connectedApps.push({
                    name: 'Slack',
                    value: 'slack',
                })
            }
            if (company?.value?.discordConnected && !this.isMsAppLoaded) {
                connectedApps.push({
                    name: 'Discord',
                    value: 'discord',
                })
            }
            return connectedApps
        },
    },
    setup() {
        const teamData = ref<TeamDetailResponse>()
        const { company } = useAuth()
        const selectedDateRange = ref<number>(4)
        const dateValue = ref<string[]>([]);
        const startDate = ref();
        const endDate = ref();


        const loadTeamDetails = async (tId?: number) => {
            const teamId = tId || teamData.value?.team.id
            if (!teamId) {
                return
            }
            const { details } = await getTeamDetails(teamId)
            teamData.value = details
        }

        const dateRangeChanged = async (startDate: string, endDate: string, teamId?: number) => {
            const tId = teamId || teamData.value?.team.id
            if (!tId) {
                return
            }
            const { details } = await getTeamDetailsByDateRange(tId, startDate, endDate)
            teamData.value = details
        }

        onMounted(async () => {
            const route = useRoute()
            const routeId = route.params.id
            if (routeId == null) {
                return
            }
            let id: number
            if (routeId instanceof Array) {
                id = parseInt(routeId[0])
            } else {
                id = parseInt(routeId)
            }

            loadTeamDetails(id)
        })

        const channelDeleted = () => {
            loadTeamDetails()
        }

        watch(dateValue, (val) => {
            if (val.length > 0) {
                startDate.value = val[0];
                endDate.value = val[1];
                dateRangeChanged(val[0], val[1])
            }
        });

        return {
            teamData,
            company,
            dateRangeChanged,
            selectedDateRange,
            channelDeleted,
            loadTeamDetails,
            dateValue,
            startDate,
            endDate
        }
    },
})
</script>

<style scoped>
.warn {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.notes {
    list-style-type: decimal;
    padding-left: 1.5rem;
}


.neon-shadow {
    border: none;
    transition: 0.3s;
    animation: glow 1s infinite;
    transition: 0.5s;
    border-radius: 5px;
    padding: 3px 10px;
}

span {
    display: block;
    width: 100%;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    padding-top: 15%;
    padding-right: 2.5%;
    margin-right: 0px;
    font-size: 1.2rem;
    transition: 0.3s;
    opacity: 0;
}

span:hover {
    transition: 0.3s;
    opacity: 1;
    font-weight: 700;
}

.neon-shadow:hover {
    background-color: #c3bacc;
    transition: 0.5s;
}

@keyframes glow {
    0% {
        box-shadow: 5px 5px 20px rgb(255, 165, 0), -5px -5px 20px rgb(255, 165, 0);
        /* Standard Orange */
    }

    50% {
        box-shadow: 15px 5px 20px rgb(77, 255, 246), -5px -5px 20px rgb(77, 213, 255);
        /* Lighter Orange */
    }

    100% {
        box-shadow: 5px 5px 20px rgb(255, 165, 0), -5px -5px 20px rgb(255, 165, 0);
        /* Standard Orange */
    }

}
</style>
