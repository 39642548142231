import CompanyTeam from '@/models/CompanyTeam'
import { EmotionScore } from '@/models/EmotionScore'
import { ref } from 'vue'
import { callApiWithAuth } from './api'

export interface DashboardEntry {
    team: CompanyTeam
    scores: EmotionScore
}

export const getDashboard = async () => {
    const { apiResponse, error, get } = callApiWithAuth('dashboard/main')

    await get()

    const responseEntries: DashboardEntry[] = apiResponse.value.teams
    const entries = ref(responseEntries)
    return { entries, error }
}

export const getDashboardByDateRange = async (startDate: string, endDate: string) => {
    const { apiResponse, error, get } = callApiWithAuth('dashboard/main')

    await get({ startDate, endDate })

    const responseEntries: DashboardEntry[] = apiResponse.value.teams
    const entries = ref(responseEntries)
    return { entries, error }
}
