<template>
    <div id="g-recaptcha"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ReCaptchaV2',
    mounted() {
        // Create and append the reCAPTCHA script
        const script = document.createElement('script');
        script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit');
        script.setAttribute('async', '');
        script.setAttribute('defer', '');
        document.head.appendChild(script);

        // Function to emit the token received from reCAPTCHA
        const dataReceived = (token: string) => {
            this.$emit('token', token);
        };

        // Callback function to render the reCAPTCHA
        const onloadCallback = () => {
            (window as any).grecaptcha.render('g-recaptcha', {
                'sitekey': process.env.VUE_APP_RECAPTCHA_V2_SITE_KEY, // Use the site key from environment variables
                'callback': dataReceived, // Specify the callback to handle the token
            });
        };

        // Attach the onloadCallback function to the window object
        (window as any).onloadCallback = onloadCallback;
    },
});
</script>
