import Recommendation from '@/models/Recommendation'
import { ref } from 'vue'
import { callApi, callApiWithAuth } from './api'

export const getRecommendations = async () => {
    const { apiResponse, error, get } = callApi('recommendations/all')

    await get()

    const responseRecommendations: Recommendation[] = apiResponse.value.recommendations
    const recommendations = ref(responseRecommendations)
    return { recommendations, error }
}
