<template>
    <div class="reset-pw-form">
        <form @submit.prevent="passwordResetSubmitted">
            <div class="overflow-hidden sm:rounded-md">
                <div class="">
                    <div class="grid grid-cols-6 gap-6">
                        <!-- Need optional input for current password for use in settings. Add later -->
                        <div class="col-span-6 sm:col-span-3" v-if="isSettings">
                            <label for="password" class="onboarding-label">Current Password</label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                autocomplete="current-password"
                                class="text-input"
                                v-model="v$.current.$model"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.current.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="w-full" v-if="isSettings"></div>
                        <div class="col-span-6 sm:col-span-3">
                            <label for="new-password" class="onboarding-label"
                                >Enter New Password</label
                            >
                            <input
                                type="password"
                                name="new-password"
                                id="new-password"
                                autocomplete="new-password"
                                class="text-input"
                                v-model="v$.newPw.$model"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.newPw.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                            <label for="confirm-password" class="onboarding-label"
                                >Confirm Password</label
                            >
                            <input
                                type="password"
                                name="confirm-password"
                                id="confirm-password"
                                autocomplete="new-password"
                                class="text-input"
                                v-model="v$.confirmPw.$model"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.confirmPw.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="error-msg" v-if="internal.responseError">
                        {{ internal.responseError }}
                    </div>
                </div>
                <div class="py-3 text-right">
                    <LoadingButton :loading="internal.loading" :disabled="v$.$invalid"
                        >Save</LoadingButton
                    >
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { updatePassword } from '@/services/me'
import useVuelidate from '@vuelidate/core'
import { helpers, required, sameAs } from '@vuelidate/validators'
import { computed, defineComponent, reactive, toRefs } from 'vue'
import LoadingButton from '../global/LoadingButton.vue'

export default defineComponent({
    components: { LoadingButton },
    props: {
        isSettings: {
            default: false,
        },
    },
    emits: ['pwData'],
    setup(props, { emit }) {
        const { isSettings } = toRefs(props)

        const internal = reactive({
            loading: false,
            responseError: '',
        })
        const state = reactive({
            current: '',
            newPw: '',
            confirmPw: '',
        })

        const obRules = computed(() => ({
            current: {},
            newPw: { required },
            confirmPw: {
                required,
                sameAsPassword: helpers.withMessage(
                    'Password confirmation must match',
                    sameAs(state.newPw)
                ),
            },
        }))
        const updateRules = computed(() => ({
            current: { required },
            newPw: { required },
            confirmPw: {
                required,
                sameAsPassword: helpers.withMessage(
                    'Password confirmation must match',
                    sameAs(state.newPw)
                ),
            },
        }))
        const v$ = useVuelidate(isSettings.value ? updateRules : obRules, state)

        const passwordResetSubmitted = async () => {
            if (isSettings.value == false) {
                emit('pwData', state)
                return
            }

            internal.loading = true
            const { error } = await updatePassword(state.current, state.newPw)

            internal.loading = false
            if (error.value) {
                internal.responseError = error.value.message
            }
        }

        return { v$, state, passwordResetSubmitted, internal }
    },
})
</script>
