<template>
    <div class="block mb-4 px-2">
        <h2 class="text-left font-bold text-2xl">Marketplace</h2>
    </div>
    <div v-if="error" class="error-msg">{{ error }}</div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-2">
        <RecommendationCard
            v-for="rec in recommendations"
            :key="rec.id"
            :recommendation="rec"
        ></RecommendationCard>
    </div>
</template>

<script lang="ts">
import Recommendation from '@/models/Recommendation'
import { getRecommendations } from '@/services/recommendations'
import { defineComponent, ref } from 'vue'
import RecommendationCard from './RecommendationCard.vue'

export default defineComponent({
    components: { RecommendationCard },
    setup() {
        const error = ref<any>()
        const recommendations = ref<Recommendation[]>([])
        const loadRecommendations = async () => {
            const { recommendations: responseRecommendations, error: responseError } =
                await getRecommendations()

            recommendations.value = responseRecommendations.value
            error.value = responseError.value
        }
        loadRecommendations()

        return { error, recommendations }
    },
})
</script>
