<template>
    <div class="app-connection flex-col">
        <div v-if="disconnectedApps.length" class="mb-6">
            <span class="font-bold">Connect Apps</span>
            <div class="py-4 flex">
                <div class="ml-3" v-for="app in disconnectedApps" :key="app.name">
                    <a v-if="!msAppLoaded" :href="app.connectionLink">
                        <img :src="app.addImageSrc" :alt="app.name" :class="app.customCSSClasses"
                    /></a>
                    <span v-if="msAppLoaded && app.id == 'ms-teams'" v-on:click="openModal">
                        <img :src="app.addImageSrc" :alt="app.name" :class="app.customCSSClasses"
                    /></span>
                </div>
            </div>
        </div>

        <div v-if="connectedApps.length">
            <span class="font-bold">Connected Apps</span>
            <div class="flex">
                <div>
                    <div class="py-4" v-for="app in connectedApps" :key="app.name">
                        <div
                            v-if="(msAppLoaded && app.id == 'ms-teams') || !msAppLoaded"
                            class="h-30"
                        >
                            {{ app.name }}
                        </div>
                    </div>
                </div>
                <div>
                    <div class="py-3" v-for="app in connectedApps" :key="app.name">
                        <div
                            v-if="(msAppLoaded && app.id == 'ms-teams') || !msAppLoaded"
                            class="flex items-center"
                        >
                            <div
                                class="disconnect-btn cursor-pointer"
                                v-on:click="() => disconnectApp(app.id)"
                                title="Uninstall bot & disconnect app"
                            >
                                disconnect
                            </div>
                            <img
                                v-show="loaderId == app.id"
                                class="h-5 mr-3"
                                src="../../assets/mini-loading.gif"
                                alt="loading..."
                            />
                            <div v-if="loaderId != app.id" class="h-5 mr-3 w-5"></div>
                            <div v-if="statusMessage[app.id]">{{ statusMessage[app.id] }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ModalContainer v-if="isOpen" :openImmediately="true" @closed="modalClosed">
        <template v-slot:modalTitle>Connect Teams</template>
        <template v-slot:modalContent>
            <p class="text-md text-black mb-2">
                If you click on this link, you will leave MicroSoft Teams and go to another site.
             <br/>   PS: After connecting successfully, please click on this tab's My Account button, logout and log back in.
            </p>
            <div class="flex justify-between">
                <button v-on:click="isOpen = false" class="text-md text-red-600 mb-2">
                    Cancel
                </button>
                <button @click="connectTeamsClicked" class="bg-teal rounded px-2 py-2">
                    Accept & Continue
                </button>
            </div>
        </template>
    </ModalContainer>
</template>

<script lang="ts">
import { useAuth } from '@/services/auth'
import { defineComponent, ref } from 'vue'
import { SupportedAppType } from '../../models/SupportedApp'
import { disconnectSlack, disconnectTeams, disconnectDiscord } from '../../services/companies'
import ModalContainer from '../global/ModalContainer.vue'

export default defineComponent({
    components: { ModalContainer },
    setup(props, { emit }) {
        const { msAppLoaded } = useAuth()
        const isOpen = ref<boolean>(false)
        const connectTeamsClicked = async () => {
            const teamsClientId: string = process.env.VUE_APP_MICROSOFT_CLIENT_ID
            const teamsRedirectURL = process.env.VUE_APP_TEAMS_REDIRECT_URL
            const teamsLink = `https://login.microsoftonline.com/common/adminconsent?client_id=${teamsClientId}&state=teams-app&redirect_uri=${teamsRedirectURL}&response_type=code`
            window.open(teamsLink);
            isOpen.value = false;
        }
        const modalClosed = () => {
            isOpen.value = false
        }
        const openModal = () => {
            isOpen.value = true
            emit('redirectModalOpened')
        }

        return { msAppLoaded, connectTeamsClicked, openModal, modalClosed, isOpen }
    },
    data() {
        return {
            loaderId: '',
            statusMessage: {} as { [value: string]: string },
        }
    },
    computed: {
        supportedApps(): SupportedAppType[] {
            const { company } = useAuth()
            const slackClientId: string = process.env.VUE_APP_SLACK_CLIENT_ID
            const teamsClientId: string = process.env.VUE_APP_MICROSOFT_CLIENT_ID
            const slackRedirectURL = process.env.VUE_APP_SLACK_REDIRECT_URL
            const teamsRedirectURL = process.env.VUE_APP_TEAMS_REDIRECT_URL
            const discordClientId: string = process.env.VUE_APP_DISCORD_CLIENT_ID
            const discordRedirectURL = process.env.VUE_APP_DISCORD_REDIRECT_URL
            const connectionState: string = Math.random().toString()
            localStorage.setItem('connectionState', connectionState)
            const slackLink = `https://slack.com/oauth/v2/authorize?client_id=${slackClientId}&scope=chat:write,commands,channels:read,groups:read,channels:join,channels:history,users:read&redirect_uri=${slackRedirectURL}&state=${connectionState}`
            const teamsLink = `https://login.microsoftonline.com/common/adminconsent?client_id=${teamsClientId}&state=${connectionState}&redirect_uri=${teamsRedirectURL}&response_type=code`
            const discordLink = `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${discordClientId}&state=${connectionState}&redirect_uri=${discordRedirectURL}&prompt=consent&scope=bot&permissions=1024`

            return [
                {
                    name: 'Slack',
                    id: 'slack',
                    isConnected: ref(company?.value?.slackConnected == true),
                    addImageSrc: 'https://platform.slack-edge.com/img/add_to_slack.png',
                    connectionLink: slackLink,
                },
                {
                    name: 'Microsoft Teams',
                    id: 'ms-teams',
                    isConnected: ref(company?.value?.teamsConnected == true),
                    addImageSrc: require('../../assets/ms_teams_app_logo.png'),
                    customCSSClasses: ['teams-logo'],
                    connectionLink: teamsLink,
                },
                {
                    name: 'Discord',
                    id: 'discord',
                    isConnected: ref(company?.value?.discordConnected == true),
                    addImageSrc: require('../../assets/discord_app_logo.png'),
                    customCSSClasses: ['teams-logo'],
                    connectionLink: discordLink,
                },
            ]
        },
        connectedApps(): SupportedAppType[] {
            return this.supportedApps.filter((app: SupportedAppType) => app.isConnected.value)
        },
        disconnectedApps(): SupportedAppType[] {
            return this.supportedApps.filter((app: SupportedAppType) => !app.isConnected.value)
        },
    },
    methods: {
        async disconnectApp(appId: string) {
            const { company } = useAuth()
            if (!company?.value?.id) return

            this.loaderId = appId

            if (appId == 'slack') {
                const { updatedCompany, error } = await disconnectSlack(company?.value?.id)
                if (error.value) {
                    this.statusMessage[appId] = error.value.message
                        ? error.value.message
                        : 'Disconnection error'
                }
            } else if (appId == 'ms-teams') {
                const { updatedCompany, error } = await disconnectTeams(company?.value?.id)
                if (error.value) {
                    this.statusMessage[appId] = error.value.message
                        ? error.value.message
                        : 'Disconnection error'
                }
            } else if (appId == 'discord') {
                const { updatedCompany, error } = await disconnectDiscord(company?.value?.id)
                if (error.value) {
                    this.statusMessage[appId] = error.value.message
                        ? error.value.message
                        : 'Disconnection error'
                }
            }
            this.loaderId = ''
            setTimeout(() => {
                this.statusMessage[appId] = ''
            }, 3000)
        },
    },
})
</script>

<style>
.teams-logo {
    border-radius: 6px;
    border: 1px solid lightgray;
}

.app-connection div {
    margin-right: 10px;
}
.slack-logo-image {
    height: 45px;
}
.disconnect-btn {
    padding: 3px 15px;
    border-radius: 10px;
    background: #f87171;
    color: white;
}
</style>
