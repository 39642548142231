export interface EmotionScore {
    happy: number
    angry: number
    surprise: number
    sad: number
    fear: number
    disgust: number
    neutral: number
}

export interface EmotionScoreDisplay {
    title: string
    name: string
    colorClass: string
    percentage: string
}

export const displayForEmotionScore = (emotion: EmotionScore) => {
    const scores: EmotionScoreDisplay[] = []
    const percentString = (score: number) => {
        return ((score || 0) * 100).toFixed(1) + '%'
    }
    scores.push({
        title: 'Enthusiastic',
        name: 'happy',
        colorClass: 'bg-happy',
        percentage: percentString(emotion.happy),
    })
    scores.push({
        title: 'Frustrated',
        name: 'angry',
        colorClass: 'bg-angry',
        percentage: percentString(emotion.angry),
    })
    scores.push({
        title: 'Confused',
        name: 'surprise',
        colorClass: 'bg-surprise',
        percentage: percentString(emotion.surprise),
    })
    scores.push({
        title: 'Burned Out',
        name: 'sad',
        colorClass: 'bg-sad',
        percentage: percentString(emotion.sad),
    })
    scores.push({
        title: 'Uncertain',
        name: 'fear',
        colorClass: 'bg-fear',
        percentage: percentString(emotion.fear),
    })

    scores.push({
        title: 'Annoyed',
        name: 'disgust',
        colorClass: 'bg-disgust',
        percentage: percentString(emotion.disgust),
    })

    scores.push({
        title: 'Satisfied',
        name: 'neutral',
        colorClass: 'bg-neutral',
        percentage: percentString(emotion.neutral),
    })
    return scores
}
