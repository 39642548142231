import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScoreCard = _resolveComponent("ScoreCard")!
  const _component_TeamChannelDeletionModal = _resolveComponent("TeamChannelDeletionModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (e) => {
        return (_openBlock(), _createBlock(_component_ScoreCard, {
          key: e.channel.id,
          emotionScore: e.scores,
          channel: e.channel,
          canDelete: _ctx.userIsCompanyAdmin,
          onDeleteClicked: ($event: any) => (_ctx.showDelete(e.channel))
        }, null, 8, ["emotionScore", "channel", "canDelete", "onDeleteClicked"]))
      }), 128))
    ]),
    (_ctx.channelToDelete)
      ? (_openBlock(), _createBlock(_component_TeamChannelDeletionModal, {
          key: 1,
          team: _ctx.team,
          channel: _ctx.channelToDelete,
          onCompleted: _ctx.channelDeletionFinished
        }, null, 8, ["team", "channel", "onCompleted"]))
      : _createCommentVNode("", true)
  ], 64))
}