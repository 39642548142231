import { ICompanyInfoFormData, IUserInfoFormData } from './FormData'

export class OnboardingTestData {
    static userInfoForm: IUserInfoFormData = {
        firstName: 'Test',
        lastName: 'User',
        email: 'test@moodconnect.com',
        password: 'abcd1234!!',
        confirmPw: 'abcd1234!!',
    }

    static companyInfoForm: ICompanyInfoFormData = {
        name: 'Sandbox, Inc.',
        companySizeString: '10-50',
    }
}
