<template>
    <div class="mx-5 my-10">
        <button :class="['primary-button', 'mr-3']" v-on:click="() => goTo('/dashboard/teams')">
            Teams
        </button>

        <button v-on:click="() => goTo('/dashboard/projects')" :class="['primary-button', 'mr-3']">
            Projects
        </button>

        <div class="team-detail pt-10 px-5">
            <div class="mb-5 font-bold" v-if="projectDetails?.project?.id">
                {{ projectDetails?.project.name }}
            </div>
            <AudioFileUpload
                v-if="projectDetails?.project?.id"
                :companyProjectId="projectDetails.project.id"
                @onUploadStatusChanged="onUploadStatusChanged"
            />

            <AudioFileList
                class="my-10"
                v-if="projectDetails?.audios?.length"
                :audioList="getAudioList()"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getProjectDetails, ProjectDetailsResponse } from '@/services/projects'
import AudioFileUpload from '@/components/projects/AudioFileUpload.vue'
import AudioFileList from '@/components/projects/AudioFileList.vue'
import { useAuth } from '@/services/auth'
import router from '@/router'

export default defineComponent({
    components: {
        AudioFileUpload,
        AudioFileList,
    },
    data() {
        return {
            listChangeKey: Math.random(),
            selectedAppValue: '',
        }
    },
    setup() {
        const projectDetails = ref<ProjectDetailsResponse>()
        const { company } = useAuth()

        const loadProjectDetails = async (_projectId?: number) => {
            const projectId = _projectId || projectDetails.value?.project?.id
            if (!projectId) return
            const { details } = await getProjectDetails(projectId)
            projectDetails.value = details
        }

        onMounted(async () => {
            const route = useRoute()
            const routeId = route.params?.id
            if (routeId == null) {
                return
            }
            let id: number
            if (routeId instanceof Array) {
                id = parseInt(routeId[0])
            } else {
                id = parseInt(routeId)
            }

            loadProjectDetails(id)
        })

        const getAudioList = () => {
            const listOfAudio =
                projectDetails.value?.audios.map((audio) => {
                    audio.status =
                        audio.status == 'UNKNOWN' ? 'TRANSCRIPTION_SCHEDULED' : audio.status
                    audio.status = audio.status.replace('_', ' ').toLowerCase()
                    return audio
                }) || []

            return listOfAudio.reverse()
        }

        const onUploadStatusChanged = () => {
            loadProjectDetails()
        }
        const goTo = (route: string) => {
            router.push(route)
        }
        return {
            projectDetails,
            company,
            loadProjectDetails,
            getAudioList,
            onUploadStatusChanged,
            goTo,
        }
    },
})
</script>
