<template>
    <div class="px-2 flex flex-row items-center">
        <div>
            <h2 class="text-left font-bold text-2xl mb-4">
                All {{ viewOption == 'teams' ? 'Teams' : 'Projects' }}
            </h2>

            <button v-on:click="() => switchTo('teams')"
                :class="['primary-button', 'mr-3', { 'active-switcher': viewOption == 'projects' }]">
                Teams
            </button>
            <LoadingButton v-if="false" :class="['primary-button', { 'active-switcher': viewOption == 'teams' }]"
                v-on:click="() => switchTo('projects')" :loading="isLoadingProjects" :disabled="isLoadingProjects">
                Projects
            </LoadingButton>
        </div>
        <div class="flex-grow"></div>
        <!-- <DateRangeSelector
            v-if="viewOption == 'teams'"
            class="mr-2"
            :initialDateRange="3"
            @dateRange="dateRangeChanged"
        ></DateRangeSelector> -->
        <div class="flex w-72 mr-3">
            <litepie-datepicker placeholder="Select date range" :formatter="{ date: 'YYYY-MM-DD', month: 'MMM' }"
                v-model="dateValue">
            </litepie-datepicker>
        </div>
        <ProjectCreationModal :hasProjects="projects.length != 0" v-if="viewOption == 'projects'" class="mr-3" />
        <TeamCreationModal :hasTeams="entries.length != 0" v-if="viewOption == 'teams'" />
    </div>
    <div v-if="error" class="error-msg">{{ error }}</div>

    <div v-if="viewOption == 'teams'" class="grid grid-cols-1 md:grid-cols-2">
        <ScoreCard v-for="e in entries" :key="e.team.id" :emotionScore="e.scores" :team="e.team"
            :canDelete="userIsCompanyAdmin" @deleteClicked="showDelete(e)" />
    </div>
    <TeamChannelDeletionModal v-if="teamToDelete" :team="teamToDelete" @completed="teamDeleted">
    </TeamChannelDeletionModal>

    <ProjectsList v-if="viewOption == 'projects' && projects.length" :projects="projects"
        @projectDeleted="projectDeleted" />
    <div class="mx-10 my-10" v-if="viewOption == 'projects' && projects.length == 0 && hasLoadedProjects">
        No projects found. Create a project!
    </div>
    <div class="mx-10 my-10" v-if="!hasLoadedProjects && !hasLoadedDashboard && !entries.length">
        Loading...
    </div>
    <div class="mx-10 my-10" v-if="viewOption == 'teams' && entries.length == 0 && hasLoadedDashboard">
        No teams found. Create a team!
    </div>
</template>

<script lang="ts">
import { DashboardEntry, getDashboard, getDashboardByDateRange } from '@/services/dashboard'
import { defineComponent, Ref, ref, watch } from 'vue'
import ScoreCard from '@/components/dashboard/ScoreCard.vue'
import TeamCreationModal from '@/components/teams/TeamCreationModal.vue'
import ProjectCreationModal from '@/components/projects/ProjectCreationModal.vue'
import LoadingButton from '@/components/global/LoadingButton.vue'
import ProjectsList from '@/components/projects/ProjectsList.vue'
import DateRangeSelector from '../global/DateRangeSelector.vue'
import TeamChannelDeletionModal from './TeamChannelDeletionModal.vue'
import CompanyTeam from '@/models/CompanyTeam'
import { useAuth } from '@/services/auth'
import Project from '@/models/Project'
import { getProjects } from '@/services/companies'
import router from '@/router'
import { useRoute } from 'vue-router'
import LitepieDatepicker from 'litepie-datepicker'


export default defineComponent({
    components: {
        ScoreCard,
        TeamCreationModal,
        ProjectCreationModal,
        DateRangeSelector,
        TeamChannelDeletionModal,
        LoadingButton,
        ProjectsList,
        LitepieDatepicker
    },
    props: ['viewType'],
    setup(props) {
        const route = useRoute()
        const selectedDateRange = ref<number>(4)

        const { userIsCompanyAdmin } = useAuth()
        const viewOption: Ref<'teams' | 'projects'> = ref(
            route.params.optionName as 'teams' | 'projects'
        )
        const hasLoadedProjects: Ref<boolean> = ref(false)
        const hasLoadedDashboard: Ref<boolean> = ref(false)
        const dateValue = ref([]);


        const dateRangeChanged = async (startDate: string, endDate: string) => {
            teamToDelete.value = undefined
            hasLoadedDashboard.value = false
            const { entries: responseEntries, error: responseError } = await getDashboardByDateRange(
                startDate, endDate)
            hasLoadedDashboard.value = true
            entries.value = []
            entries.value = responseEntries.value
            error.value = responseError.value
        }


        const projects = ref<Project[]>([])
        const isLoadingProjects = ref<boolean>(false)
        const entries = ref<DashboardEntry[]>([])
        const error = ref<any>()
        const teamToDelete = ref<CompanyTeam>()
        const loadDashboard = async () => {
            teamToDelete.value = undefined
            hasLoadedDashboard.value = false
            const { entries: responseEntries, error: responseError } = await getDashboard()
            hasLoadedDashboard.value = true
            entries.value = []
            entries.value = responseEntries.value
            error.value = responseError.value
        }

        const showDelete = (team: DashboardEntry) => {
            teamToDelete.value = team.team
        }

        const teamDeletionFinished = (deleted: boolean) => {
            teamToDelete.value = undefined
            if (deleted) {
                loadDashboard();
            }
        }
        const loadProjects = async () => {
            isLoadingProjects.value = true
            const { company } = useAuth()
            if (!company?.value?.id) return
            try {
                const { error, projectsList } = await getProjects(company.value.id)
                isLoadingProjects.value = false
                hasLoadedProjects.value = true
                if (error.value) return
                projects.value = projectsList
            } catch (e) {
                isLoadingProjects.value = false
            }
        }
        const switchTo = (option: 'teams' | 'projects') => {
            viewOption.value = option
            router.push(`/dashboard/${viewOption.value}`)
            if (option == 'projects') {
                loadProjects()
            }
        }

        loadDashboard()

        if (viewOption.value == 'projects') {
            loadProjects()
        }

        watch([route], () => {
            viewOption.value = route.params.optionName as 'projects' | 'teams'
        })

        watch(dateValue, (val) => {
            if (val.length > 0) {
                dateRangeChanged(val[0], val[1])
            }
        });

        return {
            error,
            entries,
            dateRangeChanged,
            teamToDelete,
            showDelete,
            teamDeleted: teamDeletionFinished,
            projectDeleted: loadProjects,
            userIsCompanyAdmin,
            viewOption,
            switchTo,
            isLoadingProjects,
            projects,
            hasLoadedProjects,
            hasLoadedDashboard,
            dateValue
        }
    },
})
</script>

<style scoped>
.active-switcher {
    background-color: white;
    color: black;
    border: 1px solid #18b8d1;
    transition: background 300ms ease-in-out;
}
</style>