<template>
    <div class="reset-pw h-full">
        <div class="grid grid-cols-9 place-content-center h-full">
            <div class="col-span-9 md:col-start-3 md:col-span-5">
                <h2 class="text-left text-xl font-medium mb-1">Reset Password</h2>
                <!-- Card -->
                <div class="onboarding-card">
                    <div class="w-full">
                        <ResetPasswordForm
                            :isSettings="false"
                            @pwData="passwordUpdateSubmitted"
                        ></ResetPasswordForm>
                        <div class="error-msg" v-if="state.responseError">
                            {{ state.responseError }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue'
import ResetPasswordForm from '@/components/onboarding/ResetPasswordForm.vue'
import { useRoute, useRouter } from 'vue-router'
import { resetPassword } from '@/services/auth'

export default defineComponent({
    components: { ResetPasswordForm },
    setup() {
        const state = reactive({
            resetToken: '',
            responseError: '',
        })

        const router = useRouter()
        const passwordUpdateSubmitted = async (newPw: {
            current: string
            newPw: string
            confirmPw: string
        }) => {
            // Actually send this off to the auth handler
            state.responseError = ''
            if (state.resetToken == null || state.resetToken.length == 0) {
                return
            }
            const { error } = await resetPassword(state.resetToken, newPw.newPw)

            if (error?.value == undefined) {
                router.push('/login')
            } else {
                state.responseError = error.value.message
            }
        }

        onMounted(() => {
            const route = useRoute()
            const token = route.query.token as string
            state.resetToken = token
        })

        return { passwordUpdateSubmitted, state }
    },
})
</script>
