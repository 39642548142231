<template>
    <ModalContainer :openImmediately="true" @closed="modalClosed">
        <template v-slot:modalTitle
            >Are you sure you want to delete the audio file: {{ audio.fileName }} ?</template
        >
        <template v-slot:modalContent>
            <p class="text-md text-red-600 mb-2">This process is irreversible!</p>

            <LoadingButton
                @click="deleteClicked"
                :disabled="state.loading"
                :loading="state.loading"
                class="bg-red-600"
                >Delete now</LoadingButton
            >
            <div class="error-msg" v-if="state.responseError">{{ state.responseError }}</div>
        </template>
    </ModalContainer>
</template>

<script lang="ts">

import AudioAnalysisResult from '@/models/AudioAnalysisResult'
import { deleteAudio } from '@/services/projects'
import { defineComponent, PropType, reactive } from 'vue'
import ModalContainer from '../global/ModalContainer.vue'
import LoadingButton from '../global/LoadingButton.vue'

export default defineComponent({
    components: { ModalContainer, LoadingButton },
    props: {
        audio: {
            type: Object as PropType<AudioAnalysisResult>,
            required: true,
        },
    },
    emits: ['deleted'],
    setup(props, { emit }) {
        const state: { loading: boolean; responseError?: string } = reactive({
            loading: false,
            responseError: undefined,
        })

        const deleteClicked = async () => {
            const fileKey = props.audio.fileKey

            state.loading = true
            const { error, results } = await deleteAudio(fileKey)
            state.loading = false
            if (error.value) {
                state.responseError = error.value.message
            } else if (results.value?.message) {
                state.responseError = results.value.message
            } else {
                emit('deleted', fileKey)
            }
        }

        const modalClosed = () => {
            emit('deleted', false)
        }

        return { state, deleteClicked, modalClosed }
    },
})
</script>
