<template>
    <div>
        <div class="content flex flex-wrap">
            <template v-for="project of computedProjects" :key="project.id">
                <div
                    v-on:click.self="routeTo(`/projects/${project.id}/details`)"
                    class="project-card cursor-pointer flex shadow-md w-52 h-24 items-center mx-5 my-5"
                >
                    <div
                        v-on:click="routeTo(`/projects/${project.id}/details`)"
                        class="project-logo h-10 w-10 mx-3"
                    ></div>
                    <div class="w-full">
                        <div class="flex items-center font-bold my-2">
                            <div
                                class="w-full"
                                v-on:click="routeTo(`/projects/${project.id}/details`)"
                            >
                                {{ project.name }}
                            </div>

                            <Menu as="div" class="relative inline-block text-left">
                                <div>
                                    <MenuButton
                                        class="inline-flex justify-center w-full p-2 text-sm font-medium text-black bg-teal rounded-md bg-opacity-0 hover:bg-opacity-30"
                                    >
                                        <DotsVerticalIcon
                                            class="w-5 h-5 text-black"
                                            aria-hidden="true"
                                        />
                                    </MenuButton>
                                </div>

                                <transition
                                    enter-active-class="transition duration-100 ease-out"
                                    enter-from-class="transform scale-95 opacity-0"
                                    enter-to-class="transform scale-100 opacity-100"
                                    leave-active-class="transition duration-75 ease-in"
                                    leave-from-class="transform scale-100 opacity-100"
                                    leave-to-class="transform scale-95 opacity-0"
                                >
                                    <MenuItems
                                        class="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none z-20"
                                    >
                                        <div class="px-1 py-1">
                                            <MenuItem v-slot="{ active }">
                                                <button
                                                    :class="[
                                                        active
                                                            ? 'bg-red-600 text-white'
                                                            : 'text-red-600',
                                                        'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                                    ]"
                                                    @click="() => deleteProject(project)"
                                                >
                                                    <TrashIcon
                                                        :active="active"
                                                        class="w-5 h-5 mr-2 text-red-600"
                                                        aria-hidden="true"
                                                    />
                                                    Delete Project
                                                </button>
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </transition>
                            </Menu>
                        </div>
                    </div>
                </div>
            </template>
            <div class="m-5" v-if="!computedProjects.length">Nothing to see here!</div>
        </div>
        <div class="z-32">
            <ProjectDeletionModal
                v-if="projectToDelete"
                :project="projectToDelete"
                @deleted="projectDeleted"
            ></ProjectDeletionModal>
        </div>
    </div>
</template>

<script lang="ts">
import Project from '@/models/Project'
import { defineComponent } from 'vue'
import { Menu, MenuItems, MenuButton, MenuItem } from '@headlessui/vue'
import { TrashIcon, DotsVerticalIcon } from '@heroicons/vue/solid'
import ProjectDeletionModal from './ProjectDeletionModal.vue'
import router from '@/router'

export default defineComponent({
    components: {
        ProjectDeletionModal,
        Menu,
        MenuItems,
        MenuButton,
        MenuItem,
        DotsVerticalIcon,
        TrashIcon,
    },
    props: ['projects'],
    data: () => ({
        deletedList: [] as number[],
        projectToDelete: null as Project | null,
    }),
    computed: {
        computedProjects() {
            return this.projects?.filter(
                (project: Project) => !this.deletedList.includes(project.id)
            )
        },
    },
    methods: {
        projectDeleted(deletedId: number) {
            this.deletedList.push(deletedId);
            this.$emit('projectDeleted');
            setTimeout(() => {
                this.projectToDelete = null
            }, 5000)
        },
        deleteProject(project: Project) {
            this.projectToDelete = project
        },
        routeTo(routeName: string) {
            router.push(routeName)
        },
    },
})
</script>

<style scoped>
.project-logo {
    background-image: url('../../assets/research.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.status {
    color: rgb(0, 107, 0);
}

.project-card:hover {
    background-color: aliceblue;
}
</style>