<template>
    <div class="w-72">
        <Listbox v-model="selectedBracket">
            <div class="relative">
                <ListboxButton
                    class="
                        relative
                        w-full
                        py-2
                        pl-3
                        pr-10
                        text-left
                        rounded-md
                        cursor-default
                        sm:text-sm
                        border-gray-500 border-2
                    "
                >
                    <span class="truncate">{{ selectedBracket.title }}</span>
                    <span
                        class="
                            absolute
                            inset-y-0
                            right-0
                            flex
                            items-center
                            pr-2
                            pointer-events-none
                        "
                    >
                        <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity=0"
                >
                    <ListboxOptions
                        class="
                            absolute
                            w-full
                            py-1
                            mt-1
                            z-20
                            overflow-auto
                            text-base
                            bg-white
                            rounded-md
                            shadow-lg
                            max-h-60
                        "
                    >
                        <ListboxOption
                            v-slot="{ active, selected }"
                            v-for="range in rangeBrackets"
                            :key="range.id"
                            :value="range"
                            as="template"
                        >
                            <li
                                :class="[
                                    active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-10 pr-4',
                                ]"
                            >
                                <span
                                    :class="[
                                        selected ? 'font-medium' : 'font-normal',
                                        'block truncate',
                                    ]"
                                    >{{ range.title }}</span
                                >
                                <span
                                    v-if="selected"
                                    class="
                                        absolute
                                        inset-y-0
                                        left-0
                                        flex
                                        items-center
                                        pl-3
                                        text-amber-600
                                    "
                                >
                                    <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script lang="ts">
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
    components: { Listbox, ListboxButton, ListboxOptions, ListboxOption, CheckIcon, SelectorIcon },
    emits: ['dateRange'],
    props: {
        initialDateRange: {
            type: Number,
            required: false,
        },
    },
    setup(props, { emit }) {
        const rangeBrackets = [
            { id: 1, title: 'Last 24 Hours', value: 1 },
            { id: 2, title: 'Last Week', value: 2 },
            { id: 3, title: 'Last Month', value: 3 },
            { id: 4, title: 'Last Quarter', value: 4 },
            { id: 5, title: 'All Time', value: 0 },
        ]
        const selectedBracket = ref(rangeBrackets[props.initialDateRange ?? 0])

        watch(selectedBracket, () => {
            emit('dateRange', selectedBracket.value.value)
        })
        return { rangeBrackets, selectedBracket }
    },
})
</script>
