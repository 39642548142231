<template>
    <div>
        <div class="mx-5 my-7">
            <router-link v-if="audioDetails?.companyProjectId" :class="['primary-button', 'mx-3']"
                :to="`/projects/${audioDetails?.companyProjectId}/details`">
                Project
            </router-link>
        </div>
        <div class="team-detail pt-3 px-40">
            <div class="mb-5 font-bold" v-if="audioDetails?.fileName">
                {{ audioDetails.fileName }} - Audio Analysis
            </div>

            <AudioScoresVisualizer v-if="audioDetails" :audioDetails="audioDetails" />
            <!-- <button v-show="initiated" class="button" v-on:click="play">
            {{ isPlaying ? 'Pause' : 'Play' }}
        </button> -->

            <div v-if="audioDetails?.transcriptionJson" class="flex flex-col items-center my-10">
                <div class="w-2/3">
                    <div class="flex w-full">
                        <button type="button" v-on:click="toggleTranscriptionVisibility"
                            class="text-button-font py-2.5 px-5 mr-2 mt-10 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            {{ showTranscription ? 'Hide transcription' : 'Show transcription' }}
                        </button>
                    </div>

                    <div v-show="showTranscription" class="my-5">
                        <span v-for="line of JSON.parse(audioDetails?.transcriptionJson || '[]')
                            .map((item: any) => item.text)">
                            {{ line }} <br />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { getAudioDetails } from '@/services/projects'
import { useAuth } from '@/services/auth'
import AudioAnalysisResult from '@/models/AudioAnalysisResult'
import AudioScoresVisualizer from '@/components/projects/AudioScoresVisualizer.vue'

export default defineComponent({
    components: { AudioScoresVisualizer },
    data() {
        return {
            listChangeKey: Math.random(),
            selectedAppValue: '',
            showTranscription: false,
        }
    },
    setup() {
        const audioDetails = ref<AudioAnalysisResult>()
        const player = ref<any>()
        const audioRef = ref<any>()
        const isPlaying = ref(false)
        const { company } = useAuth()

        const loadAudioDetails = async (_audioId?: number) => {
            const audioId = _audioId || audioDetails.value?.id
            if (!audioId) return
            const { details } = await getAudioDetails(audioId)
            audioDetails.value = details
        }

        onMounted(async () => {
            hideAudioElement()
            const route = useRoute()
            const params = route.params as any
            if (!params.audioId) return
            const audioId = parseInt(params?.audioId)
            loadAudioDetails(audioId)
        })

        const hideAudioElement = () => {
            try {
                // document.getElementsByTagName('audio')[0].style.display = 'none'
                // document.getElementsByTagName('audio')[0].onclick = () => { }
            } catch (error) { }
        }

        return {
            audioDetails,
            company,
            loadAudioDetails,
            player,
            isPlaying,
            hideAudioElement,
        }
    },
    methods: {
        toggleTranscriptionVisibility() {
            this.showTranscription = !this.showTranscription
        },
    },
})
</script>